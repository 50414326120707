<template>
  <v-menu
    v-model="menu"
    ref="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div readonly v-bind="attrs" v-on="on">
        <ott-input
          v-model="date"
          :error="error"
          :label="label"
          prepend-inner-icon="mdi mdi-calendar-blank-outline neutral--text"
        />
      </div>
    </template>
    <v-date-picker :width="width" height="290" v-model="date" no-title scrollable>
      <v-spacer></v-spacer>
      <!-- <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)"> OK </v-btn> -->
    </v-date-picker>
  </v-menu>
</template>

<script>
import OttInput from "./OttInput.vue";
export default {
  components: { OttInput },
  name: "OttDatePicker",
  props: {
    label: {
      type: String,
      default: () => "",
    },
    error: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: String
    },
    width: {
     type: String,
     default: () => "290" 
    }
  },
  data() {
    return {
      menu: false,
      date: "",
    };
  },
  watch: {
    date(newDate) {
      this.$emit("input", newDate);
    },
    value(newDate) {
      this.date = newDate
    }
  },
};
</script>

<style lang="scss">
.v-input__prepend-inner {
  position: absolute !important;
  right: 0 !important;
}
.v-input--dense {
  .v-label {
    top: 8px !important;
    &--active {
      left: 0!important;
    }
  }
}
</style>