<template>
  <div>
    <TitleComponent
      v-if="!locationClient.locationList.length && !showComponents" 
      :is-add="true"
      :click="() => showComponents = true" 
      title-text="Add Location" 
    />
    <div v-if="showComponents">
      <ManageLocationClients
        v-if="
          !locationClient.locationList.length ||
          locationClient.isEditMode ||
          locationClient.isAddMode
        "
        ref="manageLocation"
      />
      <LocationListClients v-else />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import ManageLocationClients from "./components/ManageLocation";
import LocationListClients from "./components/LocationList";
import TitleComponent from "@/components/TitleComponent";

export default {
  name: "LocationsClients",
  components: { TitleComponent, LocationListClients, ManageLocationClients },
  computed: {
    ...mapState({
      locationClient: (state) => state.locationClient,
      clientId: (state) => state.clientsModule.clientId,
    }),

    showComponents: {
      set(value) {
        this.setData({ showComponents: value });
      },
      get() {
        return this.locationClient.showComponents;
      },
    },    
  },

  watch: {
    'locationClient.locationList'(locationList) {
      if (!locationList.length) {
        this.setData({ showComponents: false });
      } else {
        this.setData({ showComponents: true });
      }
    },
  },
  
  mounted() {
    if (this.locationClient.locationList.length) {
      this.setData({ showComponents: true })
    } else {
      this.setData({ showComponents: false })
    }
  },

  beforeDestroy() {
    if (this.$route.name === 'Search') {
      this.resetFormData()
    }
    if (this.$route.name === 'Search' && !this.locationClient.locationList.length) {
      this.setData({ showComponents: false })
    }
  },

  methods: {
    ...mapMutations({
      setData: 'locationClient/setData',
      resetFormData: 'locationClient/resetFormData'
    })
  }
}
</script>