import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import timeZoneList from "@/constants/time-zone-list";

export default {
  data() {
    return {
      timeZoneLists: timeZoneList,
      login: Math.floor(Math.random() * 10000000000000).toString(),
      showLoader: false
    };
  },

  validations: {
    formData: {
      locationName: { required },
      maxDevice: { required },
      timezone: { required },
      server: { required },
      parentalCode: { required },
    },
  },


  async created() {
    this.setShowSaveBtn(false)

    if (
      (!this.locationClient.isAddMode && !this.locationClient.isEditMode) ||
      (this.locationClient.isAddMode && !this.locationClient.isEditMode)
    ) {
      await this.getRandomLoginPass();
      // { clientId: this.$route.name === 'Search' ? this.$route.params.id : null }
    }

    await this.getServerList()
  },

  computed: {
    ...mapState({
      locationClient: state => state.locationClient,
      formData: state => state.locationClient.formData,
      clientId: state => state.clientsModule.clientId,
      showSaveBtn: state => state.personalInfoClient.showSaveBtn,
      isAddClient: state => state.clientsModule.isAddClient,
      serverList: state => state.clientsModule.serverList,
      isLoading: state => state.appModule.isLoading,
      isOpenClientModal: state => state.clientsModule.modal.isOpen,
    }),

    /**
     * Random Login Password
     * **/
    getRandomLogin() {
      return this.locationClient.formData.login
        ? this.locationClient.formData.login
        : this.locationClient.randomLogin;
    },

    getRandomPassword() {
      if (
        this.locationClient.isEditMode &&
        !this.locationClient.passwordIsUpdated
      ) {
        return "*".repeat(13);
      } else {
        return this.locationClient.randomPass;
      }
    },

    getLastChangedPassword() {
      return this.locationClient.formData.lastChangedPassword
        ? this.locationClient.formData.lastChangedPassword
        : this.locationClient.lastChangedPassword;
    },

    /**
     * Form Data
     * **/

    locationName: {
      set(locationName) {
        this.setFormData({ locationName });
      },
      get() {
        return this.formData.locationName;
      },
    },
    maxDevice: {
      set(maxDevice) {
        this.setFormData({ maxDevice });
      },
      get() {
        return this.formData.maxDevice;
      },
    },
    timezone: {
      set(timezone) {
        this.setFormData({ timezone });
      },
      get() {
        return this.formData.timezone;
      },
    },
    server: {
      set(server) {
        this.setFormData({ server });
      },
      get() {
        return this.formData.server;
      },
    },
    parentalCode: {
      set(parentalCode) {
        this.setFormData({ parentalCode });
      },
      get() {
        return this.formData.parentalCode;
      },
    },
    isBlockLocation: {
      set(isBlockLocation) {
        this.setFormData({ isBlockLocation });
      },
      get() {
        return this.formData.isBlockLocation;
      },
    },
    isPauseSubscriptions: {
      set(isPauseSubscriptions) {
        this.setFormData({ isPauseSubscriptions });
      },
      get() {
        return this.formData.isPauseSubscriptions;
      },
    },
    VODEnable: {
      set(VODEnable) {
        this.setFormData({ VODEnable });
      },
      get() {
        return this.formData.VODEnable;
      },
    },
    archiveEnable: {
      set(archiveEnable) {
        this.setFormData({ archiveEnable });
      },
      get() {
        return this.formData.archiveEnable;
      },
    },
    timeShiftEnable: {
      set(timeShiftEnable) {
        this.setFormData({ timeShiftEnable });
      },
      get() {
        return this.formData.timeShiftEnable;
      },
    },
    comment: {
      set(comment) {
        this.setFormData({ comment });
      },
      get() {
        return this.formData.comment;
      },
    }
  },

  methods: {
    ...mapActions({
      add: "locationClient/add",
      edit: "locationClient/editLocation",
      getRandomLoginPass: "locationClient/getRandomLoginPass",
      updateRandomLoginPass: "locationClient/updateRandomLoginPass",
      getServerList: "clientsModule/getServerList",
      getLocations: 'locationClient/getLocations',
    }),

    ...mapMutations({
      setData: "locationClient/setData",
      resetFormData: "locationClient/resetFormData",
      setDisableTabs: "clientsModule/setDisableTabs",
      setShowSaveBtn: "personalInfoClient/setShowSaveBtn",
      setFormData: "locationClient/setFormData",
      toggleModalResetPassword: "locationClient/toggleModalResetPassword",
    }),

    /**
     * Update new  password
     * **/

    async updateLoginPass() {
      this.showLoader = true;
      await this.getRandomLoginPass({ clientId : this.$route?.params?.id ? this.$route.params.id : null });
      this.showLoader = false;

      const updateData = {
        clientId: this.clientId,
        login: this.locationClient.randomLogin,
        password: this.locationClient.randomPass,
        lastChangedPassword: this.$moment(this.locationClient.lastChangedPassword),
      };

      this.setData({ passwordIsUpdated: true });
      await this.updateRandomLoginPass(updateData);
    },

    /**
     * Add location
     * **/

    async addLocation() {
      this.$v.formData.$touch();

      if (!this.$v.formData.$error) {
        
        const locationData = {
          locationName: this.locationName,
          server: this.server,
          maxDevice: this.maxDevice,
          parentalCode: this.parentalCode,
          isPauseSubscriptions: this.isPauseSubscriptions,
          VODEnable: this.VODEnable,
          archiveEnable: this.archiveEnable,
          timeShiftEnable: this.timeShiftEnable,
          isBlockLocation: this.isBlockLocation,
          clientId: this.$route.name === 'Search' && !this.isOpenClientModal ? this.$route.params.id : this.clientId,
          timezone: this.timezone,
          login: this.locationClient.randomLogin,
          password: this.locationClient.randomPass,
          lastChangedPassword: this.getLastChangedPassword,
        };

        await this.add(locationData);
        await this.getLocations()

        if (this.locationClient.locationList.length) {
          this.setData({ showComponents: true });
          this.setDisableTabs(9);
        } else {
          this.setDisableTabs(6);
        }

        this.resetFormData();
        this.setShowSaveBtn(true)
        // return Promise.resolve(true)
      }
    },

    /**
     * Edit location
     * **/

    async editLocation() {
      this.$v.formData.$touch();

      if (!this.$v.formData.$error) {

        const locationData = {
          locationName: this.locationName,
          server: this.server,
          maxDevice: this.maxDevice,
          isPauseSubscriptions: this.isPauseSubscriptions ? true : false,
          VODEnable: this.VODEnable,
          archiveEnable: this.archiveEnable,
          timeShiftEnable: this.timeShiftEnable ? true : false,
          isBlockLocation: this.isBlockLocation,
          timezone: this.timezone,
          parentalCode: this.parentalCode,
        };

        if (this.isPauseSubscriptions || this.isBlockLocation) {
          locationData.comment = this.comment
        }

        await this.edit(locationData)
        await this.getLocations()

        this.resetFormData();
        this.setShowSaveBtn(true)
      }
    },

    cancel() {
      this.setData({ 
        isAddMode: false, 
        isEditMode: false,
      });
      
      if (!this.locationClient.locationList.length) {          
        this.setData({ showComponents: false })
      }
      
      this.setShowSaveBtn(true);
    },

    /**
     * Get title name
     * @default - Location
     * @isAddMode - Add Location
     * @isEditMode - Edt Location
     * **/
    getTitleName() {
      let title = "Location";
      if (this.locationClient.isEditMode) {
        title = "Edit " + title;
      }
      if (this.locationClient.isAddMode) {
        title = "Add " + title;
      }
      return title;
    },
  },
};
