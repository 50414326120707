var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title-component',{attrs:{"click":_vm.edit,"is-add":"","title-text":"Balance"}}),_c('div',{staticClass:"balance-content"},[_c('title-component',{attrs:{"title-text":`Total balance: ${
      typeof _vm.balanceCredit.mainBalance === 'number' || typeof _vm.balanceCredit.mainBalance === 'string'
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(_vm.balanceCredit.mainBalance)
        : '$0.00'
    }`}}),_c('div',{staticClass:"dept"},[_c('span',[_vm._v("Dept: "+_vm._s(typeof _vm.balanceCredit.dept === 'number' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, }).format(_vm.balanceCredit.dept) : '$0.00'))])]),(_vm.comment)?_c('div',{staticClass:"balance-content__info"},[_c('p',[_vm._v(_vm._s(_vm.comment))])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }