<template>
  <ott-dialog
    :is-open="locationClient.openModal.isOpen"
    :width="400"
    :show-footer="false"
    :show-header="false"
    @outside="() => {}"
  >
    <template v-slot:modalBody>
      <div>
        <div class="approve-cont">
          <div class="header">
            <h6 class="secondary--text">Reset Password ?</h6>
          </div>
          <div>
            <p class="secondary--text">You're about to Reset password.</p>
            <p class="secondary--text">
              If you're not sure, you can resolve or close this issue instead.
            </p>
          </div>
        </div>
        <div class="footer">
          <OttButtonLoader
            @onClick="updatePassword"
            :is-loading="isLoading"
            button-text="Update"
            className="primary-sm-btn"
          />
          <span @click="closeModal" class="cancel secondary--text">Cancel</span>
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import { mapState } from "vuex";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "UpdatePasswordLocation",
  components: { OttButtonLoader, OttDialog },
  computed: {
    ...mapState({ 
      locationClient: (state) => state.locationClient,
      isLoading: (state) => state.appModule.isLoading
    }),
  },

  methods: {
    updatePassword() {
      this.$emit("updatePassword");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h6 {
    font-size: $txt18;
    font-weight: $semiBold;
  }
}

.approve-cont {
  padding: 24px;

  .mdi-check-circle-outline {
    font-size: 20px;
  }

  p {
    margin-bottom: 20px;
    font-size: $txt14;
    font-weight: $regular;
  }

  .primary-sm-btn::v-deep .theme--light.v-btn--has-bg {
    background-color: green;
  }
}

.green-btn::v-deep {
  .theme--light.v-btn.v-btn--has-bg,
  .theme--dark.v-btn.v-btn--has-bg {
    background-color: $green;
    color: $white;
    box-shadow: none;
    text-transform: inherit;
    font-size: $txt16;
    height: 38px;
    padding: 0 16px;
    letter-spacing: 0.0492857143em !important;
  }
}

.footer {
  display: flex;
  padding: 0 24px 24px 24px;
  justify-content: flex-end;

  .cancel {
    font-size: $txt15;
    font-weight: $semiBold;
    padding: 0 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;

    &:hover {
      background: #f0f1f5;
      border-radius: $border-radius;
    }
  }
}

.theme--dark {
  .cancel:hover {
    background: #54575a !important;
    border-radius: $border-radius;
  }
}
</style>
