import { mapActions, mapMutations, mapState } from "vuex";
import { eventBus } from "@/main";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";

export default {
  name: "ManageClient",
  components: {
    OttButton,
    OttDialog
  },

  computed: {
    ...mapState({
      authProviderId: state => state.auth.user.provider.id,
      isSaved: state => state.appModule.isSaved,
      activeTab: state => state.clientsModule.activeTab,
      clients: state => state.clientsModule,
      auth: state => state.auth,
      clientId: state => state.clientsModule.clientId,
      locationList: state => state.locationClient.locationList,
      showPackagesPayBtn: state => state.packagesClients.showPayBtn,
      packagesPayBtnLabel: state => state.packagesClients.packagesPayBtnLabel,
      allowCheckout: state => state.packagesClients.allowCheckout,
      dataForPackages: state => state.packagesClients.dataForPackages,
      packagesTotalPrice: state => state.packagesClients.packagesTotalPrice,
      isAddClient: state => state.clientsModule.isAddClient,
      paymentType: state => state.paymentMethodClients.paymentType,
      selectedPaymentType: state => state.paymentMethodClients.selectedPaymentType,
      contactClient: state => state.contactClient,
      contact: state => state.contactClient,
      addressClient: state => state.addressClient,
      editMode: state => state.paymentMethodClients.editMode,
      addMode: state => state.paymentMethodClients.addMode,
      bankAccount: state => state.paymentMethodClients.bankAccount,
      paymentList: state => state.paymentMethodClients.paymentList,
      showSaveBtn: state => state.personalInfoClient.showSaveBtn,
      balanceManage: state => state.balanceCreditClient.balanceManage,
      personalInfoClient: state => state.personalInfoClient,
      credit: state => state.balanceCreditClient.credit,
      equipments: state => state.equipments,
      isGeneralSearch: state => state.generalSearch.isGeneralSearch,
      isLoading: state => state.appModule.isLoading,
      clientProvider: state => state.personalInfoClient.formData.provider,
      isAddClientFromSearch: state => state.clientsModule.isAddClientFromSearch,
    }),

    nextButton() {
      if (this.activeTab === 8 && this.showPackagesPayBtn) {
        return this.packagesPayBtnLabel;
      } else {
        return "Save and Continue"
      }
    }
  },

  async created() {
    this.setShowSaveBtn(true)
    if (this.isAddClientFromSearch) {
      this.setDisableTabs(0);
    }
    await this.getResellersList()

    if (this.isAddClient) {
      this.personalInfoSetData({ disableFormItems: true }) // need to weit first disable form items
      // if (this.$route.name !== 'Search' || (this.$route.name === 'Search' && this.isAddClientFromSearch))
      if (this.$route.name !== 'Search') {
        this.resetDataModal()
      }
      this.setClientProvider(this.authProviderId) // need to weit first add provider then undisable form
    }

    if ((this.isAddClient && this.clientProvider) || !this.isAddClient) {
      this.personalInfoSetData({ disableFormItems: false })
    }

    this.generalSearchSetData({ isGeneralSearch: false })  // if modal opened with search part change isGloblSearch key for shoing in modal
    
    // if (this.$route.name === search && !this.isAddClientFromSearch) {
    //   await this.getClient(this.searchId);
    // }
  },

  mounted() {
    if ((!this.clientId && !this.$route.name === 'Search') || (this.$route.name === 'Search' && this.isAddClientFromSearch)) {
      this.personalInfoReset();
      this.contactReset();
      this.addressReset();
      this.paymentMethodReset();
      this.financeReset();
      this.balanceManageReset();
      this.creditDataReset();
      this.locationReset();
      this.setClientId(null)
      this.setIsDataLoading(false)
    }

    // removed, caused bug not to show right provider
  },

  async destroyed() {
    this.closeModal()
    // if (this.$route.name !== 'Search' || (this.$route.name === 'Search' && this.isAddClientFromSearch)) {

    if (this.$route.name !== 'Search') {
      this.resetDataModal()
    }

    eventBus.$emit("clientModalDestroyed");

    if (this.$route.name === 'Search') {
      this.generalSearchSetData({ isGeneralSearch: true })
    }

    if (this.$route.name === 'Client') {
      await this.getClientList(this.clients.filterData)
    }
  },

  methods: {
    ...mapActions({
      getClientList: 'clientsModule/getClientList',
      setCheckoutInvoice: 'packagesClients/setCheckoutInvoice',
      getBillsList: 'clientBillsModule/getBillsList',
      getResellersList: 'providers/getResellersList',
    }),
    ...mapMutations({
      closeClientsModal: 'clientsModule/closeClientsModal',
      setClientProvider: 'personalInfoClient/setClientProvider',
      personalInfoSetData: 'personalInfoClient/setData',
      setData: 'clientsModule/setData',
      clickActiveTab: 'clientsModule/clickActiveTab',            
      closeModal: 'clientsModule/closeModal',
      setClientId: 'clientsModule/setClientId',
      setDisableTabs: 'clientsModule/setDisableTabs',
      personalInfoReset: 'personalInfoClient/resetAllData',
      contactReset:  'contactClient/resetAllData',
      addressReset: 'addressClient/resetAllData',
      paymentMethodReset : 'paymentMethodClients/resetData',
      financeReset: "financeClient/resetFinanceData",
      financeSetData: 'financeClient/setData',
      balanceManageReset: 'balanceCreditClient/resetBalanceManage',
      creditDataReset: 'balanceCreditClient/resetCreditData',
      locationReset: 'locationClient/resetAllData',
      resetEquipmentsTab: 'equipments/resetState',
      resetClientPackagesTab: 'packagesClients/resetState',
      setIsDataLoading: 'clientsModule/setIsDataLoading',
      setDataPackagesClient: "packagesClients/setData",
      setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      setIsAddClient: 'clientsModule/setIsAddClient',
      nextTab: 'clientsModule/nextTab',
      isAddAddress: 'addressClient/setIsAddMode',
      isEditAddress: 'addressClient/setIsEditMode',
      isAddContact: 'contactClient/changeIsAddMode',
      isEditContact: 'contactClient/changeIsEditMode',
      phoneSection: 'contactClient/setPhoneSection',
      emailSection: 'contactClient/setEmailSection',
      isAddPaymentMethod: 'paymentMethodClients/setAddMode',
      isEditPaymentMethod: 'paymentMethodClients/setEditMode',
      setBalanceIsEdit: 'balanceCreditClient/setBalanceIsEdit',
      setBalanceIsSave: 'balanceCreditClient/setBalanceIsSave',
      setLocationIsAdd: 'locationClient/setLocationIsAdd',
      setLocationIsEdit: 'locationClient/setLocationIsEdit',
      setDataEquipments: "equipments/setData",
      openCheckoutModal: "checkoutClients/openModal",
      generalSearchSetData: 'generalSearch/setData',
    }),
    click() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },

    async nextFunc() {
      switch (this.activeTab) {
        case 0: {
          // add personal info
          this.$refs['clientPersonalInfo'].saveData()
          break
        }
        case 1: {
          // add contact email
          if (!this.contact.allEmails.length) {
            this.$refs['clientContact'].$refs['contactEmail'].saveData()
          }
          // add contact phone
          if (!this.contact.allPhones.length) {
            this.$refs['clientContact'].$refs['contactPhone'].saveData()
          }

          if (this.contact.allEmails.length && this.contact.allPhones.length) {
            this.nextTab()
          }

          break
        }
        case 2: {
          // add address
          if (!this.addressClient.allAddresses.length) {
            this.$refs['clientAddress'].$refs['manageAddress'].saveData()
          }
          if (this.addressClient.allAddresses.length && this.addressClient.showAddressList) {
            this.nextTab()
          }

          break
        }
        case 3: {
          // add credit card
          if (this.selectedPaymentType === this.paymentType.creditCard && !this.paymentList.length) {
            this.$refs['clientsPaymentMethods'].$refs['managePayments'].$refs['creditCard'].saveData()
          }
          // add bank account
          if (this.selectedPaymentType === this.paymentType.bankAccount && !this.paymentList.length) {
            this.$refs['clientsPaymentMethods'].$refs['managePayments'].$refs['bankAccount'].saveData()
          }

          if (this.paymentList.length) {
            this.nextTab()
          }

          break
        }
        case 4: {
          // add finance
          this.$refs['clientFinance'].saveData().then(data => {
            if (data) {
              this.nextTab()
            }
          })
          break
        }
        case 5: {
          // add balance
          if (!this.balanceManage.isSave || this.balanceManage.isEdit) {
            this.$refs['clientBalanceCredit'].$refs['balanceForm'].save()
          }
          if (this.balanceManage.isSave && this.credit.isStart) {
            this.nextTab()
          }
          break
        }
        case 6: {
          // add location
          if (this.$refs['clientLocation'].$refs['manageLocation'] !== undefined) {
            await this.$refs['clientLocation'].$refs['manageLocation'].addLocation()
          } else {
            this.nextTab();
          }

          break
        }
        case 7: {
          this.nextTab()
          break
        }
        // case 8: {
        //   this.nextTab()
        // }
      }
    },
    changeTab(value) {
      if (
        this.clients.disableTabs ||
        this.clients.disableTabs === 0
      ) {
        if (value <= this.clients.disableTabs) {
          this.clickActiveTab(value);
        }
      } else {
        this.clickActiveTab(value);
      }
    },
    clickList(value) {
      this.changeTab(value)
      this.isAddAddress(false)
      this.isEditAddress(false)
      this.isAddContact(false)
      this.isEditContact(false)
      this.phoneSection(true)
      this.emailSection(true)
      this.isAddPaymentMethod(false)
      this.isEditPaymentMethod(false)
      if (this.balanceManage.isEdit) {
        this.setBalanceIsEdit(false)
        this.setBalanceIsSave(true)
      }
      this.setLocationIsAdd(false)
      this.setLocationIsEdit(false)
    },

    /**
     * Save and close data
     * **/
    saveAndCloseData() {
      switch (this.activeTab) {
        case 1: {
          // add contact email
          if (!this.contact.allEmails.length) {
            this.$refs['clientContact']?.$refs['contactEmail']?.saveData()
          }
          // add contact phone
          if (!this.contact.allPhones.length) {
            this.$refs['clientContact']?.$refs['contactPhone']?.saveData()
          }
          break
        }
        case 2: {
          // add address
          if (!this.addressClient.allAddresses.length) {
            this.$refs['clientAddress']?.$refs['manageAddress']?.saveData()
          }
          // if (this.addressClient.allAddresses.length) {
          //   this.cancel()
          // }
          break
        }
        case 3: {
          // add credit card
          if (this.selectedPaymentType === this.paymentType.creditCard && !this.paymentList.length) {
            this.$refs['clientsPaymentMethods']?.$refs['managePayments']?.$refs['creditCard']?.saveData()
          }
          // add bank account
          if (this.selectedPaymentType === this.paymentType.bankAccount && !this.paymentList.length) {
            this.$refs['clientsPaymentMethods']?.$refs['managePayments']?.$refs['bankAccount']?.saveData()
          }
          break
        }
        case 4: {
          // add finance
          this.$refs['clientFinance']?.saveData()
          break
        }
        case 5: {
          // add balance
          this.$refs['clientBalanceCredit']?.$refs['balanceForm']?.save()
          break
        }
        case 6: {
          // add location
          if (!this.locationList.length) {
            this.$refs['clientLocation']?.$refs['manageLocation']?.addLocation()
          }
          break
        }
        // case 7: {
        //   this.cancel()
        //   break
        // }
        // case 8: {
        //   this.cancel()
        //   break
        // }
        // case 9: {
        //   this.cancel()
        // }
      }

      // await this.getClientList(this.clients.filterData)
      this.cancel()
      this.$emit('resetSelectedList')
    },

    /**
     * Close and reset  all data modal
     * **/
    cancel() {
      // if (this.$route.name !== 'Search' || (this.$route.name === 'Search' && this.isAddClientFromSearch)) {
      
      this.financeSetData({
        formData: {
          currency: null,
          priceGroup: null,
          paymentForPackages: null,
          paymentForDevice: null,
          isPaperlessBilling: false,
        },
        paymentForPackagesObj: null
      }) // reset finance form data

      if (this.$route.name !== 'Search') {
        if (this.clientId) {
          if (this.locationList.length) {
            this.setDisableTabs(9);
          } else {
            this.setDisableTabs(6);
          }
        } else {
          this.setDisableTabs(0)
        }
        this.setShowSaveBtn(true)
        this.setIsAddClient(true)
        this.closeModal()
      } else {
        this.closeClientsModal()
        this.setDisableTabs(9);
      }
      // if (this.$route.name !== 'Search' || (this.$route.name === 'Search' && this.isAddClientFromSearch))
      if (this.$route.name !== 'Search') {
        this.resetDataModal()
      }

      this.setData({ clientProviderId: null })
    },
    resetDataModal() {
      this.personalInfoReset();
      this.contactReset();
      this.addressReset();
      this.paymentMethodReset();
      this.financeReset();
      this.balanceManageReset();
      this.creditDataReset();
      this.locationReset();
      this.resetEquipmentsTab();
      this.resetClientPackagesTab();
      this.setClientId(null);
      this.setData({ clientProviderid: null })
      this.setIsDataLoading(false);
      this.setDataPackagesClient({
        selectedLocation: null,
        buttonData: {
          packagesPayBtnLabel: ''
        }
      });
      this.setDataEquipments({clientEquipments: []})
    },

    /**Checkout part**/

    async openCheckout() {
      await this.setCheckoutInvoice({
        ...this.dataForPackages,
      }).then(() => this.openCheckoutModal());
    },
  }
};
