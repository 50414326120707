<template>
  <div class="credit">
    <title-component :title-text="credit.isEdit ? 'Edit Credit' : 'Credit'" />
    <div class="credit-fields">
      <div class="credit-fields__inputs G-flex">
        <div 
          class="G-flex G-flex-column G-justify-between"
          :class="{
            'P-padding-33': clientModalIsOpen,
            'ps-3 pb-2 w-220': !clientModalIsOpen
          }"
        >
          <ott-switch
            v-model="isStartNow"
            :disable="credit.isEdit"
            style="font-size: 14px"
            className="secondaryColor"
            label="Start Now"
          />
          <ott-input
            v-model="creditAmount"
            :error="$v.credit.creditAmount.$error"
            :is-percent="true"
            :customInputClass="'P-smpt-port'"
            :disabled="credit.isEdit"
            :wheel="(e) => e.preventDefault()"
            label="Credit Amount*"
            type="number"
            class="mt-3"
          />
        </div>
        <div 
          v-if="!isStartNow" 
          class="P-date-picker G-flex G-align-end"
          :class="{
            'P-padding-33': clientModalIsOpen,
            'px-4 pb-2 w-220': !clientModalIsOpen
          }"
        >
          <DatePicker
            v-model="setDate"
            :masks="{ input: ottDateFormat }"
            :popover="{ visibility: !!balanceCredit.creditData ? '' : 'click' }"
            :min-date="newDateWithMainUserTZ"
            :disable="credit.isEdit"
            :is-dark="$vuetify.theme.dark"
            locale="en"
            class="ott-date-picker"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="ott-date-picker-cont">
                <v-text-field
                  v-model="inputValue"
                  v-on="inputEvents"
                  :disabled="credit.isEdit"
                  :readonly="true"
                  :error="$v.credit.date.$error"
                  outlined
                  dense
                  height="38px"
                  label="Credit Start Date*"
                  append-icon="mdi mdi-calendar-blank-outline neutral--text"
                />
              </div>
            </template>
          </DatePicker>
        </div>
        <div 
          class="credit-term-block"
          :class="{
            'P-padding-33': clientModalIsOpen,
            'pb-2 w-220': !clientModalIsOpen,
            'ps-4' : isStartNow && !clientModalIsOpen
          }"
        >
          <v-radio-group
            row
            v-model="setRadio"
            :error="$v.credit.radioValue.$error"
            :disabled="credit.isEdit"
          >
            <ott-radio
              v-model="credit.radio[0]"
              :disabled="credit.isEdit"
              :label="credit.radio[0]"
            />
            <ott-radio
              v-model="credit.radio[1]"
              :disabled="credit.isEdit"
              :label="credit.radio[1]"
            />
          </v-radio-group>
          <ott-select
            v-model="selectedCreditTerm"
            :disabled="credit.isEdit"
            :error="$v.credit.selectedCreditTerm.$error"
            :items="credit.radioValue === 'Day' ? daysList : monthsList"
            selectClass="P-client-credit-select"
            selected-text="value"
            label="Credit Term*"
            class="mt-3"
          />
        </div>
      </div>
      <div class="P-padding-100">
        <ott-textarea
          v-model="comment"
          :rows="3"
          :disabled="credit.isEdit"
          class="P-comment-input"
          label="Comment"
        />
      </div>
      <div class="autoextend">
        <ott-switch
          v-model="creditAutoextend"
          :disable="credit.isEdit"
          class-name="secondaryColor"
          label="Credit Autoextend"
          style="font-size: 14px"
        />
      </div>
    </div>
  
    <div class="credit-buttons">
      <SaveResetButtons
        v-if="!credit.isEdit"
        :left-click-func="cancel"
        :right-click-func="startCredit"
        :is-loading="isLoading"
        left-label="Cancel"
        right-label="Start Credit"
      />
      <div v-else class="G-flex">
        <ott-button
          :click="cancelManage"
          text="Cancel"
          custom-class="neutral-sm-btn P-cancel-btn"
        />
        <ott-button
          :click="openStopCreditModal"
          text="Stop Credit"
          custom-class="red-transparent-sm-btn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import DaysMonthMixin from "@/mixins/DaysMonthMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";
// components
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import DatePicker from "v-calendar/src/components/DatePicker";
import TitleComponent from "@/components/TitleComponent";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import SaveResetButtons from "@/components/SaveResetButtons";

export default {
  name: "CreditForm",
  components: {
    OttButtonLoader,
    TitleComponent,
    OttTextarea,
    OttButton,
    OttSelect,
    OttInput,
    OttRadio,
    OttSwitch,
    DatePicker,
    SaveResetButtons
  },
  mixins: [DaysMonthMixin, ConvertTimeZoneMixin, DateFormatMixin],
  data() {
    return {
      menu2: false,
      date: null,
      isStopCredit: true,
    };
  },
  computed: {
    ...mapState({
      balanceManage: (state) => state.balanceCreditClient.balanceManage,
      credit: (state) => state.balanceCreditClient.credit,
      addProviderModal: (state) => state.addProviderModal,
      balanceCredit: (state) => state.balanceCreditClient,
      clientId: (state) => state.clientsModule.clientId,
      clientModalIsOpen: (state) => state.clientsModule.modal.open,
      isLoading: (state) => state.appModule.isLoading
    }),
    clientsPauseAfterDays: {
      get() {
        return this.credit.clientsPauseAfterDays;
      },
    },
    setRadio: {
      get() {
        return this.credit.radioValue;
      },
      set(value) {
        this.setRadioMutation(value);
      },
    },
    comment: {
      get() {
        return this.credit.comment;
      },
      set(value) {
        this.setCreditCommentMutation(value);
      },
    },
    selectedCreditsPauseAfterDays: {
      get() {
        return this.credit.selectedCreditsPauseAfterDays;
      },
      set(value) {
        this.selectedCreditsPauseAfterDaysMutation(value);
      },
    },
    setDate: {
      get() {
        return this.credit.date;
      },
      set(value) {
        this.setDateMutation(value);
      },
    },
    creditAmount: {
      get() {
        return this.credit.creditAmount;
      },
      set(value) {
        this.setCreditAmountMutation(value);
      },
    },
    creditAutoextend: {
      get() {
        return this.credit.creditAutoextend;
      },
      set(value) {
        this.setCreditAutoextendMutation(value);
      },
    },
    selectedCreditTerm: {
      get() {
        return this.credit.selectedCreditTerm;
      },
      set(value) {
        this.selectedCreditTermMutation(value);
      },
    },
    isStartNow: {
      get() {
        return this.credit.isStartNow;
      },
      set(value) {
        this.setIsStartNowMutation(value);
      },
    },
  },
  validations: {
    credit: {
      creditAmount: { required },
      selectedCreditTerm: { required },
      radioValue: { required },
      date: {
        required: requiredIf(function () {
          return !this.isStartNow;
        }),
      },
    },
  },
  methods: {
    ...mapActions({
      addCredit: "balanceCreditClient/addCredit",
      getBalanceCredit: "balanceCreditClient/getBalanceCredit",
      stop: "balanceCreditClient/stopCredit",
    }),
    ...mapMutations({
      resetCredit: "balanceCreditClient/resetCredit",
      resetCreditData: "balanceCreditClient/resetCreditData",
      setShowSaveBtn: "personalInfoClient/setShowSaveBtn",
      setRadioMutation: "balanceCreditClient/setRadio",
      setCreditCommentMutation: "balanceCreditClient/setCreditComment",
      selectedCreditsPauseAfterDaysMutation:
        "balanceCreditClient/selectedCreditsPauseAfterDays",
      setDateMutation: "balanceCreditClient/setDate",
      setCreditAmountMutation: "balanceCreditClient/setCreditAmount",
      setCreditAutoextendMutation: "balanceCreditClient/setCreditAutoextend",
      selectedCreditTermMutation: "balanceCreditClient/selectedCreditTerm",
      setIsStartNowMutation: "balanceCreditClient/setIsStartNow",
      resetBalanceManage: "balanceCreditClient/resetBalanceManage",
      setCreditIsStart: "balanceCreditClient/setIsStart",
      showBalance: "balanceCreditClient/saveBalance",
      setIsStop: "balanceCreditClient/setIsStop",
      setData: "balanceCreditClient/setData",
    }),
    
    async startCredit() {
      this.$v.credit.$touch();
      if (!this.$v.credit.$error) {
        const data = {
          clientId: this.clientId,
          startNow: this.credit.isStartNow,
          creditAmount: this.credit.creditAmount,
          creditStartDate: this.credit.isStartNow
            ? new Date()
            : this.credit.date,
          creditComment: this.credit.comment,
          creditTerm: this.credit.selectedCreditTerm,
          days: this.credit.radioValue === "Day",
          months: this.credit.radioValue === "Month",
          clientsPauseAfterDays:
            this.credit.radioValue === "Day"
              ? this.credit.selectedCreditTerm
              : "",
          creditAutoextend: this.credit.creditAutoextend,
        };
        await this.addCredit(data);
        await this.getBalanceCredit();
      }
    },
    
    async stopCredit() {
      await this.stop({ clientId: this.clientId }).then(async () => {
        await this.getBalanceCredit().then(() => {
          this.setIsStop(true)  
        })
      });
    },

    openStopCreditModal() {
      this.$emit("stopCredit");
    },
    cancelManage() {
      this.resetCredit();
      this.setShowSaveBtn(true);     
    },

    cancel() {
      if (!this.credit?.isStart) {
        this.setData({ showCreditComponents: false })
      } else {
        this.setData({ showCreditComponents: true })
      }

      if (!this.balanceManage?.isSave) {
        this.setData({ showBalanceComponents: false })
      } else {
        this.setData({ showBalanceComponents: true })
      }
    },
    resetData() {
      this.resetCreditData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
@import "./src/assets/scss/datePicker";
@import "src/assets/scss/formStyles";

.credit {
  display: flex;
  flex-direction: column;
  & .credit-fields {
    margin: 0 -10px;
  }

  & > .credit-buttons {
    display: flex;
    margin-top: 20px;
    margin-bottom: 24px;

    & > :nth-child(2) {
      margin-left: 15px;
    }
  }
}

.P-date-picker {
  position: relative;

  .ott-date-picker {
    width: 100%;
  }
}
.buttons::v-deep {
  .P-save-and-reset-block {
    margin-top: 10px;
  }
}

.autoextend {
  width: fit-content;
  padding: 10px;
}

.P-cancel-btn {
  margin-right: 10px;
}

::v-deep {
  .v-input__slot,
  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
  .v-text-field__details {
    display: none;
  }

  .P-client-credit-select .v-menu__content {
  min-height: 100px; /* Adjust the height as needed */
}
}
::v-deep {
  .v-input--selection-controls {
    margin-top: 0;
  }
}

.w-220 {
  width: 220px;
}
</style>
