<template>
  <div class="P-modal-components">
    <TitleComponent title-text="Used Device" />

    <div v-if="locationsList.length">
      <OttLocationsList
        :locations-list="locationsList"
        :selected-data="usedDevicesClient.selectedLocation"
        :width="tabsWidth !== 100 ? 330 : 512"
        @changeLocation="changeLocation"
      />

      <DevicesList v-if="showDevicesList" />
    </div>

    <div v-else class="text-center">
      <p>
        For showing used devices data need to add location(s)
      </p>
    </div>

  </div>
</template>
<script>
import UsedDevicesMixin from "./UsedDevicesMixin";
import TitleComponent from "@/components/TitleComponent";
import OttLocationsList from "@/components/OttLocationsList/OttLocationsList";
import DevicesList from "./components/DevicesList";
import PageLoader from "@/components/pageLoader/PageLoader";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "UsedDevices",
  components: { DevicesList, OttLocationsList, TitleComponent, PageLoader },
  mixins: [UsedDevicesMixin],
  data(){
    return {
      syncClientId: null,
    }
  },
  created() {
      if (this.$route.name === 'Search') {
        this.syncClientId = this.$route.params['id'];
      }
  },
  beforeDestroy() {
    if (this.$route.name === 'Search') {
        this.syncClientId = this.$route.params['id'];
      }
  },
  computed: {
    ...mapState({
      hubState: (state) => state.appModule.hubState,
    }),
  },
  methods: {
  },
  watch: {
  }
};
</script>