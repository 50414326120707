
<template>
  <div>
    <div class="d-flex justify-space-between align-center ">
      <!-- <div class="d-flex align-center P-actions-dates P-input-block mb-0">
        <div class="G-date-picker mb-0">
          <DatePicker
            v-model="fromToDate"
            :masks="{ input: ottDateFormat }"
            :max-date="newDateWithMainUserTZ"
            :popover="{ visibility: 'click' }"
            :is-dark="$vuetify.theme.dark"
            locale="en"
            @input="getUsedDevices"
            is-range
            class="ott-date-picker"
          >
            <template v-slot="{ inputValue, inputEvents }">
                <div class="ott-date-picker-cont Ott-input-class:">
                    <div class="G-calendar-input">
                        <v-text-field
                            v-model="inputValue.start"
                            v-on="inputEvents.start"
                            outlined
                            dense
                            height="38"
                            label="From"
                        />
                        <span
                            class="P-date-icon neutral--text"
                            @click="fromToDate = {start: null, end: null}"
                            :class="
                              !inputValue.start
                                ? 'mdi mdi-calendar-blank-outline P-calendar'
                                : 'icon-cancel'
                            "
                        ></span>
                    </div>
                    <span class="pick-to neutral--text">To</span>
                    <div class="G-calendar-input">
                        <v-text-field
                            v-model="inputValue.end"
                            v-on="inputEvents.end"
                            outlined
                            dense
                            height="38px"
                            label="To"
                        />
                        <span
                            @click="fromToDate = {start: null, end: null}"
                            :class="
                                !inputValue.start
                                ? 'mdi mdi-calendar-blank-outline P-calendar'
                                : 'icon-cancel'
                            "
                            class="P-date-icon neutral--text"
                        ></span>
                    </div>
                </div>
            </template>
          </DatePicker>
        </div>
      </div> -->

      <ott-select
        v-model="chartLimit"
        :items="chartLimits"
        @emitInputChange="getStatisticsData"
        class="w-100"
        label="Limit"
        selected-text="name"
      ></ott-select>
    </div>

    <apexchart
      v-if="chartData.length && seriesData.length"
      :options="chartOptions"
      :series="series"
      :height="Math.max(150, this.seriesData.length * 40) + 'px'"
      class="mt-3"
    ></apexchart>
    
    <p v-if="!chartData.length" class="text-center mt-5">There is no data for statistics</p>

    <ul v-if="chartData.length" class="d-flex align-center justify-center P-legends">
      <li class="d-flex align-center me-4">
        <span class="me-1 green-background chart-legend-mark"></span>
        <span>Live</span>
      </li>

       <li class="d-flex align-center me-4">
        <span class="me-1 yellow-background chart-legend-mark"></span>
        <span>VOD</span>
      </li>

       <li class="d-flex align-center me-4">
        <span class="me-1 red-background chart-legend-mark"></span>
        <span>Record</span>
      </li>
       <li v-if="this.selectedDevice?.settings?.timeShift" class="d-flex align-center">
        <span class="me-1 orange-background chart-legend-mark"></span>
        <span>Time Shift</span>
      </li>
    </ul>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import _ from "lodash";
import TitleComponent from "@/components/TitleComponent";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import PageLoader from "@/components/pageLoader/PageLoader";
import DatePicker from "v-calendar/src/components/DatePicker";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  name: "DeviceStatistics",
  components: {
    DatePicker,
    TitleComponent,
    OttSelect,
    PageLoader
  },

  mixins: [ ConvertTimeZoneMixin, DateFormatMixin ],

  props: {
    chartData: {
      type: Array,
      default: () => null
    }
  },

  watch: {
    chartData: {
      deep: true,
      handler() {
        this.getStatisticsData()
      }
    },
  },

  computed: {
    ...mapState({
      selectedLocation: state => state.usedDevicesClient.selectedLocation,
      usedDevices: state => state.usedDevicesClient.usedDevices,
      isLoading: state => state.usedDevicesClient.isLoading,
      selectedDeviceIndex: state => state.usedDevicesClient.selectedDeviceIndex,
      statisticsFromToDate: state => state.usedDevicesClient.fromToDate
    }),

    fromToDate: {
      get() {
        return this.statisticsFromToDate
      },
      set(fromToDate) {
        this.setData({ fromToDate })
      }
    },

    selectedDevice() {
      if (this.selectedLocation)
        return this.usedDevices[this.selectedDeviceIndex];
      else return null;
    },

    chartOptions() {
      return {
        chart: {
          type: 'bar',
          parentHeightOffset: 0,
          toolbar: {
            offsetX: 0,
            offsetY: 0,
          },
          
          height: Math.max(150, this.seriesData.length * 40) + 'px'
        },
        theme: { 
          mode: this.$vuetify.theme.dark ? 'dark' : 'light'
        },
        plotOptions: {
          bar: {
            barHeight: '16px',
            distributed: true,
            horizontal: true,
            borderRadius: this.$route.name === 'Search' ? 8 : 9,
            borderRadiusApplication: "end",
            dataLabels: {
              enabled: false,
            },
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'end',
          style: {
            colors: this.$vuetify.theme.dark ? ['white'] : ['black'],
            textAlign: 'end'
          },
          formatter:(val, opt) => opt.w.globals.labels[opt.dataPointIndex], // to show label inside bar
          offsetX: 0,
          dropShadow: {
            enabled: this.$vuetify.theme.dark ? true : false
          }
        },
        stroke: {
          show: false,
          width: 0,
          colors: ['transparent']
        },
        xaxis: {
          title: {
            text: 'Hours',
            style: {
              color: this.$vuetify.theme.dark ? 'white' : 'black',
            }
          },
          labels: {
            style: {
              colors: this.$vuetify.theme.dark ? ['white'] : ['black'],
            },
          },
        },
        yaxis: {
          labels: {
            show: false,
            style: {
              colors: this.$vuetify.theme.dark ? ['white'] : ['black'],
              fontWeight: 600,
            },
            align: 'right',
            offsetX: 14,
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
         custom: ({series, seriesIndex, dataPointIndex, w}) => {
          const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return `
            <div class='P-tooltip pa-2'>
              <p 
                class="
                  P-tooltip__title text-center py-1 mb-2
                  ${
                    data.mode === 'L' ? 'green-background' :
                    data.mode === 'V' ? 'yellow-background' :
                    data.mode === 'A' ? 'red-background' : 
                    data.mode === 'T' ? 'orange-background' : ''
                  }
                  ${this.$vuetify.theme.dark ? 'shadow' : ''}  
                ">${data.x}
              </p>
      
              <ul class="pa-0 ma-0">
                <li>
                  <b>Date</b>: 
                  <i>
                    ${this.$moment.unix(data.startDate).format(this.ottDateFormat + ' hh:mm A')} ~ 
                    ${this.$moment.unix(data.startDate).add(data.y, 'seconds').format(this.ottDateFormat + ' hh:mm A')}
                  </i>
                </li>

                <li>
                  <b>Durations</b>: 
                  <i>
                    ${ 
                      Math.floor(data.y / 3600) ? Math.floor(data.y / 3600) + 'h ' + Math.floor((data.y % 3600) / 60) + 'm ' + data.y % 60 + 's'
                        : !Math.floor(data.y / 3600) && Math.floor((data.y % 3600) / 60) ? Math.floor((data.y % 3600) / 60) +'m ' + data.y % 60 + 's'
                        : !Math.floor(data.y / 3600) && !Math.floor((data.y % 3600) / 60) ? Math.floor((data.y % 3600) / 60) +'m ' + data.y % 60 + 's' 
                        : !Math.floor(data.y / 3600) && !Math.floor((data.y % 3600) / 60) && !Math.floor((data.y % 3600) / 60) ? data.y % 60 + 's' : ''
                    }
                  </i>
                </li>
                
                <li>
                  <b>Mode</b>: 
                  <i>
                    ${
                      data.mode === 'L' ? 'Live' :
                      data.mode === 'V' ? 'VOD' :
                      data.mode === 'A' ? 'Record' :
                      data.mode === 'T' ? 'Time Shift' : '~'
                    }
                   </i> 
                </li>

                <li>
                  <b>Time Shift</b>: 
                  <i>${data.timeshift}</i>
                </li>

                <li>
                  <b>Type</b>: 
                  <i class="text-capitalize">${data.type}</i>
                </li>
              </ul>
            </div>
          `
        },
          theme: this.$vuetify.theme.dark ? 'dark' : 'light',
        },
        colors: [...this.colors],
        legend: {
          show: false
        },
        grid: {
          padding: {
            left: -12,
            right: 0,
            bottom: 0,
            top: 0
          },
        },
        states: {
          normal: {
            filter: {
              type: 'none',
            }
          },
          hover: {
            filter: {
              type: 'none',
              value: 0.1
            }
          },
          active: {
            filter: {
              type: 'none',
            }
          },
        }
      }
    },

    series() {
      return [
        {
          name: 'Hours',
          data: [...this.seriesData]
        },
      ]
    },
  },

  created() {
    this.getStatisticsData()
  },

  data() {
    return {
      chartLimit: 10,
      seriesData: [],
      colors: [],

      chartLimits: [
        {
          name: '10',
          value: 10,
        },

        {
          name: 'All',
          value: 0
        }
      ]
    };
  },

  methods: {
    ...mapMutations({
      setData: 'usedDevicesClient/setData',
      resetFromToDate: "usedDevicesClient/resetFromToDate"
    }),

    ...mapActions({
      getUsedDevices: "usedDevicesClient/getUsedDevices"
    }),

    getStatisticsData() {
      const seriesData = []
      const colors = []

      this.chartData.map((item, index) => {
        if ((this.chartLimit && index <= this.chartLimit - 1) || (!this.chartLimit && index <= this.chartData.length-1)) {
          seriesData.push({
            x: item.mode !== 'V' ? item.channel_name : item.vod_name,
            y: item.duration,
            startDate: item.created_at,
            mode: item.mode,
            timeshift: item.timeshift,
            type: item.type
          })

        
          if (item.mode === 'L') {
            colors.push('#6CCF97')
          }

          if (item.mode === 'V') {
            colors.push('#f9f975')
          }

          if (item.mode === 'A') {
            colors.push('#FCA7A7')
          }

          if (item.mode === 'T') {
            colors.push('#FEB300')
          }
        }
      })

      this.seriesData = [...seriesData]
      this.colors = [...colors]
    },
  },
};
</script>

<style lang="scss" scoped>
 @import "src/assets/scss/variables";

  ::v-deep {
    .v-text-field__details, .v-messages {
      display: none;
    }
  }

  .P-legends {
    font-size: $txt14;
  }

  .chart-legend-mark {
    width: 13px;
    height: 13px;
    border-radius: 4px;
  }

  .w-100 {
    width: 100px;
  }


</style>

<style lang="scss">
  .apexcharts-theme-dark svg {
    background: transparent !important;
  }

  .P-tooltip {
    &__title {
      font-size: 14px;
      font-weight: bold;
      border-radius: 4px;
    }

    .shadow {
      text-shadow: 1px 1px 2px black;
    }
  }

  .green-background {
    background-color: #6CCF97; 
  }

  .yellow-background {
    background-color: #f9f975;
  }

  .red-background {
    background-color: #FCA7A7;
  }

  .orange-background {
    background-color: #FEB300;
  }
</style>
