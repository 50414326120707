var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"P-modal-component-body"},[_c('div',{staticClass:"P-manage-contact"},[(_vm.contact.emailSection)?_c('div',{staticClass:"P-manage-contact-box"},[(
          !_vm.allEmails.length ||
          (_vm.contact.manageValue === _vm.contact.manageType.email &&
          (_vm.contact.isEditMode || _vm.contact.isAddMode))
        )?_c('div',{staticClass:"P-contact-box"},[(!_vm.allEmails.length && !_vm.showEmailComponents)?_c('TitleComponent',{attrs:{"is-add":true,"click":() => _vm.showEmailComponents = true,"title-text":"Add Email Address"}}):_vm._e(),(_vm.showEmailComponents)?_c('div',[_c('TitleComponent',{attrs:{"title-text":_vm.getEmailName()}}),_c('EmailContent',{ref:"contactEmail"})],1):_vm._e()],1):_c('ContactListEmail',{ref:"emailList",on:{"deleteEmail":function($event){return _vm.openDeleteModal($event)}}})],1):_vm._e(),(_vm.contact.phoneSection)?_c('div',{staticClass:"P-manage-contact-box"},[(
          !_vm.allPhones.length ||
          (_vm.contact.manageValue === _vm.contact.manageType.phone &&
          (_vm.contact.isEditMode || _vm.contact.isAddMode))
        )?_c('div',{staticClass:"P-contact-box"},[(!_vm.allPhones.length && !_vm.showPhoneComponents)?_c('TitleComponent',{attrs:{"is-add":true,"click":() => _vm.showPhoneComponents = true,"title-text":"Add Phone Number"}}):_vm._e(),(_vm.showPhoneComponents)?_c('div',[_c('TitleComponent',{attrs:{"title-text":_vm.getPhoneNumberTitle()}}),_c('PhoneContent',{ref:"contactPhone"})],1):_vm._e()],1):_c('ContactListPhone',{ref:"phoneList",on:{"deletePhone":function($event){return _vm.openDeleteModal($event)}}})],1):_vm._e()]),(_vm.showWarningModal)?_c('WarningModal',{attrs:{"isModalOpen":_vm.showWarningModal,"modalHeading":_vm.warningModalHeading,"modalText":_vm.warningModalText,"btnText":_vm.warningModalBtnText,"isDangerButton":_vm.warningModalIsDangerButton,"isLoading":_vm.warningModalIsLoading},on:{"closeModal":function($event){_vm.showWarningModal = false},"btnFunction":_vm.warningModalBtnFunc}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }