<template>
  <div class="P-packages-list-block P-manage-client">
    <div class="P-packages-list-header">
      <div class="P-package-information">
        <ul class="G-align-center">
          <li v-if="selectedLocation?.createdAt">
            <p class="neutral--text">Registration Date</p>
            <p class="secondary--text">
              {{ selectedLocation.createdAt }}
            </p>
          </li>
          <li
            v-if="
              selectedLocation.isPauseSubscriptions &&
              selectedLocation.pauseStartDate &&
              selectedLocation.subscriptionState === 3
            "
          >
            <p class="neutral--text">In Pause</p>
            <p class="secondary--text">{{ selectedLocation.pauseStartDate }}</p>
          </li>
          <li
            v-if="
              selectedLocation &&
              selectedLocation.subscriptionState === 2 &&
              selectedLocation?.subscriptionCancelDate
            "
          >
            <p class="neutral--text">In Cancelation</p>
            <p class="secondary--text">
              {{ selectedLocation?.subscriptionCancelDate }}
            </p>
          </li>
          <li v-if="selectedLocation && selectedLocation.discount">
            <p class="neutral--text">DISCOUNT</p>
            <p class="secondary--text">{{ selectedLocation.discount }}%</p>
          </li>
          <li
            v-if="
              selectedLocation && selectedLocation.subscriptionState !== null
            "
          >
            <p class="neutral--text">Location Status</p>
            <p
              class="secondary--text d-flex flex-column"
              v-html="selectedLocationStatus"
            />
          </li>
        </ul>
      </div>
      <div class="P-action-block G-align-center">
        <div
          :class="{ 'P-big-select': $route.name !== 'Search' }"
          class="P-padding-33"
        >
          <ott-select
            :value="roomsCount"
            :items="roomsList"
            @emitInputChange="roomChange"
            label="Room"
            selected-text="value"
          ></ott-select>
        </div>
        <div
          :class="{ 'P-big-select': $route.name !== 'Search' }"
          class="P-padding-33"
        >
          <ott-select
            :value="actionType"
            @emitInputChange="actionChange"
            :items="packagesClients.actionsList"
            :multiple="false"
            :persistentHint="true"
            selected-text="name"
            label="Action"
            clear
          />
        </div>
        <div
          v-if="
            actionType === packagesClients.actionTypes.subscribeFromEndMaxExpire
          "
        >
          <v-radio-group
            :value="dayMonthType"
            @change="dayMonthTypeChange"
            class="G-radio-group"
          >
            <div class="P-full-line G-flex">
              <div class="P-radio-box P-radio-day">
                <OttRadio
                  :label="$route.name !== 'Search' ? 'Day' : 'D'"
                  value="day"
                />
              </div>
              <div class="P-radio-box">
                <OttRadio
                  :label="$route.name !== 'Search' ? 'Month' : 'M'"
                  value="month"
                />
              </div>
            </div>
          </v-radio-group>
        </div>
        <div
          v-if="
            actionType === packagesClients.actionTypes.subscribeFromEndMaxExpire
          "
          :class="{ 'P-dayMonth-select': $route.name !== 'Search' }"
          class="P-padding-20"
        >
          <ott-select
            :value="dayMonthValue"
            :label="dayMonthType === 'day' ? 'Day' : 'Month'"
            :items="dayMonthType === 'day' ? daysList : monthsList"
            :multiple="false"
            :persistentHint="true"
            @emitInputChange="dayMonthValueChange"
            selected-text="value"
          />
        </div>
        <div
          class="P-padding-33 G-date-picker"
          v-if="actionType === packagesClients.actionTypes.subscribeToDate"
        >
          <DatePicker
            :value="subscribeToDate"
            :masks="{ input: ottDateFormat }"
            :min-date="$moment(newDateWithMainUserTZ).add(1, 'days')._d"
            :popover="{ visibility: 'click' }"
            :is-dark="$vuetify.theme.dark"
            @input="subscribeToDateChange"
            locale="en"
            class="ott-date-picker"
          >
            <template v-slot="{ inputEvents }">
              <div class="ott-date-picker-cont">
                <v-text-field
                  :value="subscribeToDate | moment(ottDateFormat)"
                  :readonly="true"
                  :height="38"
                  v-on="inputEvents"
                  outlined
                  dense
                  label="Date"
                  class=""
                  append-icon="mdi mdi-calendar-blank-outline neutral--text"
                />
              </div>
            </template>
          </DatePicker>
        </div>
      </div>
    </div>
    <div class="P-packages-list-body">
      <div class="P-label-list">
        <table
          v-if="packagesList.length && isExistPrice && !loading"
          :class="{
            'P-table-for-search': $route.name === 'Search' && tabsWidth !== 100,
          }"
        >
          <tr>
            <th class="secondary--text">Name</th>
            <th class="secondary--text">Type</th>
            <th
              v-if="
                selectedLocation.subscriptionState === 3 &&
                selectedLocation.subscriptionActivationDate !== null
              "
              class="secondary--text P-width-140"
            >
              Start Date
            </th>
            <th
              class="secondary--text"
              :class="{
                'P-width-140': selectedLocation.subscriptionState === 3,
              }"
            >
              Expire Date -
              <span class="P-rooms-count-span">{{
                roomsCountOld ? `${roomsCountOld} Rm` : ""
              }}</span>
            </th>
            <th
              v-if="
                paymentList.length &&
                packagesData.locations[locationIndexFromPackagesData]
                  .packageInfos.length
              "
              class="secondary--text"
            >
              Recurring Payment
            </th>
            <th class="secondary--text">Current Price</th>
            <th class="secondary--text P-width-140">
              Expire New {{ roomsCountNew ? "-" : "" }}
              <span class="P-rooms-count-span">{{
                roomsCountNew ? `${roomsCountNew} Rm` : ""
              }}</span>
            </th>
            <th class="secondary--text">
              Total Price <br />
              (USD)
            </th>
          </tr>
          <tr
            v-for="(item, index) of packagesList"
            :key="index"
            class="P-hovered-tr"
          >
            <td class="secondary--text">
              <div
                class="Ott-checkbox-cont G-align-center"
                :class="{
                  'ps-3':
                    $route.name !== 'Search' ||
                    ($route.name === 'Search' && tabsWidth === 100),
                }"
              >
                <v-checkbox
                  v-model="
                    packagesData.locations[locationIndexFromPackagesData]
                      .packageInfos
                  "
                  :disabled="item?.disabled"
                  :value="item.packageId"
                  class="w-max-content pt-0"
                  @change="packageCheckboxChanged"
                />
                <p class="secondary--text nowrap-text">
                  {{ getPackageName(item.packageName) }}
                </p>
              </div>
            </td>

            <td class="secondary--text nowrap-text">
              {{ item.packageType === 1 ? "Base" : "Advanced" }}
            </td>
            <td
              v-if="selectedLocation.subscriptionState === 3"
              class="secondary--text P-width-140"
            >
              <span
                v-if="
                  packagesData.locations[
                    locationIndexFromPackagesData
                  ].packageInfos.includes(item.packageId)
                "
              >
                <span v-if="item.startDate !== undefined">{{
                  item.startDate
                }}</span>
              </span>
            </td>
            <td
              class="secondary--text"
              :class="{
                'P-width-140': selectedLocation.subscriptionState === 3,
              }"
            >
              <span v-if="item.expireDate !== undefined">{{
                item.expireDate
              }}</span>

              <span
                v-if="
                  selectedLocation.subscriptionState === 1 &&
                  packagesData.locations[
                    locationIndexFromPackagesData
                  ].packageInfos.includes(item.packageId)
                "
                class="G-badge G-pending-badge mx-auto"
              >
                Pending
              </span>
            </td>
            <td
              v-if="
                paymentList.length &&
                packagesData.locations[locationIndexFromPackagesData]
                  .packageInfos.length
              "
              class="secondary--text"
            >
              <ott-checkbox
                v-if="
                  paymentList.length &&
                  packagesData.locations[
                    locationIndexFromPackagesData
                  ].packageInfos.includes(item.packageId) &&
                  paymentForPackagesObj?.id
                "
                :disabled="item?.disabled"
                v-model="item.recurringPayment"
                class="pt-0 mx-auto w-max-content"
                @updated="recurringPaymentCheck(item.recurringPayment)"
              />
            </td>
            <td class="secondary--text">
              {{
                item.currentPrice === -1
                  ? "Not defined"
                  : new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                    }).format(item.currentPrice)
              }}
            </td>
            <td>
              <span class="text-danger" v-if="item.canceled"> Canceled </span>
              <span class="text-success" v-else>
                {{ item.expireNew }}
              </span>
            </td>
            <td class="secondary--text">
              {{
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                }).format(item.totalPrice)
              }}
            </td>
          </tr>
        </table>
      </div>
      <div
        v-if="
          packagesList.length &&
          (actionType ===
            packagesClients.actionTypes.subscribeFromEndMaxExpire ||
            actionType ===
              packagesClients.actionTypes.subscribeToEndMaxExpire ||
            actionType === packagesClients.actionTypes.subscribeToDate)
        "
        class="P-total-price G-justify-end pe-6"
      >
        <p>Total Price</p>
        <p>
          {{
            packagesData.locations[locationIndexFromPackagesData].totalPrice !==
            0
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                }).format(
                  packagesData.locations[locationIndexFromPackagesData]
                    .totalPrice
                )
              : "$0.00"
          }}
        </p>
      </div>
    </div>

    <!-- <div
      v-if="
        !isOpenManagePackagesModal &&
        showPackagesPayBtn &&
        packagesTotalPrice !== 0 &&
        (packagesList.length || isExistPrice)
      "
      class="footer"
    >
      <ott-button-loader
        @onClick="openCheckout"
        :disabled="!allowCheckout"
        :button-text="packagesPayBtnLabel"
        className="primary-sm-btn P-login-btn"
      />
    </div> -->
    <v-skeleton-loader
      v-if="(!packagesList.length && isExistPrice) || loading"
      type="table-heading, table-thead, table-tbody"
    />

    <!-- v-if="!isExistPrice && !packagesList.length" -->
    <div v-if="!isExistPrice" class="text-center mt-5 pt-5">
      <p class="pt-5">
        For showing packages data need to add packages price by type of client
      </p>
    </div>

    <Transition name="fade">
      <v-alert
        v-if="showRecurringMessage"
        dense
        text
        class="mt-5"
        type="success"
        style="width: max-content"
      >
        The Recurring Payment Is {{ recurringSelectedText }}
      </v-alert>
    </Transition>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { eventBus } from "@/main";
import DaysMonthMixin from "@/mixins/DaysMonthMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";

import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import DatePicker from "v-calendar/src/components/DatePicker";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttDatePicker from "@/components/vuetifyComponents/OttDatePicker";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttDialog from "@/components/vuetifyComponents/OttDialog";

export default {
  name: "PackagesList",
  components: {
    OttCheckbox,
    OttRadio,
    OttSelect,
    DatePicker,
    OttDatePicker,
    OttButtonLoader,
    SaveResetButtons,
    OttDialog,
  },

  mixins: [DateFormatMixin, DaysMonthMixin, ConvertTimeZoneMixin],

  data() {
    return {
      actionSelected: false,
      canUpdated: false,
      showResetBtn: false,
      checkedPackages: [],
      requrringTimeOut: null,
      showRecurringMessage: false,
      recurringSelectedText: "",
      payedPackagesCount: 0,
      warningModalIsLoading: false,
      locationComments: [],
      roomsCountOld: null,
      loading: false,
    };
  },

  computed: {
    ...mapState({
      packagesClients: (state) => state.packagesClients,
      mainPackagesList: (state) => state.packagesModule.dataTable,
      locationList: (state) => state.locationClient.locationList,
      initialLocationList: (state) => state.locationClient.initialLocationList,
      activeTab: (state) => state.clientsModule.activeTab,
      clientId: (state) => state.clientsModule.clientId,
      clientEquipments: (state) => state.equipments.clientEquipments,
      equipmentsIsAddMode: (state) => state.equipments.isAddMode,
      equipmentsIsEditMode: (state) => state.equipments.isEditMode,
      equipmentsIsReturnMode: (state) => state.equipments.isReturnMode,
      dataForPackages: (state) => state.packagesClients.dataForPackages,
      isGeneralSearch: (state) => state.generalSearch.isGeneralSearch,
      allowCheckout: (state) => state.packagesClients.allowCheckout,
      packagesPayBtnLabel: (state) => state.packagesClients.packagesPayBtnLabel,
      paymentList: (state) => state.paymentMethodClients.paymentList,
      tabsWidth: (state) => state.generalSearch.tabsWidth,
      paymentForPackagesObj: (state) =>
        state.financeClient.paymentForPackagesObj,
      isLoading: (state) => state.appModule.isLoading,
    }),

    roomsList() {
      const list = [];
      for (let i = 1; i <= 5; i++) {
        list.push({
          name: i,
          value: i,
        });
      }
      return list;
    },

    canceledPackagesCount() {
      let count = 0;

      if (this.packagesList.length) {
        //check all canceled packages count
        this.packagesList.map((item) => {
          if (
            !this.packagesData.locations[
              this.locationIndexFromPackagesData
            ].packageInfos.includes(item.packageId) &&
            item.expireDate &&
            item.expireNew === undefined
          ) {
            count++;
          }
        });
      }

      return count;
    },

    selectedLocationStatus() {
      /*
        location status by subscriptionState value
        0 - Inactive
        1 - Pending
        2 - Canceled
        3 - Active
      */

      let status = "";
      let pauseDuration = null;
      let expireDate = null;
      let pauseStartDate = null;

      if (this.selectedLocation) {
        const location = this.selectedLocation;

        //get pause duration
        if (location?.subscriptionExpireDate) {
          expireDate = this.$moment(location.subscriptionExpireDate);
          pauseStartDate = this.$moment(location.pauseStartDate);
          pauseDuration = this.convertMsToDays(expireDate.diff(pauseStartDate));
        }

        if (
          location.isBlockLocation === undefined ||
          location.isPauseSubscriptions === undefined ||
          (!location.isBlockLocation && !location.isPauseSubscriptions)
        ) {
          status = "";
        }

        if (location.subscriptionState === 0) {
          status = `<span class="G-badge G-advanced-badge">Inactive</span>`;
        }

        if (location.subscriptionState === 1) {
          status = `<span class="G-badge G-pending-badge">Pending ${
            location?.subscriptionPendingDate
              ? location.subscriptionPendingDate
              : ""
          }</span>`;
        }

        if (location.subscriptionState === 2) {
          status = `<span class="G-badge G-disabled-badge">Canceled ${
            location?.subscriptionCancelDate
              ? location.subscriptionCancelDate
              : ""
          }</span>`;
        }

        if (location.subscriptionState === 3) {
          if (location.isBlockLocation && location.isPauseSubscriptions) {
            status = `<span class="G-badge G-reject-badge mb-2">Blocked</span>
                      <span class="G-badge G-paused-badge">Paused ${pauseDuration}</span>`;
          }

          if (location.isBlockLocation && !location.isPauseSubscriptions) {
            status = `<span class="G-badge G-reject-badge">Blocked</span>`;
          }

          if (!location.isBlockLocation && location.isPauseSubscriptions) {
            status = `<span class="G-badge G-paused-badge">Paused ${pauseDuration}</span>`;
          }

          if (!location.isBlockLocation && !location.isPauseSubscriptions) {
            status = `<span class="G-badge G-approve-badge">Active ${
              location?.subscriptionActivationDate
                ? location.subscriptionActivationDate
                : ""
            }</span>`;
          }
        }
      }

      return status;
    },

    packagesData() {
      return this.packagesClients.packagesData;
    },

    isExistPrice() {
      let isExist = true;
      // TODO always must be price;
      // if (this.mainPackagesList.length) {
      //   this.mainPackagesList.map((item) => {
      //     if (item.clientPrice !== -1) {
      //       isExist = true;
      //     }
      //   });
      // }
      return isExist;
    },

    packagesList() {
      let packagesList = [];
      if (this.packagesData.locations !== undefined) {
        this.packagesData.locations.map((item) => {
          if (item.locationId === this.selectedLocation.id) {
            packagesList = [...item.packagesList];
          }
        });
      }

      return packagesList;
    },

    roomsCountNew() {
      return this.packagesData.locations[this.locationIndexFromPackagesData]
        .roomsCountNew;
    },

    selectedLocation() {
      return this.packagesClients.selectedLocation;
    },

    locationIndexFromPackagesData() {
      let locationIndex = null;
      if (this.packagesData.locations && this.packagesData.locations.length) {
        this.packagesData.locations.map((item, index) => {
          item.locationId === this.selectedLocation.id
            ? (locationIndex = index)
            : "";
        });
      }

      return locationIndex;
    },

    locationIndexFromCommentsList() {
      let locationIndex = null;
      if (this.locationComments.length) {
        this.locationComments.map((item, index) => {
          item.locationId === this.selectedLocation.id
            ? (locationIndex = index)
            : "";
        });
      }

      return locationIndex;
    },

    /**
     * v-models
     * **/
    roomsCount() {
      let roomsCount = null;
      if (this.packagesData.locations && this.packagesData.locations.length) {
        this.packagesData.locations.map((item) => {
          if (item.locationId === this.selectedLocation.id) {
            roomsCount = item.roomsCountNew
              ? item.roomsCountNew
              : this.roomsCountOld;
          }
        });
      }
      return roomsCount;
    },

    actionType() {
      let actionType = 1;
      if (this.packagesData.locations && this.packagesData.locations.length) {
        this.packagesData.locations.map((item) => {
          if (item.locationId === this.selectedLocation.id) {
            actionType = item.actionType;
          }
        });
      }
      return actionType;
    },

    dayMonthType() {
      let dayMonthType = "month";
      if (this.packagesData.locations && this.packagesData.locations.length) {
        this.packagesData.locations.map((item) => {
          if (item.locationId === this.selectedLocation.id) {
            dayMonthType = item.dayMonthType;
          }
        });
      }
      return dayMonthType;
    },

    dayMonthValue() {
      let dayMonthValue = 0;
      if (this.packagesData.locations && this.packagesData.locations.length) {
        this.packagesData.locations.map((item) => {
          if (item.locationId === this.selectedLocation.id) {
            dayMonthValue = item.dayMonthValue;
          }
        });
      }
      return dayMonthValue;
    },

    subscribeToDate() {
      let subscribeToDate = this.$moment(new Date()).add(1, "days");
      if (this.packagesData.locations && this.packagesData.locations.length) {
        this.packagesData.locations.map((item) => {
          if (item.locationId === this.selectedLocation.id) {
            subscribeToDate = item.subscribeToDate;
          }
        });
      }
      return subscribeToDate;
    },
  },

  watch: {
    packagesList: {
      handler() {
        /**
         * If not exist any active package remove subscribeToEndMaxExpire action from actions list
         * **/
        this.setActionsList();
      },
      deep: true,
    },

    locationList: {
      async handler(newList, oldList) {
        if (
          oldList.length &&
          newList.length &&
          newList.length !== oldList.length
        ) {
          // set packagesData locations, equipments keys for get packages data
          this.setPackagesDataLocations();
          await this.getPackages();
        }
      },
      deep: true,
    },

    async selectedLocation() {
      this.loading = true;
      this.getPackages().finally(() => (this.loading = false));
    },
  },
  beforeDestoy() {
    this.$hubEventBus.$off("package-info", this.handlePackageInfo); // if loggedIn get permissions with WS
  },
  updated() {
    if (this.packagesData?.locations) {
      this.roomsCountOld =
        this.packagesData.locations[
          this.locationIndexFromPackagesData
        ].roomsCount;
    }
  },
  async created() {
    this.$hubEventBus.$on("package-info", this.handlePackageInfo); // if loggedIn get permissions with WS

    // set packageData locations for get packages
    this.setPackagesDataLocations();

    if (this.packagesData?.locations) {
      await this.getPackages();
      this.roomsCountOld =
        this.packagesData.locations[
          this.locationIndexFromPackagesData
        ].roomsCount;
    }

    if (this.selectedLocation?.id) {
      //get payed packages count
      await this.getSubscriptions({ location: this.selectedLocation.id }).then(
        (res) => {
          this.payedPackagesCount = res.length;
        }
      );
    }

    //after change location remove payedPackagesCount for hide comment modal
    eventBus.$on("locationChanged", () => {
      this.payedPackagesCount = 0;
      // await this.getPackages()
    });
  },
  methods: {
    async handlePackageInfo(info) {
      this.getPackages();
      console.log("package disabled or enabled");
    },
    ...mapActions({
      save: "packagesClients/save",
      getLocationPackages: "packagesClients/getLocationPackages",
      setCheckoutInvoice: "packagesClients/setCheckoutInvoice",
      getSubscriptions: "packagesModule/getSubscriptions",
    }),

    ...mapMutations({
      setData: "packagesClients/setData",
      setRoomsCount: "packagesClients/setRoomsCount",
      setPackagesDataLocationsKeys:
        "packagesClients/setPackagesDataLocationsKeys",
      setDataLocations: "locationClient/setData",
      openCheckoutModal: "checkoutClients/openModal",
    }),

    setPackagesDataLocations() {
      // set packageData locations for get packages
      const packagesData = {
        locations: [],
      };

      this.locationList.map((item) => {
        packagesData.locations.push({
          locationId: item.id,
          roomsCountNew: null,
          actionType: 1,
          dayMonthType: "month",
          dayMonthValue: 0,
          subscribeToDate: this.$moment(new Date()).add(1, "days"),
          totalPrice: null,
          packagesList: [],
          packageInfos: [],
          packageRemoves: [],
        });

        this.locationComments.push({
          locationId: item.id,
          comment: null,
        });
      });

      this.setData({
        packagesData,
      });
    },

    convertMsToDays(t) {
      let cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor((t - d * cd) / ch),
        m = Math.round((t - d * cd - h * ch) / 60000);

      return `${d}d ${h}h ${m}m`;
    },

    async packageCheckboxChanged() {
      await this.getPackages();

      //get payed packages count
      await this.getSubscriptions({ location: this.selectedLocation.id }).then(
        (res) => {
          this.payedPackagesCount = res.length;
        }
      );
    },

    getPackageName(nameArray) {
      if (nameArray && nameArray.length) {
        let engName = nameArray.filter(
          (name) => name.lang === "en" || name.lang === "EN"
        );
        let ruName = nameArray.filter(
          (name) => name.lang === "ru" || name.lang === "RU"
        );
        if (engName && engName.length) {
          return engName[0].name;
        } else {
          return ruName[0].name;
        }
      }
    },

    async reset() {
      this.dayMonthType = "month";
      this.dayMonthValue = 1;
      this.subscribeToDate = this.$moment(new Date()).add(1, "days");
      this.actionType = 1;

      //set locations to initial position
      this.setDataLocations({
        locationList: JSON.parse(JSON.stringify(this.initialLocationList)),
      });

      //after reset set selectedLocation with current selectedLocation id from inital list
      if (this.packagesClients.selectedLocation) {
        let selectedLocId = null;
        selectedLocId = this.packagesClients.selectedLocation.id;

        this.locationList.map((item) => {
          if (item.id === selectedLocId) {
            this.setData({
              selectedLocation: { ...item },
            });
          }
        });
      }

      await this.getPackages();
    },

    getDiffDays(date1, date2) {
      return (
        (new Date(date2).getTime() - new Date(date1).getTime()) /
        (1000 * 3600 * 24)
      );
    },

    setActionsList() {
      /**
       * If not exist any active package remove subscribeToEndMaxExpire action from actions list
       * **/
      if (this.packagesList.length) {
        let isActivePckage = false;

        this.packagesList.map((item) => {
          if (item.expireDate) {
            isActivePckage = true;
          }
        });

        if (isActivePckage) {
          this.setData({
            actionsList: [
              { name: "Subscribe From End Of Max Expire", value: 1 },
              { name: "Subscribe To End Of Max Expire", value: 2 },
              //TODO: need to remove in future if this is correct business logic
              // { name: "Subscribe To Date", value: 3 },
            ],
          });
        } else {
          this.setData({
            actionsList: [
              { name: "Subscribe From End Of Max Expire", value: 1 },
              //TODO: need to remove in future if this is correct business logic
              // { name: "Subscribe To Date", value: 3 },
            ],
          });
        }
      }
    },

    async roomChange(roomsCountNew) {
      this.setPackagesDataLocationsKeys({ roomsCountNew });
      await this.getPackages();
    },

    async actionChange(actionType) {
      this.setPackagesDataLocationsKeys({ actionType });
      await this.getPackages();
    },

    async dayMonthTypeChange(dayMonthType) {
      this.setPackagesDataLocationsKeys({ dayMonthType });
      await this.getPackages();
    },

    async dayMonthValueChange(dayMonthValue) {
      this.setPackagesDataLocationsKeys({ dayMonthValue });
      await this.getPackages();
    },

    async subscribeToDateChange(subscribeToDate) {
      this.setPackagesDataLocationsKeys({ subscribeToDate });
      await this.getPackages();
    },

    async getPackages() {
      this.dataForGetPackages();
      await this.getLocationPackages({ ...this.dataForPackages });
    },

    async recurringPaymentCheck(isSelected) {
      clearTimeout(this.requrringTimeOut);
      this.showRecurringMessage = true;
      isSelected
        ? (this.recurringSelectedText = "Selected")
        : (this.recurringSelectedText = "Unselected");

      this.requrringTimeOut = setTimeout(() => {
        this.showRecurringMessage = false;
      }, 1200);

      await this.getPackages();
    },

    dataForGetPackages() {
      let obj = {};
      const dataForPackages = {
        locations: [],
        equipments: [],
        selectedLocation: this.selectedLocation?.id,
        client: "",
      };

      dataForPackages.locations = [];
      dataForPackages.client = this.clientId;
      dataForPackages.equipments = [...this.clientEquipments];
      dataForPackages.equipments = dataForPackages.equipments.filter(
        (item) => item.id === undefined
      );
      dataForPackages.equipments.map((item) => {
        if (typeof item.equipment === "object") {
          item.equipment = item.equipment.id;
        }
        delete item.accessToDelete;
        delete item.type;
        delete item.purchaseDate;
        delete item.returnDate;
      });

      if (this.packagesData.locations && this.packagesData.locations.length) {
        this.packagesData.locations.map((item) => {
          obj.locationId = item.locationId;
          obj.packageInfos = [...item.packageInfos];
          obj.packageRemoves = [...item.packageRemoves];

          obj.recurringPaymentInfos =
            obj.recurringPaymentInfos !== undefined
              ? obj.recurringPaymentInfos
              : [];
          //get recurringPaymentInfos for every locations package
          item.packagesList.map((p) => {
            obj.recurringPaymentInfos.push({
              packageId: p.packageId,
              recurringPayment: p.recurringPayment,
            });

            if (p.expireDate && !item.packageInfos.includes(p.packageId)) {
              obj.packageRemoves.push(p.packageId);
            }
          });

          //get roomsCountNew
          if (this.roomsCountNew) {
            obj.room = this.roomsCountNew;
          }

          //get actions

          if (item.actionType === 1) {
            obj.globalAction = item.actionType;
            item.dayMonthType === "month"
              ? (obj.month = item.dayMonthValue)
              : (obj.day = item.dayMonthValue);
          }

          if (item.actionType === 3) {
            obj.globalAction = item.actionType;
            item.subscribeToDate
              ? (obj.subscribeToDate = item.subscribeToDate)
              : "";
          }

          if (item.actionType !== 1 && item.actionType !== 3) {
            obj.globalAction = item.actionType;
          }

          // if (item.actionType === null) {
          //   delete obj.globalAction
          // }

          dataForPackages.locations.push(obj);

          obj = {};
        });
      }

      this.setData({ dataForPackages });
    },

    async openCheckout() {
      //get payed packages count
      let payedPackagesCount = 0;
      await this.getSubscriptions({ location: this.selectedLocation.id }).then(
        (res) => {
          payedPackagesCount = res.length;
        }
      );

      //check if location is canceled but not exist comment
      if (
        this.canceledPackagesCount &&
        payedPackagesCount &&
        this.canceledPackagesCount === payedPackagesCount &&
        !this.locationComments[this.locationIndexFromCommentsList].comment
      ) {
        this.payedPackagesCount = payedPackagesCount;
      } else {
        await this.setCheckoutInvoice({
          ...this.dataForPackages,
        }).then(() => this.openCheckoutModal());
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/tableList";
@import "src/assets/scss/formStyles";

.P-packages-list-body {
  margin-top: 14px;
}

.P-packages-list-header {
  margin-top: 15px;
}

.P-package-information {
  ul {
    padding: 0;

    li {
      display: flex;
      align-items: center;
      padding-left: 25px;
      margin-left: 25px;
      border-left: 1px solid $secondary-color;
      &:first-child {
        padding-left: 0;
        margin-left: 0;
        border-left: unset;
      }

      p {
        margin: 0;
        font-size: $txt14;

        &:first-child {
          font-weight: $semiBold;
          margin-right: 20px;
        }
      }
    }
  }
}

.P-action-block {
  margin: 15px -10px 0;
  .P-full-line {
    margin-left: 12px;
    margin-right: 12px;
    .P-radio-day {
      margin-right: 12px;
    }
  }
}

.P-radio-box::v-deep {
  .v-label {
    top: -1px !important;
  }
}

.G-align-center {
  margin-bottom: 0 !important;
}

.P-total-price {
  margin-top: 15px;
  p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: $txt14;
    color: $primary-color;

    &:first-child {
      min-width: 100px;
      max-width: 100px;
    }
  }
}

.P-big-select {
  min-width: 253px;
}

.P-dayMonth-select {
  width: 125px;
}

//ott checkbox styles

.Ott-checkbox-cont::v-deep {
  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-label {
    font-size: $txt14;
    text-transform: capitalize !important;
  }

  .theme--light {
    &.v-label {
      color: $secondary-color;
    }

    &.v-icon {
      color: $neutral-color;
    }
  }

  .v-input--is-label-active {
    .v-input--selection-controls__input {
      &:before {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }
  }

  .theme--dark {
    &.v-input--is-disabled {
      .v-input--selection-controls__input {
        &:before {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          background-color: transparent;
          left: 4px;
          top: 4px;
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .v-input--selection-controls__input {
      &:before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #ffffff;
        left: 6px;
        top: 6px;
        opacity: 0;
        visibility: hidden;
      }
    }

    &.v-label {
      color: $white;
    }

    .v-icon {
      color: $white;
    }

    .P-test {
      .v-icon {
        color: $primary-color !important;
      }
    }

    .primary--text {
      color: $primary-colorDM !important;
    }
  }

  .v-input--indeterminate {
    .v-icon {
      color: $primary-color !important;
    }
  }

  .v-input--indeterminate {
    .v-input--selection-controls__input {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.P-test::v-deep {
  .v-icon {
    color: $primary-color !important;
  }
}

.P-disable::v-deep {
  .v-label {
    opacity: 0.5;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #a5aac6;
  margin: 24px 0;
  padding: 24px 24px 0;
}

::v-deep {
  .v-skeleton-loader__table-tbody {
    padding: 0;
  }
  // disable vuetify checkbox ripple effect
  .v-input--selection-controls__ripple {
    display: none;
  }

  .G-badge {
    width: max-content;
    padding: 3px 10px;
  }
}

.P-width-140 {
  max-width: 140px !important;
  min-width: 140px !important;
}

.text-success {
  color: #5fc788;
}

.text-danger {
  color: #c75f62;
}

.P-table-for-search::v-deep {
  th,
  td {
    font-size: 13px;
  }
}

.nowrap-text {
  white-space: nowrap;
}

.square {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
</style>
