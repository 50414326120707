<template>
  <div class="'P-contact-list-block">
    <div class="P-contact-list-box">
      <TitleComponent title-text="Email Addresses" :is-add="true" :click="addEmail"/>
      <div class="P-table-block">
        <div class="P-table-header">
          <ul class="G-flex">
            <li v-for="(item, index) of emailHeader" :key="index" class="secondary--text" >{{ item }}</li>
            <li></li>
          </ul>
        </div>
        <div class="P-table-body">
          <ul class="G-flex" v-for="(item, index) of allEmails" :key="index">
            <li><p class="secondary--text">{{ item.email }}</p></li>
            <li><span v-if="item.isMain" class="mdi mdi-check checkTable"/></li>
            <li><span v-if="item.forContactInvoice" class="mdi mdi-check checkTable"/></li>
            <li class="G-align-center">
              <div class="P-actions">
                <v-menu left content-class="P-actions-list">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item class="P-list" @click="editEmail(item, index)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="P-list" @click="$emit('deleteEmail', {index, type: 'email', email : item.email})">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  // component
  import TitleComponent from "@/components/TitleComponent";

  export default {
    name: 'ContactListEmail',
    components: {TitleComponent},
    data() {
      return {
        emailHeader: [
          'Email Address', 'Main', 'Contact / Invoices'
        ],
      }
    },
    computed: {
      ...mapState({
        contact: state => state.contactClient,
        clientId: state => state.clientsModule.clientId,
        emailData: state => state.contactClient.emailData,
        allEmails: state => state.contactClient.allEmails
      })
    },
    methods: {
      ...mapActions({
        updateEmail: 'contactClient/addContactEmail',
        getContact: 'contactClient/getContact'
      }),
      ...mapMutations({
        isEditMode: 'contactClient/changeIsEditMode',
        isAddMode: 'contactClient/changeIsAddMode',
        manageValue: 'contactClient/changeManageValue',
        resetEmailData: 'contactClient/resetEmailData',
        selectedEmailIndex: 'contactClient/setSelectedEmailIndex',
        phoneSection: 'contactClient/setPhoneSection',
        setShowSaveBtn: 'personalInfoClient/setShowSaveBtn'
      }),
      addEmail() {
        this.manageValue(this.contact.manageType.email)
        this.resetEmailData()
        this.isAddMode(true)
        this.phoneSection(false)
        this.setShowSaveBtn(true)
      },
      editEmail(item, index) {
        this.isAddMode(false)
        this.isEditMode(true)
        this.selectedEmailIndex(index)
        this.emailData.email = item.email
        this.emailData.isMain = item.isMain
        this.emailData.forContactInvoice = item.forContactInvoice
        this.manageValue(this.contact.manageType.email)
        this.phoneSection(false)
        this.setShowSaveBtn(false)
      },
      async deleteEmail(index) {
        this.allEmails.map((item, i) => {
          if (i === index) {
            this.allEmails.splice(i, 1)
          }
        })

        await this.updateEmail({emails: this.allEmails, clientId: this.clientId})
        await this.getContact({clientId: this.clientId})
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "src/assets/scss/variables";
  @import "../../simpleTable";

  .P-table-block {
    ul {
      li {
        &:nth-child(2) {
          max-width : 75px;
          min-width : 75px;
        }
        &:nth-child(3) {
          max-width : 130px;
          min-width : 130px;
          text-align: end;
        }
      }
    }
  }

  .P-contact-list-block {
    margin-top : 20px;
  }

  .P-contact-list-box {
    margin-bottom : 12px;
  }
</style>