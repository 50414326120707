var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"P-packages-list-block P-manage-client"},[_c('div',{staticClass:"P-packages-list-header"},[_c('div',{staticClass:"P-package-information"},[_c('ul',{staticClass:"G-align-center"},[(_vm.selectedLocation?.createdAt)?_c('li',[_c('p',{staticClass:"neutral--text"},[_vm._v("Registration Date")]),_c('p',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.selectedLocation.createdAt)+" ")])]):_vm._e(),(
            _vm.selectedLocation.isPauseSubscriptions &&
            _vm.selectedLocation.pauseStartDate &&
            _vm.selectedLocation.subscriptionState === 3
          )?_c('li',[_c('p',{staticClass:"neutral--text"},[_vm._v("In Pause")]),_c('p',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.selectedLocation.pauseStartDate))])]):_vm._e(),(
            _vm.selectedLocation &&
            _vm.selectedLocation.subscriptionState === 2 &&
            _vm.selectedLocation?.subscriptionCancelDate
          )?_c('li',[_c('p',{staticClass:"neutral--text"},[_vm._v("In Cancelation")]),_c('p',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.selectedLocation?.subscriptionCancelDate)+" ")])]):_vm._e(),(_vm.selectedLocation && _vm.selectedLocation.discount)?_c('li',[_c('p',{staticClass:"neutral--text"},[_vm._v("DISCOUNT")]),_c('p',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.selectedLocation.discount)+"%")])]):_vm._e(),(
            _vm.selectedLocation && _vm.selectedLocation.subscriptionState !== null
          )?_c('li',[_c('p',{staticClass:"neutral--text"},[_vm._v("Location Status")]),_c('p',{staticClass:"secondary--text d-flex flex-column",domProps:{"innerHTML":_vm._s(_vm.selectedLocationStatus)}})]):_vm._e()])]),_c('div',{staticClass:"P-action-block G-align-center"},[_c('div',{staticClass:"P-padding-33",class:{ 'P-big-select': _vm.$route.name !== 'Search' }},[_c('ott-select',{attrs:{"value":_vm.roomsCount,"items":_vm.roomsList,"label":"Room","selected-text":"value"},on:{"emitInputChange":_vm.roomChange}})],1),_c('div',{staticClass:"P-padding-33",class:{ 'P-big-select': _vm.$route.name !== 'Search' }},[_c('ott-select',{attrs:{"value":_vm.actionType,"items":_vm.packagesClients.actionsList,"multiple":false,"persistentHint":true,"selected-text":"name","label":"Action","clear":""},on:{"emitInputChange":_vm.actionChange}})],1),(
          _vm.actionType === _vm.packagesClients.actionTypes.subscribeFromEndMaxExpire
        )?_c('div',[_c('v-radio-group',{staticClass:"G-radio-group",attrs:{"value":_vm.dayMonthType},on:{"change":_vm.dayMonthTypeChange}},[_c('div',{staticClass:"P-full-line G-flex"},[_c('div',{staticClass:"P-radio-box P-radio-day"},[_c('OttRadio',{attrs:{"label":_vm.$route.name !== 'Search' ? 'Day' : 'D',"value":"day"}})],1),_c('div',{staticClass:"P-radio-box"},[_c('OttRadio',{attrs:{"label":_vm.$route.name !== 'Search' ? 'Month' : 'M',"value":"month"}})],1)])])],1):_vm._e(),(
          _vm.actionType === _vm.packagesClients.actionTypes.subscribeFromEndMaxExpire
        )?_c('div',{staticClass:"P-padding-20",class:{ 'P-dayMonth-select': _vm.$route.name !== 'Search' }},[_c('ott-select',{attrs:{"value":_vm.dayMonthValue,"label":_vm.dayMonthType === 'day' ? 'Day' : 'Month',"items":_vm.dayMonthType === 'day' ? _vm.daysList : _vm.monthsList,"multiple":false,"persistentHint":true,"selected-text":"value"},on:{"emitInputChange":_vm.dayMonthValueChange}})],1):_vm._e(),(_vm.actionType === _vm.packagesClients.actionTypes.subscribeToDate)?_c('div',{staticClass:"P-padding-33 G-date-picker"},[_c('DatePicker',{staticClass:"ott-date-picker",attrs:{"value":_vm.subscribeToDate,"masks":{ input: _vm.ottDateFormat },"min-date":_vm.$moment(_vm.newDateWithMainUserTZ).add(1, 'days')._d,"popover":{ visibility: 'click' },"is-dark":_vm.$vuetify.theme.dark,"locale":"en"},on:{"input":_vm.subscribeToDateChange},scopedSlots:_vm._u([{key:"default",fn:function({ inputEvents }){return [_c('div',{staticClass:"ott-date-picker-cont"},[_c('v-text-field',_vm._g({attrs:{"value":_vm._f("moment")(_vm.subscribeToDate,_vm.ottDateFormat),"readonly":true,"height":38,"outlined":"","dense":"","label":"Date","append-icon":"mdi mdi-calendar-blank-outline neutral--text"}},inputEvents))],1)]}}],null,false,3035717159)})],1):_vm._e()])]),_c('div',{staticClass:"P-packages-list-body"},[_c('div',{staticClass:"P-label-list"},[(_vm.packagesList.length && _vm.isExistPrice && !_vm.loading)?_c('table',{class:{
          'P-table-for-search': _vm.$route.name === 'Search' && _vm.tabsWidth !== 100,
        }},[_c('tr',[_c('th',{staticClass:"secondary--text"},[_vm._v("Name")]),_c('th',{staticClass:"secondary--text"},[_vm._v("Type")]),(
              _vm.selectedLocation.subscriptionState === 3 &&
              _vm.selectedLocation.subscriptionActivationDate !== null
            )?_c('th',{staticClass:"secondary--text P-width-140"},[_vm._v(" Start Date ")]):_vm._e(),_c('th',{staticClass:"secondary--text",class:{
              'P-width-140': _vm.selectedLocation.subscriptionState === 3,
            }},[_vm._v(" Expire Date - "),_c('span',{staticClass:"P-rooms-count-span"},[_vm._v(_vm._s(_vm.roomsCountOld ? `${_vm.roomsCountOld} Rm` : ""))])]),(
              _vm.paymentList.length &&
              _vm.packagesData.locations[_vm.locationIndexFromPackagesData]
                .packageInfos.length
            )?_c('th',{staticClass:"secondary--text"},[_vm._v(" Recurring Payment ")]):_vm._e(),_c('th',{staticClass:"secondary--text"},[_vm._v("Current Price")]),_c('th',{staticClass:"secondary--text P-width-140"},[_vm._v(" Expire New "+_vm._s(_vm.roomsCountNew ? "-" : "")+" "),_c('span',{staticClass:"P-rooms-count-span"},[_vm._v(_vm._s(_vm.roomsCountNew ? `${_vm.roomsCountNew} Rm` : ""))])]),_vm._m(0)]),_vm._l((_vm.packagesList),function(item,index){return _c('tr',{key:index,staticClass:"P-hovered-tr"},[_c('td',{staticClass:"secondary--text"},[_c('div',{staticClass:"Ott-checkbox-cont G-align-center",class:{
                'ps-3':
                  _vm.$route.name !== 'Search' ||
                  (_vm.$route.name === 'Search' && _vm.tabsWidth === 100),
              }},[_c('v-checkbox',{staticClass:"w-max-content pt-0",attrs:{"disabled":item?.disabled,"value":item.packageId},on:{"change":_vm.packageCheckboxChanged},model:{value:(
                  _vm.packagesData.locations[_vm.locationIndexFromPackagesData]
                    .packageInfos
                ),callback:function ($$v) {_vm.$set(_vm.packagesData.locations[_vm.locationIndexFromPackagesData]
                    , "packageInfos", $$v)},expression:"\n                  packagesData.locations[locationIndexFromPackagesData]\n                    .packageInfos\n                "}}),_c('p',{staticClass:"secondary--text nowrap-text"},[_vm._v(" "+_vm._s(_vm.getPackageName(item.packageName))+" ")])],1)]),_c('td',{staticClass:"secondary--text nowrap-text"},[_vm._v(" "+_vm._s(item.packageType === 1 ? "Base" : "Advanced")+" ")]),(_vm.selectedLocation.subscriptionState === 3)?_c('td',{staticClass:"secondary--text P-width-140"},[(
                _vm.packagesData.locations[
                  _vm.locationIndexFromPackagesData
                ].packageInfos.includes(item.packageId)
              )?_c('span',[(item.startDate !== undefined)?_c('span',[_vm._v(_vm._s(item.startDate))]):_vm._e()]):_vm._e()]):_vm._e(),_c('td',{staticClass:"secondary--text",class:{
              'P-width-140': _vm.selectedLocation.subscriptionState === 3,
            }},[(item.expireDate !== undefined)?_c('span',[_vm._v(_vm._s(item.expireDate))]):_vm._e(),(
                _vm.selectedLocation.subscriptionState === 1 &&
                _vm.packagesData.locations[
                  _vm.locationIndexFromPackagesData
                ].packageInfos.includes(item.packageId)
              )?_c('span',{staticClass:"G-badge G-pending-badge mx-auto"},[_vm._v(" Pending ")]):_vm._e()]),(
              _vm.paymentList.length &&
              _vm.packagesData.locations[_vm.locationIndexFromPackagesData]
                .packageInfos.length
            )?_c('td',{staticClass:"secondary--text"},[(
                _vm.paymentList.length &&
                _vm.packagesData.locations[
                  _vm.locationIndexFromPackagesData
                ].packageInfos.includes(item.packageId) &&
                _vm.paymentForPackagesObj?.id
              )?_c('ott-checkbox',{staticClass:"pt-0 mx-auto w-max-content",attrs:{"disabled":item?.disabled},on:{"updated":function($event){return _vm.recurringPaymentCheck(item.recurringPayment)}},model:{value:(item.recurringPayment),callback:function ($$v) {_vm.$set(item, "recurringPayment", $$v)},expression:"item.recurringPayment"}}):_vm._e()],1):_vm._e(),_c('td',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(item.currentPrice === -1 ? "Not defined" : new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(item.currentPrice))+" ")]),_c('td',[(item.canceled)?_c('span',{staticClass:"text-danger"},[_vm._v(" Canceled ")]):_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(item.expireNew)+" ")])]),_c('td',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(item.totalPrice))+" ")])])})],2):_vm._e()]),(
        _vm.packagesList.length &&
        (_vm.actionType ===
          _vm.packagesClients.actionTypes.subscribeFromEndMaxExpire ||
          _vm.actionType ===
            _vm.packagesClients.actionTypes.subscribeToEndMaxExpire ||
          _vm.actionType === _vm.packagesClients.actionTypes.subscribeToDate)
      )?_c('div',{staticClass:"P-total-price G-justify-end pe-6"},[_c('p',[_vm._v("Total Price")]),_c('p',[_vm._v(" "+_vm._s(_vm.packagesData.locations[_vm.locationIndexFromPackagesData].totalPrice !== 0 ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format( _vm.packagesData.locations[_vm.locationIndexFromPackagesData] .totalPrice ) : "$0.00")+" ")])]):_vm._e()]),((!_vm.packagesList.length && _vm.isExistPrice) || _vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table-heading, table-thead, table-tbody"}}):_vm._e(),(!_vm.isExistPrice)?_c('div',{staticClass:"text-center mt-5 pt-5"},[_c('p',{staticClass:"pt-5"},[_vm._v(" For showing packages data need to add packages price by type of client ")])]):_vm._e(),_c('Transition',{attrs:{"name":"fade"}},[(_vm.showRecurringMessage)?_c('v-alert',{staticClass:"mt-5",staticStyle:{"width":"max-content"},attrs:{"dense":"","text":"","type":"success"}},[_vm._v(" The Recurring Payment Is "+_vm._s(_vm.recurringSelectedText)+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"secondary--text"},[_vm._v(" Total Price "),_c('br'),_vm._v(" (USD) ")])
}]

export { render, staticRenderFns }