<template>
  <div class="P-profile-list">
    <div class="P-label-list">
      <div class="P-table-header">
        <ul class="G-flex ">
          <li class="secondary--text">Avatar</li>
          <li class="secondary--text">Nickname</li>
          <li class="secondary--text">Protect Code</li>
          <li class="secondary--text">Age Group</li>
          <li class="secondary--text">Admin</li>
          <li></li>
        </ul>
      </div>
      <div class="P-table-body"
           v-if="profileClients.selectedLocation && profileClients.selectedLocation.profiles && profileClients.selectedLocation.profiles.length">
        <ul v-for="(items, index) of profileClients.selectedLocation.profiles" :key="index" class="G-flex ">
          <li class="secondary--text">
            <div class="P-profile-avatar"></div>
          </li>
          <li class="secondary--text">{{ items.nickname}}</li>
          <li class="secondary--text">{{ items.protectCode}}</li>
          <li class="secondary--text">{{ items.ageGroup }}</li>
          <li class="secondary--text G-justify-end"><span v-if="items.isAdmin" class="mdi mdi-check checkTable"/>
          </li>
          <li class="G-align-center">
            <div class="P-actions">
              <v-menu left content-class="P-actions-list">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="P-list">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="P-list">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import ProfileListMixin from "./ProfileListMixin";

  export default {
    name: 'ProfileList',
    mixins: [ProfileListMixin]
  }
</script>
<style scoped lang="scss">

  @import "src/assets/scss/variables";

  .P-table-header {
    ul {
      li {
        font-size      : $txt12;
        font-weight    : bold;
        padding        : 8px 16px;
        display        : flex;
        align-items    : center;
        text-transform : capitalize;
      }
    }
  }

  .P-table-body {
    ul {
      li {
        font-size      : $txt14;
        word-break     : break-all;
        padding        : 8px 16px;
        transition     : $transition;
        display        : flex;
        align-items    : center;
        text-transform : capitalize;

        & + li {
          border-left : 1px solid $neutral-color45;
        }
      }

      border-top : 1px solid $neutral-color45;

    }
  }

  .P-label-list {
    overflow      : hidden;
    border-radius : 4px;
    border        : 1px solid $neutral-color45;

    ul {
      padding : 0;

      li {
        max-width : 100%;
        width     : 100%;

        & + li {
          border-left : 1px solid $neutral-color45;
        }
      }
    }

    li {
      &:first-child {
        max-width : 72px;
        min-width : 72px;
      }
      &:nth-child(3)  {
        max-width : 200px;
        min-width : 200px;
      }
     &:nth-child(4)  {
        max-width : 120px;
        min-width : 120px;
      }
      &:nth-child(5)  {
        max-width : 60px;
        min-width : 60px;
      }
      &:last-child {
        max-width   : 40px;
        min-width   : 40px;
        padding     : 15px 0;
        border-left : 1px solid transparent;
      }
    }
  }

  .P-table-body::v-deep {
    .v-input--switch {
      margin-top : 0;
    }

    .v-input__slot {
      margin-bottom : 0;
    }

    .v-messages {
      display : none;
    }
  }

  .P-actions {
    opacity    : 0;
    visibility : hidden;
    transition : $transition;
  }

  .P-profile-avatar {
    width            : 40px;
    height           : 40px;
    background-color : $neutral-color;
    border-radius    : 50%;
  }

  .P-profile-list{
    margin-top: 20px;
  }
  .checkTable {
    color : $green;
  }
</style>
