import { mapActions, mapMutations, mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            equipments: (state) => state.equipments,
            dataForPackages: (state) => state.packagesClients.dataForPackages,
        }),

        clientEquipments() {
            let equipments = []
            if (this.equipments.clientEquipments.length) {
                equipments = this.equipments.clientEquipments.filter(item => item.equipment )
            }
        
            return equipments;
        }
    },

    methods: {
        ...mapActions({
            getLocationPackages: "packagesClients/getLocationPackages",
            getEquipmentsList: 'equipments/getEquipmentsList',
            getEquipmentsTypes: "equipmentsModule/getEquipmentsTypes",
            setCheckoutInvoice: "packagesClients/setCheckoutInvoice",
        }),

        ...mapMutations({
            setData: 'equipments/setData',
            setFormDataKeys: "equipments/setFormDataKeys",
            packagesSetData: "packagesClients/setData",
        }),

        async getPackagesEquipmentsData(returnedData = []) {
            //creating payload for get equipments, packages
            const dataForPackages = { ...this.dataForPackages };

            let equipments = [...this.equipments.clientEquipments];
            equipments = equipments.filter(item => item.id === undefined)
            equipments.map((item) => {
                if(typeof item.equipment === 'object') {
                    item.equipment = item.equipment.id
                }
                delete item.accessToDelete
                delete item.type
                delete item.purchaseDate
                delete item.returnDate
            });
            dataForPackages.equipments = [...equipments];

            if (returnedData.length) {
                dataForPackages.returnEquipments = [...returnedData]
            }

            this.packagesSetData({ dataForPackages });

            await this.getLocationPackages({ ...this.dataForPackages });

        }
    }
};
