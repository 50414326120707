<template>
  <div class="P-table P-equipments-list">
    <TitleComponent 
      :click="addNewEquipment"  
      :class="{ 'mt-4 ms-4': $vuetify.theme.dark }" 
      title-text="Equipmentes"
      is-add
     />
    <div class="P-table-block P-table-block-scroll" :class="scrollBlockClasses">
      <div class="P-table-header">
        <ul class="G-flex">
          <li class="secondary--text" v-for="(header, key) of headers" :key="key">
            {{ header }}
            <span v-if="header === 'Summ'">(USD)</span>
          </li>
          <li></li>
        </ul>
      </div>
      <div v-if="clientEquipments.length" class="P-table-body">
        <template v-if="groupedOldEquipments.length">
          <ul
            v-for="(oldItem, index) of groupedOldEquipments" 
            :key="index+'123'"
            class="G-flex"
          >
            <li>
              <img v-if="oldItem?.equipment?.productImage?.imagesList.length" :src="baseApiUrl + 'files/icon/' + oldItem?.equipment?.productImage?.imagesList[0]" class="equipment-image ps-2" />
              <span v-else>-</span>
            </li>
            <li>{{ getName(oldItem.equipment) }}</li>
            <li>{{ getName(oldItem.equipment.type) }}</li>
            <li>
              {{ oldItem.purchaseDate !== undefined || oldItem.purchaseDate !== 'Invalid date' ? oldItem.purchaseDate : "-" }}
            </li>
            <li>{{ oldItem.returnDate !== undefined ? oldItem.returnDate : "-" }}</li>
            <li>
              {{ oldItem.quantity }}
            </li>
            <li>
              {{ oldItem.quantity }}
            </li>
            <li>{{ !oldItem.newEquipment ? oldItem.price : "-" }}</li>
            <li>{{ oldItem.totalPrice ? oldItem.totalPrice : "-" }}</li>
            <li>
              {{ oldItem.newEquipment ? oldItem.totalPrice : "-" }}
            </li>
            <li class="G-align-center">
              <div class="P-actions G-flex-center">
                <v-menu left content-class="P-actions-list">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template>
                      <v-list-item @click="openReturnModal(oldItem)" class="P-list">
                        <v-list-item-title>Return</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </div>
            </li>
          </ul>
        </template>

        <template v-if="groupedNewEquipments.length">
          <ul
            v-for="(newItem, newItemImdex) of groupedNewEquipments" 
            :key="newItemImdex+'456'"
            class="G-flex"
          >
            <li>
              <img v-if="newItem?.equipment?.productImage?.imagesList.length" :src="baseApiUrl + 'files/icon/' + newItem?.equipment?.productImage?.imagesList[0]" class="equipment-image ps-2" />
              <span v-else>-</span>
            </li>
            <li>{{ getName(newItem.equipment) }}</li>
            <li>{{ getName(newItem.equipment.type) }}</li>
            <li>
              {{ newItem.purchaseDate !== undefined || newItem.purchaseDate !== 'Invalid date' ? newItem.purchaseDate : "-" }}
            </li>
            <li>{{ newItem.returnDate !== undefined ? newItem.returnDate : "-" }}</li>
            <li>
              {{ newItem.quantity }}
            </li>
            <li>
              {{ newItem.piece ? newItem.piece : "-" }}
            </li>
            <li>{{ !newItem.newEquipment ? numberToFinanceNumber(newItem.price) : "-" }}</li>
            <li>{{
              typeof newItem.totalPrice === 'number'
                ? numberToFinanceNumber(newItem.totalPrice)
                : "-" 
            }}</li>
            <li>
              {{ newItem.newEquipment ? numberToFinanceNumber(newItem.totalPrice) : "-" }}
            </li>
            <li class="G-align-center">
              <div class="P-actions G-flex-center">
                <v-menu left content-class="P-actions-list">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template>
                      <v-list-item
                        v-for="(action, actionIndex) of actionsList"
                        :key="actionIndex"
                        @click="
                          action.clickFunc(
                            action.name === 'Edit' ? { data: newItem } : ''
                          )
                        "
                        class="P-list"
                      >
                        <v-list-item-title>{{ action.name }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </div>
            </li>
          </ul>
        </template>
      </div>
    </div>

    <br />
    <p v-if="equipments.equipmentsTotal !== 0" class="text-right pe-4 pb-4">
      Total: {{ numberToFinanceNumber(equipments.equipmentsTotal) }}
    </p>

    <WarningModal
      v-if="opentReturnModal"
      :is-modal-open="opentReturnModal"
      :modal-heading="warningModalHeading"
      :modal-text="warningModalText"
      :is-danger-button="true"
      :modal-width="600"
      :is-loading="warningModalIsLoading"
      btnText="Return"
      @btnFunction="returnFunc"
      @closeModal="closeReturnModal"
      @click:outside="closeReturnModal"
    >
      <div slot="body" class="G-justify-center mt-3 px-4">
        <div class="P-padding-50 px-0">
          <ott-input
            v-model.number="returnedCount"
            :min="0"
            :max="returnedMaxNum"
            type="number"
            label="Returned Count"
          />
        </div>
      </div>
    </WarningModal>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// component
import TitleComponent from "@/components/TitleComponent";
import EqupmentsMixin from "../EquipmentsMixin";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import WarningModal from "@/components/WarningModal";
import OttInput from "@/components/vuetifyComponents/OttInput";

export default {
  name: "EquipmentsList",
  components: { TitleComponent, OttButtonLoader, WarningModal, OttInput },
  mixins: [EqupmentsMixin],
  data() {
    return {
      headers: [
        "Image",
        "Name",
        "Type",
        "Date of Purchase",
        "Date of Return",
        "Qty",
        "Piece",
        "Purchase Price",
        "Total Price",
        "Summ",
      ],
      actionsList: [
        {
          name: "Edit",
          clickFunc: this.editEquipment,
        },
      ],
      opentReturnModal: false,
      returnedCount: null,
      delectedAddressData: {},
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      returnedEquipmentId: null,
      returnedEquipmentPrice: null,
      returnedEquipmentTotalPrice: null,
      returnedEquipmentName: '',
      returnedMaxNum: null
    };
  },
  computed: {
    ...mapState({
      equipmentTypes: (state) => state.equipmentsModule.types,
      equipmentFormData: (state) => state.equipments.formData,
      packagesTotalPrice: (state) => state.packagesClients.packagesTotalPrice,
      isOpenManagePackagesModal: (state) => state.clientsModule.isOpenManagePackagesModal,
      tabsWidth: (state) => state.generalSearch.tabsWidth,
      isLoading: (state) => state.appModule.isLoading
    }),

    scrollBlockClasses() {
      return this.tabsWidth === 100 && this.$route.name === 'Search' ? 'w-max-100' : 'w-835';
    },

    baseApiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    
    warningModalHeading() {
      return `Return client's <ins>${this.returnedEquipmentName}</ins> named equipment(s)?`
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>return</ins></span> client's equipment(s).
              If you're not sure, you can cancel this operation. Please enter count of returned equipment(s)`;
    },

    groupedOldEquipments() {
      const groupedItems = []
      this.clientEquipments.forEach((item) => {
        let isGrouped = false;

        for (let i = 0; i < groupedItems.length; i++) {
          const group = groupedItems[i];

          if (
            group.equipment.id === item.equipment.id && 
            group.price === item.price &&
            group.serialN === item.serialN && 
            group.macAddress === item.macAddress && 
            group.comment === item.comment &&
            ('id' in item)
          ) {
            group.quantity++;
            group.piece += item.piece;
            group.totalPrice += item.price;
            group.ids.push(item.id);
            isGrouped = true;
            break;
          }
        }

        if (!isGrouped) {
          const group = {
            price: item.price,
            totalPrice: item.price,
            quantity: 1,
            piece: item.piece,
            equipment: item.equipment,
            newEquipment: ('id' in item) ? false : true,
            ids: [item.id],
            client: item.client,
            purchaseDate: item.purchaseDate,
            returnDate: item.returnDate,
            serialN: item.serialN,
            macAddress: item.macAddress,
            comment: item.comment,
            hasId: function (id) {
              return this.ids.includes(id);
            },
          };

          if (('id' in item)) {
            groupedItems.push(group);
          }
        }
      });

      return groupedItems
    },

    groupedNewEquipments() {
      const groupedItems = []
      this.clientEquipments.forEach((item) => {
        let isGrouped = false;
        for (let i = 0; i < groupedItems.length; i++) {
          const group = groupedItems[i];

          if (
            group.equipment.id === item.equipment.id && 
            group.price === item.price &&
            group.serialN === item.serialN && 
            group.macAddress === item.macAddress && 
            group.comment === item.comment &&
            !('id' in item)
          ) {
            group.quantity++;
            group.piece += item.piece;
            group.totalPrice += item.price;
            group.ids = [];
            isGrouped = true;
            break;
          }
        }

        if (!isGrouped) {
          const group = {
            price: item.price,
            totalPrice: item.price,
            quantity: 1,
            piece: item.piece,
            equipment: item.equipment,
            newEquipment: ('id' in item) ? false : true,
            ids: [item.id],
            client: item.client,
            purchaseDate: item.purchaseDate,
            returnDate: item.returnDate,
            serialN: item.serialN,
            macAddress: item.macAddress,
            comment: item.comment,
            hasId: function (id) {
              return this.ids.includes(id);
            },
          };

          if (!('id' in item)) {
            groupedItems.push(group);
          }
        }
      });

      return groupedItems
    },
  },
  created() {
    if (
      this.packagesTotalPrice !== 0 &&
      !this.equipments.isAddMode &&
      !this.equipments.isEditMode &&
      !this.equipments.isReturnMode
    ) {
      this.packagesSetData({ showPayBtn: true });
    } else {
      this.packagesSetData({ showPayBtn: false });
    }
  },
  methods: {
    ...mapMutations({
      resetEquipmentForm: "equipments/resetFormData",
      openCheckoutModal: "checkoutClients/openModal",
    }),

    numberToFinanceNumber(number) {
      const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(number);

      if (number === 0) {
        return '$0.00'
      } else {
        return formattedNumber;
      }
    },

    addNewEquipment() {
      this.setData({ isAddMode: true });
      this.resetEquipmentForm();
    },
    async editEquipment({ data }) {
      let selectedEquipmentIndex = null
      const equipmentFormData = {
        equipment: data.equipment.id,
        serialN: data.serialN,
        macAddress: data.macAddress,
        price: data.price,
        piece: data.piece,
        refund: "",
        comment: data.comment,
        description: "",
      };

      this.equipments.clientEquipments.map((item, index) => {
        if (
          item.equipment?.id === data.equipment.id && 
          item.serialN === data.serialN &&
          item.macAddress === data.macAddress &&
          item.price === data.price &&
          item.piece === data.piece && 
          item.comment === data.comment
        ) {
          selectedEquipmentIndex = index
        }
      })

      this.setData({
        isEditMode: true,
        formData: equipmentFormData,
        equipmentFilter: data?.equipment?.type?.id,
        selectedEquipmentIndex 
      });


    },

    getName(nameObj) {
      if (typeof nameObj === 'object') {
        return nameObj.name.map((name) =>
          name.lang === "en" ? name.name : name[0].name
        )[0]  
      } else {
        return '-'
      } 
    },

    openReturnModal(equipment) {
      this.returnedEquipmentId = equipment.equipment.id
      this.returnedEquipmentPrice = equipment.price
      this.returnedEquipmentTotalPrice = equipment.totalPrice
      this.returnedEquipmentName = this.getName(equipment.equipment)
      this.returnedMaxNum = equipment.quantity
      this.opentReturnModal = true
    },

    closeReturnModal() {
      this.opentReturnModal = false
      this.returnedCount = null
      this.returnedMaxNum = null
      this.returnedEquipmentName = ''
      this.returnedEquipmentId  = null
      this.returnedEquipmentPrice = null
      this.returnedEquipmentTotalPrice = null
    },

    async returnFunc() {
      const returnedData = [
        {
          equipment: this.returnedEquipmentId,
          piece: this.returnedCount,
          price: this.returnedEquipmentPrice,
          totalPrice: this.returnedEquipmentTotalPrice
        }
      ]

      this.warningModalIsLoading = true;
      await this.getPackagesEquipmentsData(returnedData).finally(() => {
        this.warningModalIsLoading = false;
        this.closeReturnModal()
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "src/assets/scss/variables";
@import "src/views/clients/modals/manageClient/simpleTable";
@import "src/assets/scss/formStyles";

.P-equipments-list {
  .P-table-body {
    // overflow: hidden;
    border-radius: 4px;
    margin-top: 12px;
    // max-height: 250px;
  }

  .equipment-image {
    width: 100%;
    height: 100px;
    object-fit: contain;
  }

  li:last-child {
    position: sticky;
    right: 0;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #a5aac6;
  margin: 24px 0;
  padding: 24px 24px 0;
}

.P-table-block-scroll::v-deep {
  overflow: auto;
  max-height: 350px;
}

.w-835::v-deep {
  max-width: 835px;
}
.w-max-100 {
  max-width: 100%;
}

.P-table-block-scroll::-webkit-scrollbar {
    height: 7px;
}

.P-actions {
  background-color: white;
  width: 15px;
}
</style>
