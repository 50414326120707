<template>
  <div>
    <div class="P-balance">
        <balance-form
          v-if="balanceManage.isEdit"
          ref="balanceForm"
        />
        <balance v-else />
    </div>

    <div>
      <title-component
        v-if="!credit.isStart && !showCreditComponents" 
        :is-add="true"
        :click="() => showCreditComponents = true" 
        title-text="Add Credit" 
      />

      <div v-if="showCreditComponents">
        <credit-form
          v-if="!credit.isStart || credit.isEdit || credit.isStop"
          @stopCredit="showWarningModal = true"
          ref="creditForm"
        />
        <credit v-else />
      </div>
    </div>

    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false" 
      @btnFunction="stopCredit()"      
      btnText="Stop Credit"
    />
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  // components
  import TitleComponent from "@/components/TitleComponent";
  import WarningModal from "@/components/WarningModal";
  import BalanceForm from "./components/balanceManage/BalanceForm";
  import Balance from "./components/balanceManage/Balance";
  import CreditForm from "./components/credit/CreditForm";
  import Credit from "./components/credit/Credit";

  export default {
    name: "BalanceCreditClient",
    components: { TitleComponent, WarningModal, BalanceForm, Balance, CreditForm, Credit },
    
    data() {
      return {
        showWarningModal: false,
        warningModalIsDangerButton: true,
        warningModalIsLoading: false,
      }
    },

    computed: {
      ...mapState({
        balanceManage: state => state.balanceCreditClient.balanceManage,
        credit: state => state.balanceCreditClient.credit,
        showBalance: state => state.balanceCreditClient.showBalanceComponents,
        showCredit: state => state.balanceCreditClient.showCreditComponents
      }),

      showBalanceComponents: {
        set(value) {
          this.setData({ showBalanceComponents: value })
        },
        get() {
          return this.showBalance
        }
      },

      showCreditComponents: {
        set(value) {
          this.setData({ showCreditComponents: value })
        },
        get() {
          return this.showCredit
        }
      },

      warningModalHeading() {
        return `Stop client's credit?`
      },

      warningModalText() {
        return `You're about to <ins>stop</ins> client's credit. If you're not sure, You can cancel this operation.`
      },
    },

    watch: {
      balanceManage(balance) {
        if (!balance.isSave) {
          this.setData({ showBalanceComponents: false })
        } else {
          this.setData({ showBalanceComponents: true })
        }
      },

      credit(creditObj) {
        if (!creditObj.isStart) {
          this.setData({ showCreditComponents: false })
        } else {
          this.setData({ showCreditComponents: true })
        }
      }
    },

    async created() {
      if (this.$route.name !== 'Search') {
        await this.getBalanceCredit();
      }

      if (!this.balanceManage?.isSave) {
        this.setData({ showBalanceComponents: false })
      } else {
        this.setData({ showBalanceComponents: true })
      }

      if (!this.credit?.isStart) {
        this.setData({ showCreditComponents: false })
      } else {
        this.setData({ showCreditComponents: true })
      }
    },

    methods: {
      ...mapActions({
        getBalanceCredit: 'balanceCreditClient/getBalanceCredit'
      }),
      ...mapMutations({
        setData: 'balanceCreditClient/setData',
        setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      }),
     
      async stopCredit() {
        this.warningModalIsLoading = true;
        await this.$refs.creditForm.stopCredit().finally(() => {
          this.warningModalIsLoading = false;
        });
        this.showWarningModal = false;
        this.setShowSaveBtn(true)

        if (!this.credit?.isStart) {
          this.setData({ showCreditComponents: false })
        } else {
          this.setData({ showCreditComponents: true })
        }

        if (!this.balanceManage?.isSave) {
          this.setData({ showBalanceComponents: false })
        } else {
          this.setData({ showBalanceComponents: true })
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .P-balance {
    margin-bottom : 16px;
  }
</style>
