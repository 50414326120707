<template>
  <div class="P-device-information">
    <div>
      <template v-for="(geoIpItem, geoIpKey, geoIpIndex) of data.geoIpInfo"> 
        <li 
          v-if="geoIpKey !== 'city' && geoIpKey !== 'country' && geoIpKey !== 'location'" :key="geoIpIndex + 'i'"
          :class="{
            'order-5': geoIpKey === 'countryCode',
            'order-7': geoIpKey === 'postalCode',
            'order-9': geoIpKey === 'timezone',
            'order-2': geoIpKey === 'realIp',
            'order-4': geoIpKey === 'network',
            'order-6': geoIpKey === 'domain',
            'order-8': geoIpKey === 'organization',
            'order-10': geoIpKey === 'isp'
          }">
          <p class="neutral--text">{{ geoIpKey.replace(/([a-z0-9])([A-Z])/g, '$1 $2') }}</p>
          <p class="secondary--text"><pre>{{ geoIpItem || "-" }}</pre></p>
        </li>

        <li 
          v-if="geoIpKey === 'city' || geoIpKey === 'country'"  :key="geoIpIndex + 'j'"
          :class="{
            'order-1': geoIpKey === 'city',
            'order-3': geoIpKey === 'country'
          }"
        >
          <p class="neutral--text">{{ geoIpKey.replace(/([a-z0-9])([A-Z])/g, '$1 $2') }}</p>
          <p class="secondary--text"><pre>{{ getName(geoIpItem.names) || "-" }}</pre></p>
        </li>
      </template>
    </div>

    <div>
      <template v-for="(item, key, index) of data">
        <li 
          v-if="key !== 'geoIpInfo' &&  key !== 'lastUpdate' && key !== 'locationId' && key !== '_id'" :key="index"
        >
          <p class="neutral--text">{{ key.replace(/([a-z0-9])([A-Z])/g, '$1 $2') }}</p>
          <p class="secondary--text">{{ item || "-" }}</p>
        </li>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeviceInformation",
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },

  methods: {
    getName(names) {
      if (!Object.keys(names).length) return null;

      if (names.fff !== null) {
        return names.en;
      } else {
        return names[Object.keys(names)[0]];
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.P-device-information {
  margin-top: 12px;

  & > div {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: space-between;

    li {
      width: 48%;
      display: flex;
      align-items: center;
      padding-bottom: 6px;
      border-bottom: 1px dashed;
      padding-top: 6px;

      p {
        width: 50%;
        margin: 0;
        text-transform: capitalize;

        &:first-child {
          font-size: $txt12;
          font-weight: $semiBold;
        }

        &:last-child {
          font-size: $txt14;
          word-break: break-word;
        }
      }
    }
  }
}
</style>