<template>
  <div class="P-manage-equipment P-manage-client">
    <TitleComponent :title-text="getEquipmentTitle" />
    <div class="P-manage-equipment-block G-flex-wrap">
      <div class="P-padding-25">
        <ott-select
          v-model="equipmentFilter"
          :items="equipmentsFilterList"
          @emitInputChange="equipmentFilterChange"
          selected-text="name"
          label="Equipment types"
        />
      </div>

      <div class="P-padding-25">
        <ott-select
          v-model="equipment"
          :items="equipmentsSelectList"
          :multiple="false"
          :persistentHint="true"
          :clear="true"
          :class="{ 'P-error': $v.formData.equipment.$error }"
          @emitInputChange="equipmentSelect"
          @emmitClearData="equipmentClear"
          selected-text="name"
          label="Equipment"
        />
        <!-- :disabled="equipments.isEditMode || equipments.isReturnMode" -->

        <!-- :class="$v.formData.firstname.$error ? 'P-error'  : ''" -->
        <!-- :items="allEquipments" -->
      </div>

      <div class="P-padding-25">
        <ott-input v-model="serialN" label="Serial N" />
      </div>

      <div class="P-padding-25">
        <ott-input v-model="macAddress" label="Mac Address" />
      </div>

      <div class="P-padding-25">
        <ott-select
          v-model="piece"
          :items="piecesPrices"
          :multiple="false"
          :persistentHint="true"
          :clear="true"
          :class="{ 'P-error': $v.formData.piece.$error }"
          @emitInputChange="pieceSelect"
          @emmitClearData="pieceClear"
          selected-text="name"
          label="Pc / Price"
        />
      </div>

      <div class="P-padding-25">
        <!-- v-model="price" -->
        <!-- :class="{ 'P-error': $v.formData.price.$error }" -->
        <ott-input
          :value="price"
          :disabled="true"
          :read-only="true"
          label="Total Price"
          type="number"
        />
      </div>

      <div class="P-padding-100">
        <quill-editor 
          v-model="comment" :options="{ placeholder: 'Comment' }" 
          ref="myQuillEditor"
          :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
        ></quill-editor>
      </div>

      <div v-if="description" class="P-padding-100">
        <fieldset>
          <legend>Description</legend>
          <p class="mb-0 description" :class="$vuetify.theme.dark ? 'dark' : 'light'" v-html="description" /> 
        </fieldset>
      </div>

      <div class="P-padding-50">
        <div class="P-save-btn-block" v-if="!equipments.clientEquipments.length">
          <ott-button-loader
            :is-loading="isLoading"
            @onClick="save"
            button-text="Save"
            class-name="primary-sm-btn"
          />
        </div>

        <SaveResetButtons
          v-if="
            (equipments.isAddMode && equipments.clientEquipments.length) ||
            (equipments.isEditMode && equipments.clientEquipments.length)
          "
          left-label="Cancel"
          :right-label="equipments.isEditMode ? 'Save' : 'Add'"
          :left-click-func="cancel"
          :right-click-func="equipments.isEditMode ? edit : save"
          :is-loading="isLoading"
        />
      </div>
    </div>
  </div>
</template>
<script>
// components
import { mapMutations, mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import _ from "lodash";
import TitleComponent from "@/components/TitleComponent";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import SaveResetButtons from "@/components/SaveResetButtons";
import EqupmentsMixin from "../EquipmentsMixin";

export default {
  name: "ManageEquipment",
  mixins: [EqupmentsMixin],
  components: {
    TitleComponent,
    OttSelect,
    OttInput,
    OttTextarea,
    OttButtonLoader,
    SaveResetButtons
  },

  validations: {
    formData: {
      equipment: { required },
      // price: { required },
      piece: { required },
    },
  },

  data() {
    return {
      piecesPrices: [],
      salePrices: []
    }
  },

  computed: {
    ...mapState({
      equipmentsTypes: (state) => state.equipmentsModule.types,
      selectedEquipmentIndex: (state) => state.equipments.selectedEquipmentIndex,
      isLoading: (state) => state.appModule.isLoading,
    }),

    equipmentsFilterList() {
      let filtersList = [
        {
          name: "All types",
          value: "all",
        },
      ];

      if (
        this.equipments.equipmentsForSelect.length &&
        this.equipmentsTypes.length
      ) {
        filtersList = [...filtersList, ...this.equipmentsTypes];
      }

      return filtersList;
    },

    equipmentsSelectList() {
      let list = [];

      if (this.equipments.equipmentsForSelect.length) {
        if (this.equipmentFilter === "all") {
          list = [...this.equipments.equipmentsForSelect];
        } else {
          list = this.equipments.equipmentsForSelect.filter(
            (item) => item.type === this.equipmentFilter
          );
        }
      }

      return list;
    },

    formData() {
      return this.equipments.formData;
    },

    calculatedTotalPrice() {
      let price = 0

      if (this.salePrices.length) {
        price = this.salePrices.reduce((acc, curr) => {
          return curr.piece <= this.piece ? acc + curr.price : acc;
        }, 0);
      }

      return price
    },

    equipmentFilter: {
      get() {
        return this.equipments.equipmentFilter;
      },
      set(equipmentFilter) {
        this.setData({ equipmentFilter });
      },
    },

    equipment: {
      get() {
        return this.formData.equipment;
      },

      set(equipment) {
        this.setFormDataKeys({ equipment });
      },
    },

    serialN: {
      get() {
        return this.formData.serialN;
      },

      set(serialN) {
        this.setFormDataKeys({ serialN });
      },
    },

    macAddress: {
      get() {
        return this.formData.macAddress;
      },

      set(macAddress) {
        this.setFormDataKeys({ macAddress });
      },
    },

    price: {
      get() {
        return this.formData.price;
      },

      set(price) {
        this.setFormDataKeys({ price });
      },
    },

    piece: {
      get() {
        return this.formData.piece;
      },

      set(piece) {
        this.setFormDataKeys({ piece });
      },
    },

    comment: {
      get() {
        return this.formData.comment;
      },

      set(comment) {
        this.setFormDataKeys({ comment });
      },
    },

    description: {
      get() {
        return this.formData.description;
      },

      set(description) {
        this.setFormDataKeys({ description });
      },
    },

    getEquipmentTitle() {
      let title = "Add Equipment";
      if (this.equipments.isEditMode) {
        title = "Edit Equipment";
      }

      return title;
    },
  },
  watch: {
    equipmentsSelectList(newValue, oldValue) {
      this.setFormDataKeys({ equipment: null });
      if(this.equipment === null) this.equipmentClear()
    },
  },

  async created() {
    this.packagesSetData({ showPayBtn: false });
    this.equipmentAutoSelect(this.equipment);
  },

  methods: {
    ...mapMutations({
      resetFormData: "equipments/resetFormData",
    }),

    equipmentSelect(equipmentId) {
      //after select equipment, autoselected price, description
      this.equipmentAutoSelect(equipmentId);
    },

    equipmentFilterChange() {
      this.piece = null
    },

    equipmentAutoSelect(equipmentId) {
      this.equipments.allEquipments.map((item) => {
        if (item._id === equipmentId) {
          // if is add equipment then change values
          if (!this.equipments.isEditMode) {
            this.price = '';
            this.piece = null
          }

          // get pieces/prices list
          let prices = [];
          if (('prices' in item)) {
            prices = item.prices.filter(price => price.md === 'sale');
          }

          this.piecesPrices = []
          if (prices.length) {
            this.salePrices = [...prices[0].pieces]

            this.salePrices.map(piece => {
              this.piecesPrices.push({
                name: piece.piece + 'Pc / $' + piece.price,
                value: piece.piece
              })
            })

            // if is add equipment then change values
            if (!this.equipments.isEditMode) {
              this.piece = this.salePrices[0].piece;
              this.price = this.calculatedTotalPrice;
            }
          }

          this.description = item.description;
        }
      });
    },

    pieceSelect() {
      this.price = this.calculatedTotalPrice
    },

    equipmentClear() {
      this.description = null;
      this.price = null;
      this.piece = null;
    },

    pieceClear() {
      this.piece = null;
      this.price = null;
    },

    cancel() {
      this.setData({
        isAddMode: false,
        isEditMode: false,
        equipmentFilter: null,
      });

      this.resetFormData();
    },

    async save() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$error) {
        const clientEquipments = [...this.equipments.clientEquipments];

        clientEquipments.push({
          type: this.equipmentFilter,
          equipment: this.equipment,
          serialN: this.serialN,
          macAddress: this.macAddress,
          price: this.price,
          piece: this.piece,
          comment: this.comment,
          accessToDelete: true,
        });

        this.setData({
          clientEquipments,
        });

        this.cancel();

        await this.getPackagesEquipmentsData();
      }
    },

    async edit() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$error) {
        const clientEquipments  = _.clone(this.equipments.clientEquipments)
        const selectedEquipment = this.equipments.clientEquipments[this.selectedEquipmentIndex]
       
        selectedEquipment.type = this.equipmentFilter;
        selectedEquipment.equipment = this.equipment;
        selectedEquipment.serialN = this.serialN;
        selectedEquipment.macAddress = this.macAddress;
        selectedEquipment.price = this.price;
        selectedEquipment.piece = this.piece;
        selectedEquipment.comment = this.comment;
        selectedEquipment.accessToDelete = true;

        this.setData({
          clientEquipments,
        });

        this.cancel();

        await this.getPackagesEquipmentsData();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/formStyles";

.P-manage-equipment-block {
  margin: 0 -10px;
}

.P-manage-equipment-block::v-deep {
  .v-input--switch {
    margin: 0;
  }
}

.P-checkbox {
  padding: 8px 8px 0;

  .Ott-checkbox-cont + .Ott-checkbox-cont {
    margin-left: 20px;
  }
}

.P-read-only-text {
  color: $neutral-color;
  font-size: $txt10;
  margin-left: 18px;
  margin-bottom: -6px;
}

.P-error::v-deep {
  .v-input--is-disabled,
  fieldset,
  .v-label {
    border-width: 1.5px;
    color: $danger-color-dark !important;
    caret-color: $danger-color-dark !important;
  }
}

fieldset {
  border-radius: 5px;
  border: 1px solid;
  color: $neutral-colorDM;
  font-size: 0.875rem;
  padding-left: 14px;
  transition: all 0.3s;
  word-break: break-word;
}

.description.dark {
  color: $white !important;
}

.description.light {
  color: black !important;
}
</style>
