<template>
  <div class="P-devices-list">
    <div v-if="!usedDevices.isLoading">

      <v-expansion-panels
        accordion
        v-model="selectedDeviceIndex"
        class="G-accordion G-scroll-list"
      >
        <template v-for="(item, index) of usedDevices">
          <v-expansion-panel
            @click="handleDeviceClick(item)"
            :key="index"
          >
            <v-expansion-panel-header :disable-icon-rotate="true">
              <div
                class="
                  G-accordion-header G-flex G-align-center
                  P-devices-header
                  justify-space-between
                "
              >
                <ul class="G-justify-between">
                  <li class="secondary--text">{{ item.information?.type || '-' }}</li>
                  <li class="secondary--text">{{ item.information?.modelCode || '-'}}</li>
                  <li class="secondary--text">
                    {{ item.information.lastActiveTime }}
                  </li>
                  <li class="secondary--text">{{ item.information?.ipAddress || '-' }}</li>
                  <li class="secondary--text">{{ item.information?.geoIpInfo?.isp || '-' }}</li>

                  <!-- <li v-if="item.information?.syncState !== null">
                    <span
                      class="mdi mdi-check"
                      :style="{ color: getSyncColor(item.information?.syncState) }"
                    />
                  </li> -->
                </ul>

                <Transition name="bounce">
                  <custom-badge
                    v-if="savedDevice.index === index && savedDevice.saved"
                    text="Saved"
                    classes="G-badge G-approve-badge px-0 d-flex justify-center align-center py-1 me-7"
                  />
                </Transition>
              </div>
              <template v-slot:actions>
                <span class="mdi mdi-plus neutral--text P-accordion-plus" />
                <span class="mdi mdi-minus neutral--text P-accordion-minus" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="selectedDeviceIndex !== undefined">
              <v-tabs v-model="activeTab" class="mb-5" align-with-title>
                <v-tab @click="activeTab = 0">Settings</v-tab>
                <v-tab @click="activeTab = 1">Informations</v-tab>
                <v-tab @click="activeTab = 2">Statistics</v-tab>
              </v-tabs>
              <transition name="fade">
                <settings v-if="activeTab === 0" />
              </transition>

              <transition name="fade">
                <information
                  v-if="activeTab === 1"
                  :data="item.information"
                />
              </transition>

              <transition name="fade">
                <statistics
                  v-if="activeTab === 2"
                  :chartData="item?.statistics ? item.statistics : null"
                />
              </transition>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Settings from "./Settings";
import Information from "./Information";
import Statistics from "./Statistics";
import CustomBadge from "@/components/CustomBadge";
import HubMixin from "@/mixins/Hub.mixin.js";

export default {
  name: "DevicesList",
  components: {
    CustomBadge,
    Settings,
    Information,
    Statistics,
  },
  mixins: [HubMixin],
  data() {
    return {
      activeTab: 0,
      intervalObject: null,
    };
  },
  watch: {
    hubState() {
      if (this.syncClientId && this.hubState === 5) {
        this.subscribeHubGroup(null, `client-useddevice-info-${this.syncClientId}`, this.handleClientSocket);
        this.initiateRequestInterval();
      }
    },
    selectedDeviceIndex(val) {
      if (typeof val === 'undefined') {
        this.cleanIntervalObject();
      } else {
        this.initiateRequestInterval();
      }
      console.log(val)
      this.activeTab = 0
    } 
  },

  computed: {
    ...mapState({
      usedDevicesClient: (state) => state.usedDevicesClient,
      hubState: (state) => state.appModule.hubState,
      usedDevices: (state) => state.usedDevicesClient.usedDevices,
      savedDevice: (state) => state.usedDevicesClient.savedDevice,
    }),

    selectedDeviceIndex: {
      set(value) {
        this.setData({
          selectedDeviceIndex: value,
        });
      },
      get() {
        return this.usedDevicesClient.selectedDeviceIndex;
      },
    },
  },
  mounted() {
    if (this.$route.name === 'Search') {
        this.subscribeHubGroup(null, `client-useddevice-info-${this.syncClientId}`, this.handleClientSocket); 
        this.initiateRequestInterval();
      }
  },
  created() {
      if (this.$route.name === 'Search') {
        this.syncClientId = this.$route.params['id'];
      }
  },
  beforeDestroy() {
    if (this.$route.name === 'Search') {
        this.syncClientId = this.$route.params['id'];
        this.subscribeHubGroup(`client-useddevice-info-${this.syncClientId}`, null, this.handleClientSocket); 
        this.initiateRequestInterval();
      }
      this.cleanIntervalObject();
  },
  destroyed() {
    this.activeTab = 0
    this.selectedDeviceIndex = 0
  },

  methods: {
    initiateRequestInterval() {
      this.cleanIntervalObject();
      if (typeof this.selectedDeviceIndex !== 'undefined') {
        const self = this;
      this.intervalObject = setInterval(async ()=>{
      console.log(`used device interval request...`);
      await self.getUsedDevices({ syncCdn: false});
      }, 5000)
      }
    },
    cleanIntervalObject() {
      if (this.intervalObject) {
        console.log(`cleaning interval`);
        clearInterval(this.intervalObject);
      }
    },
    async handleClientSocket(data) {
      await this.getUsedDevices({ syncCdn: false});
    },
    ...mapMutations({
      setData: "usedDevicesClient/setData",
      resetFromToDate: "usedDevicesClient/resetFromToDate"
    }),
    ...mapActions({
      syncUsedDeviceCdn: "usedDevicesClient/syncCdn",
      getUsedDevices: "usedDevicesClient/getUsedDevices",
    }),
    onUsedDeviceClick() {
      this.resetFromToDate()
    },
    handleDeviceClick(usedDevice) {
        this.syncUsedDeviceCdn({ usedDeviceId: usedDevice.information?._id.toString()}).then(data=>{
          console.log(data)
                }, err => {
          console.log(err)
        })
      console.log(`used device clicked:`, usedDevice)
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.P-devices-list {
  margin-top: 25px;
}

.P-devices-header {
  ul {
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      text-align: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      font-size: 13px;
      font-weight: 600;
      padding-top: 5px;
      padding-bottom: 5px;
      line-height: 20px;
      word-break: break-word;
      &:nth-child(1) {
        padding-right: 20px;
      }

      & + li {
        padding-left: 15px;
        padding-right: 15px;
        border-left: 1px solid $neutral-color45;
      }
    }
  }
}
.P-devices-list::v-deep {
  .v-expansion-panel {
    margin-bottom: 22px;
    margin-top: 0;
    &-content__wrap {
      padding: 12px 24px 24px;
    }
  }
}

.v-tab {
  font-size: 13px;
}
</style>

<style lang="scss">
.v-slide-group__content {
  justify-content: center !important;
}
</style>
