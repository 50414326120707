<template>
  <div class="P-email-content P-manage-client d-flex flex-wrap">
    <div 
      :class="
        $route.name !== 'Search'? 'P-padding-50' :
        $route.name === 'Search' && tabsWidth !== 100 ? 'P-padding-50' : 'P-padding-40'
      "
    >
      <ott-input
        v-model="email"
        :error="$v.emailData.email.$error || isDuplicatedEmail"
        label="Email Address*"
      />
      <div class="P-checkbox G-align-center">
        <ott-checkbox label="Is Main" v-model="isMain"/>
        <ott-checkbox label="Contact / Invoices" v-model="forContactInvoice"/>
      </div>
      <ErrorMessageContainer
        v-if="isDuplicatedEmail"
        :message="errorMessage"
      />
      <div>
        <SaveResetButtons
          v-if="!showDuplicatedClients"
          :right-label="contact.isEditMode ? 'Save' : 'Add'"
          :left-click-func="resetData"
          :right-click-func="contact.isEditMode ? editData : saveData"
          :is-loading="isLoading"
          left-label="Cancel"
        />
        <SaveResetButtons
          v-else
          :right-label="getSaveBtnName"
          :left-click-func="resetData"
          :right-click-func="contact.isEditMode ? editDataWithDuplicate : saveDataWithDuplicate"
          :is-loading="isLoading"
          left-label="Cancel"
        />
      </div>
    </div>

    <transition name="fade">
      <div 
        v-if="showDuplicatedClients" 
        :class="
          $route.name !== 'Search' ? 'P-padding-100' :
          $route.name === 'Search' && tabsWidth === 100 ? 'P-padding-60' : 'P-padding-100'
        "
      >
        <v-card>
          <v-toolbar
            color="pink"
            dark
          >
            <v-toolbar-title class="text-center text-17 mx-auto">This email is used by other clients <br /> 
              If you want to continue and save please click continue </v-toolbar-title>
            </v-toolbar>

          <v-list two-line>
            <v-list-item-group
              multiple
            >
              <template v-for="(item, index) in duplicateClientsData">
                <v-list-item :key="item.title">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title class="text-15" v-html="item.clientInfo"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < duplicateClientsData.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
    </transition>
  </div>
</template>
<script>
  import { required, email } from "vuelidate/lib/validators";
  import { mapActions, mapMutations, mapState } from "vuex";
  import EmailPhoneMixin from "./EmailPhoneMixin";
  
  // components
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer"
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
  import SaveResetButtons from "@/components/SaveResetButtons";

  export default {
    name: 'Email',
    components: { SaveResetButtons, OttButtonLoader, ErrorMessageContainer, OttCheckbox, OttInput },
    mixins: [ EmailPhoneMixin ],
    validations: {
      emailData: {
        email: { required, email },
      }
    },
    data() {
      return {
        isDuplicatedEmail: false,
        errorMessage: '',
        initialEmail: ''
      }
    },
    computed: {
      ...mapState({
        contact: state => state.contactClient,
        emailData: state => state.contactClient.emailData,
        allEmails: state => state.contactClient.allEmails,
        clientId: state => state.clientsModule.clientId,
        showSaveBtn: state => state.personalInfoClient.showSaveBtn,
        isAddClient: state => state.clientsModule.isAddClient,
        isLoading: state => state.appModule.isLoading,
        isGeneralSearch: state => state.generalSearch.isGeneralSearch
      }),
      email: {
        get() {
          return this.contact.emailData.email
        },
        set(value) {
          this.setEmail(value)
        }
      },
      isMain: {
        get() {
          return this.contact.emailData.isMain
        },
        set(value) {
          this.setIsMain(value)
        }
      },
      forContactInvoice: {
        get() {
          return this.contact.emailData.forContactInvoice
        },
        set(value) {
          this.setForContactInvoice(value)
        }
      }
    },
    created() {
      this.setShowSaveBtn(false)
    },
    mounted() {
      this.initialEmail = this.contact.emailData.email;
    },
    destroyed() {
      this.setShowSaveBtn(true)
    },
    methods: {
      ...mapActions({
        addEmail: 'contactClient/addContactEmail',
        getContact: 'contactClient/getContact',
        checkDuplicatedEmailPhone: 'contactClient/checkDuplicatedEmailPhone',
        getClientList: 'clientsModule/getClientList'
      }),
      ...mapMutations({
        setData: "contactClient/setData",
        isEditMode: 'contactClient/changeIsEditMode',
        isAddMode: 'contactClient/changeIsAddMode',
        phoneSection: 'contactClient/setPhoneSection',
        manageValue: 'contactClient/changeManageValue',
        resetEmailData: 'contactClient/resetEmailData',
        setIsEmailLoading: 'contactClient/setIsEmailLoading',
        checkIsMainEmail: 'contactClient/checkIsMainEmail',
        setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
        setEmail: 'contactClient/setEmail',
        setIsMain: 'contactClient/setIsMain',
        setForContactInvoice: 'contactClient/setForContactInvoice',
      }),
      validate() {
        this.$v.emailData.$touch()
        return !this.$v.emailData.$error && !this.isDuplicatedEmail
      },
      async saveData() {
        const email = {
          email: this.emailData.email,
          isMain: !this.allEmails.length ? true : this.emailData.isMain,
          forContactInvoice: this.emailData.forContactInvoice,
        }

        await this.checkIsDuplicate();
        if (this.validate() && !this.isDuplicatedEmail && !this.showDuplicatedClients) {
          this.checkIsMainEmail(this.emailData)
          this.setIsEmailLoading(true)
          this.allEmails.push(email)

          await this.addEmail({emails: this.allEmails, clientId: this.clientId})
          await this.getContact({clientId: this.clientId})
          this.resetData()
          return Promise.resolve(true)
        } else {
          return Promise.resolve(false)
        }
      },
      async saveDataWithDuplicate() {
        const email = {
          email: this.emailData.email,
          isMain: !this.allEmails.length ? true : this.emailData.isMain,
          forContactInvoice: this.emailData.forContactInvoice,
        }

        await this.checkIsDuplicate();

        if (this.validate() && !this.isDuplicatedEmail) {
          this.checkIsMainEmail(this.emailData)
          this.setIsEmailLoading(true)
          this.allEmails.push(email)

          await this.addEmail({emails: this.allEmails, clientId: this.clientId})
          await this.getContact({clientId: this.clientId})
          this.resetData()
          return Promise.resolve(true)
        } else {
          return Promise.resolve(false)
        }
      },
      async editData() {
        if (this.email !== this.initialEmail) await this.checkIsDuplicate()

        if (this.validate() && !this.isDuplicatedEmail && !this.showDuplicatedClients) {
          this.checkIsMainEmail(this.emailData)
          this.setIsEmailLoading(true)
          const emails = [...this.allEmails];
            emails.map((item, index) => {
                if (index === this.contact.selectedEmailIndex) {
                  item.email = this.emailData.email
                  item.isMain = this.emailData.isMain
                  item.forContactInvoice = this.emailData.forContactInvoice

                  delete item.updatedAt;
                  delete item.createdAt;
                  delete item.id;
                }
            })
            await this.addEmail({emails, clientId: this.clientId})
            await this.getContact({clientId: this.clientId})
            this.resetData()
        }
      },
      async editDataWithDuplicate() {
        if (this.email !== this.initialEmail) await this.checkIsDuplicate()

        if (this.validate() && !this.isDuplicatedEmail) {
          this.checkIsMainEmail(this.emailData)
          this.setIsEmailLoading(true)
          const emails = [...this.allEmails];
            emails.map((item, index) => {
                if (index === this.contact.selectedEmailIndex) {
                  item.email = this.emailData.email
                  item.isMain = this.emailData.isMain
                  item.forContactInvoice = this.emailData.forContactInvoice

                  delete item.updatedAt;
                  delete item.id;
                }
            })
            await this.addEmail({emails, clientId: this.clientId})
            await this.getContact({clientId: this.clientId})
            this.resetData()
        }
      },
      async checkIsDuplicate() {
        if (this.allEmails.some(item => item.email === this.email)) {
          this.isDuplicatedEmail = true
          this.errorMessage = "Duplicate Email address"
        } else {
          this.isDuplicatedEmail = false
        }

        await this.checkDuplicatedEmailPhone({ email: this.email, client: this.clientId }).then((res) => {
          /**
           Is checking if the phone number is already used by other clients, if yes, 
            creating new content to show all the clients' important information
          **/
         
          if (res?.isDublicated && !this.isDuplicatedEmail) {
            this.showDuplicatedClients = true
            this.duplicateClientsData = []
        
            res.duplicateClients.map(item => { 
              this.duplicateClientsData.push({
                id: item.id,
                clientInfo: `<p class="G-align-center flex-wrap mb-0"><b>${item.personalInfo.firstname} ${item.personalInfo.lastname}</b><i>${ this.createAddress(item.addresses[0]) ? ', ' + this.createAddress(item.addresses[0]) : ' ' }${ item.phones.length ? ', ' + item.phones[0].phone : ', ' }${ item.emails.length ? ', <ins>' + this.email  + '</ins>, ' : ', ' } </i>
                &nbsp; ${ this.getClientStatus(item) }</p>`
              })
              // clientInfo: `<b>${item.personalInfo.firstname} ${item.personalInfo.lastname} (${item.provider ? item.provider.name[0].name : 'No Provider Name'})</b><i>${ this.createAddress(item.addresses[0]) ? ', ' + this.createAddress(item.addresses[0]) : ' ' }${ item.phones.length ? ', ' + item.phones[0].phone + ', ' : ', ' }</i>
              // &nbsp; ${ this.getClientStatus(item) }`
            })
                            
          } else {
            this.showDuplicatedClients = false
          }

        })
      },
      resetData() {
        this.showDuplicatedClients = false
        this.duplicateClientsData = []
        this.resetEmailData()
        this.phoneSection(true)
        this.manageValue()
        this.isEditMode(false)
        this.isAddMode(false)
        this.setShowSaveBtn(true)

        if (!this.allEmails.length) {
          this.setData({ showEmailComponents: false })
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/formStyles";

  .P-email-content {
    margin : 0 -10px;
  }

  .P-checkbox {

    margin-top: 10px;
    margin-left: -2px;

    .Ott-checkbox-cont + .Ott-checkbox-cont {
      margin-left : 20px;
    }
  }

  ::v-deep {
    .error-massage-cont {
      margin: 10px 0 0;
    }

    .v-list-item__content {
      padding: 8px 0;
    }

    .v-list--two-line .v-list-item, .v-list-item--two-line {
      min-height: unset;
    }

    .v-list {
      padding: 0;
    }

    .v-list-item {
      padding: 0 12px;
    }
  }

  .text-17 {
    font-size: 17px;
  }

  .text-15 {
    font-size: 15px;
  }

</style>