<template>
    <div class="P-address pa-2 G-flex-center flex-column">
        <b class="mb-4 black--text">{{ title }}</b>
        <p class="text-center black--text" v-html="addressText" />
        <ott-button-loader
            v-if="showButton"
            :button-text="buttonText"
            :className="`text-capitalize ${ buttonClass }`"
            @onClick="$emit('buttonClick')"
            :is-loading="isLoading"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
    name: 'TheAddress',
    components: {
        OttButtonLoader
    },
    props: {
        addressText: {
            type: String,
            default: ''
        },
        title: {
            tyepe: String,
            default: ''
        },
        showButton: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: 'green-sm-btn'
        },
        buttonText: {
            type: String,
            default: 'Change'
        }
    },
    computed: {
        ...mapState({
            isLoading: state => state.appModule.isLoading,
        })
    }
}
</script>

<style lang="scss">
    @import "src/assets/scss/variables";

    .P-address {
        width: 100%;
        border-radius: 5px;
        background-color: white;
        font-size: $txt15;
    }
</style>