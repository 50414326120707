import { mapState } from "vuex";

export default {
    data() {
        return {
            showDuplicatedClients: false,
            duplicateClientsData: [],
        }
    },

    computed: {
        ...mapState({
            tabsWidth: state => state.generalSearch.tabsWidth,
        }),
        
        getSaveBtnName() {
            if (this.contact.isEditMode) {
              return this.showDuplicatedClients ? 'Continue' : 'Save'
            } else {
              return this.showDuplicatedClients ? 'Continue' : 'Add'
            }
        },
    },

    methods: {
      // client address showing logic
      createAddress(address) {
        let addressText = '';
        if (address) {
          if (address.address) {
            addressText = addressText + address.address
          }
          if (address.suite) {
            addressText = addressText + ' ' + address.suite
          }
          if (address.city) {
            addressText = addressText + ' ' + address.city
          }
          if (address.province) {
            addressText = addressText + ' ' + address.province
          }
          if (address.zip) {
            addressText = addressText + ' ' + address.zip
          }
          if (address.country && address.country !== 'US') {
            addressText = addressText + ' ' + address.country
          }
        }

        return addressText
      },

      // client status showing logic
      getClientStatus(data) {
        /*
          client status by subscriptionState value
          0 - Inactive
          1 - Pending
          2 - Canceled
          3 - Active
        */
        
        if (data.subscriptionState === 0) {
          return `<span class="G-badge G-advanced-badge">Inactive</span>`
        }
      
        if (data.subscriptionState === 1) {
          return `<span class="G-badge G-pending-badge">Pending</span>`
        }
      
        if (data.subscriptionState === 2) {
          return `<span class="G-badge G-disabled-badge">Canceled</span>`
        }
      
        if (data.subscriptionState === 3) {
          if (data.inBlock === 0 && data.inPause === 0) {
            return `<span class="G-badge G-approve-badge">Active</span>`
          }
    
          if (data.inBlock === 0 && data.inPause === 1) {
            return `<span class="G-badge G-paused-badge">Paused</span>`
          }
    
          if (data.inBlock === 0 && data.inPause === 2) {
            return `<span class="G-badge G-paused-badge">Semipaused</span>`
          }
    
          if (data.inBlock === 1 && data.inPause === 0) {
            return `<span class="G-badge G-reject-badge">Blocked</span>`
          }
    
          if (data.inBlock === 2 && data.inPause === 0) {
            return `<span class="G-badge G-reject-badge">Semiblocked</span>`
          }
    
          if (data.inBlock === 1 && data.inPause === 1) {
            return `<span class="G-badge G-reject-badge">Blocked</span> <br>
              <span class="G-badge G-paused-badge">Paused</span>`
          }
    
          if (data.inBlock === 1 && data.inPause === 2) {
            return `<span class="G-badge G-reject-badge">Blocked</span> <br>
                <span class="G-badge G-paused-badge">Semipaused</span>`
          }
    
          if (data.inBlock === 2 && data.inPause === 1) {
            return `<span class="G-badge G-reject-badge">Semiblocked</span> <br>
              <span class="G-badge G-paused-badge">Paused</span>`
          }
    
          if (data.inBlock === 2 && data.inPause === 2) {
            return `<span class="G-badge G-reject-badge">Semiblocked</span> <br>
              <span class="G-badge G-paused-badge">Semipaused</span>`
          }
        }
    
        if (data.inBlock === undefined || data.inPause === undefined || (data.inBlock === 0 && data.inPause === 0)) {
          return ''
        }
      },
    }
}
