<template>
  <div class="P-device-settings">
    <div class="P-manage-device P-manage-client G-flex G-flex-wrap">
      <div class="P-padding-33">
        <ott-select
          v-model="timeShift"
          @emitFunc="saveDeviceData"
          :items="usedDevicesClient.timeShiftList"
          :multiple="false"
          :persistentHint="true"
          label="Timeshift"
          selected-text="name"
        />
      </div>
      <div class="P-padding-33">
        <ott-select
          v-model="language"
          @emitFunc="saveDeviceData"
          :items="usedDevicesClient.languageList"
          :multiple="false"
          :persistentHint="true"
          selected-text="name"
          label="Language"
        />
      </div>
      <div class="P-padding-33">
        <ott-select
          v-model="remoteControl"
          @emitFunc="saveDeviceData"
          :items="usedDevicesClient.remoteControlList"
          :multiple="false"
          :persistentHint="true"
          selected-text="name"
          label="Remote Control"
        />
      </div>
      <div class="P-padding-33">
        <ott-select
          v-model="audioTrackDefault"
          @emitFunc="saveDeviceData"
          :items="usedDevicesClient.audioTrackDefaultList"
          :multiple="false"
          :persistentHint="true"
          label="Audiotrack Default"
          selected-text="name"
        />
      </div>
      <div class="P-padding-33">
        <ott-select
          v-model="httpCaching"
          @emitFunc="saveDeviceData"
          :items="usedDevicesClient.httpCachingList"
          :multiple="false"
          :persistentHint="true"
          selected-text="name"
          label="Http Caching"
        />
      </div>
      <div class="P-padding-33">
        <ott-select
          v-model="streamQuality"
          @emitFunc="saveDeviceData"
          :items="usedDevicesClient.streamQualityList"
          :multiple="false"
          :persistentHint="true"
          label="Stream Quality"
          selected-text="name"
        />
      </div>

      <div class="P-padding-33">
        <ott-select
          v-model="uiFontSize"
          @emitFunc="saveDeviceData"
          :items="usedDevicesClient.fontSizes"
          :multiple="false"
          :persistentHint="true"
          label="UI Font size"
          selected-text="name"
        />
      </div>
      <div class="P-checkbox G-align-center">
        <ott-checkbox
          v-model="isBackgroundPlayer"
          @changeCheckbox="saveDeviceData"
          label="Background Player"
        />
        <ott-checkbox
          v-model="isSD"
          @changeCheckbox="saveDeviceData"
          label="SD"
        />
        <ott-checkbox
          v-model="isHD"
          @changeCheckbox="saveDeviceData"
          label="HD"
        />
        <ott-checkbox
          v-model="isFHD"
          @changeCheckbox="saveDeviceData"
          label="FHD"
        />
        <ott-checkbox
          v-model="isUHD"
          @changeCheckbox="saveDeviceData"
          label="UHD"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";

export default {
  name: "DeviceSettings",
  components: {
    OttSelect,
    OttCheckbox,
  },
  computed: {
    ...mapState({
      usedDevicesClient: (state) => state.usedDevicesClient,
      locationsList: (state) => state.usedDevicesClient.locationList,
      selectedDeviceIndex: (state) =>
        state.usedDevicesClient.selectedDeviceIndex,
    }),

    selectedDevice() {
      if (this.usedDevicesClient.selectedLocation)
        return this.usedDevicesClient.usedDevices[this.selectedDeviceIndex];
      else return null;
    },

    timeShift: {
      set(value) {
        this.setSettingsForm({ keyName: 'timeShift', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.timeShift;
        else return null;
      },
    },

    language: {
      set(value) {
        this.setSettingsForm({ keyName: 'language', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.language;
        else return null;
      },
    },

    remoteControl: {
      set(value) {
        this.setSettingsForm({ keyName: 'remoteControl', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.remoteControl;
        else return null;
      },
    },

    audioTrackDefault: {
      set(value) {
        this.setSettingsForm({ keyName: 'audioTrackDefault', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.audioTrackDefault;
        else return null;
      },
    },

    httpCaching: {
      set(value) {
        this.setSettingsForm({ keyName: 'httpCaching', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.httpCaching.toString();
        else return null;
      },
    },

    streamQuality: {
      set(value) {
        this.setSettingsForm({ keyName: 'streamQuality', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.streamQuality;
        else return null;
      },
    },

    isBackgroundPlayer: {
      set(value) {
        this.setSettingsForm({ keyName: 'isBackgroundPlayer', value });
      },
      get() {
        if (this.selectedDevice) {
          return this.selectedDevice.settings.isBackgroundPlayer;
        } else return null;
      },
    },

    isSD: {
      set(value) {
        this.setSettingsForm({ keyName: 'isSD', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.isSD;
        else return false;
      },
    },

    isHD: {
      set(value) {
        this.setSettingsForm({ keyName: 'isHD', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.isHD;
        else return false;
      },
    },

    isFHD: {
      set(value) {
        this.setSettingsForm({ keyName: 'isFHD', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.isFHD;
        else return false;
      },
    },

    isUHD: {
      set(value) {
        this.setSettingsForm({ keyName: 'isUHD', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.isUHD;
        else return false;
      },
    },

    uiFontSize: {
      set(value) {
        this.setSettingsForm({ keyName: 'uiFontSize', value });
      },
      get() {
        if (this.selectedDevice) return this.selectedDevice.settings.uiFontSize;
        else return "normal";
      },
    },
  },

  methods: {
    ...mapMutations({
      setData: "usedDevicesClient/setData",
      setSettingsForm: "usedDevicesClient/setSettingsForm",
    }),

    ...mapActions({
      saveUsedDevice: "usedDevicesClient/saveUsedDevice",
    }),

    /**
     * Save device Data
     * **/

    async saveDeviceData() {
      await this.saveUsedDevice()
        .then((r) => {
          this.setData({
            savedDevice: {
              index: this.selectedDeviceIndex,
              saved: true,
            },
          });
        })
        .finally((r) => {
          this.setData({
            savedDevice: {
              index: this.selectedDeviceIndex,
              saved: false,
            },
          });
        });

    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.P-checkbox {
  padding: 8px 8px 0;

  .Ott-checkbox-cont + .Ott-checkbox-cont {
    margin-left: 20px;
  }
}
</style>