import { render, staticRenderFns } from "./ManageProfile.vue?vue&type=template&id=8d271178&scoped=true"
import script from "./ManageProfile.vue?vue&type=script&lang=js"
export * from "./ManageProfile.vue?vue&type=script&lang=js"
import style0 from "./ManageProfile.vue?vue&type=style&index=0&id=8d271178&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d271178",
  null
  
)

export default component.exports