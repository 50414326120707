<template>
  <div class="P-manage-vod P-manage-client">
    <div class="P-manage-vod-box">
      <TitleComponent title-text="Vod Enable"/>
      <div class="P-checkbox G-align-center">
        <ott-checkbox label="Protect Media By Age" v-model="isVodEnable"/>
      </div>
    </div>
    <div class="P-manage-vod-box" v-if="isVodEnable">
      <TitleComponent title-text="Rates Control"/>
      <div class="P-label-list">
        <div class="P-table-header">
          <ul class="G-flex ">
            <li class="secondary--text">Rates</li>
            <li class="secondary--text G-justify-center">Blood</li>
            <li class="secondary--text G-justify-center">Violence</li>
            <li class="secondary--text G-justify-center">Obscene</li>
            <li class="secondary--text G-justify-center">Porn</li>
            <li class="secondary--text G-justify-center">Horror </li>
          </ul>
        </div>
        <div class="P-table-body">
          <ul class="G-flex ">
            <li class="secondary--text">
              <p class="secondary--text"> Hide </p>
            </li>
            <li class="G-justify-center">
              <ott-checkbox v-model="hideIsBlood"/>
            </li>
            <li class="G-justify-center">
              <ott-checkbox v-model="hideIsViolence"/>
            </li>
            <li class="G-justify-center">
              <ott-checkbox v-model="hideIsObscene"/>
            </li>
            <li class="G-justify-center">
              <ott-checkbox v-model="hideIsPorn"/>
            </li>
            <li class="G-justify-center">
              <ott-checkbox v-model="hideIsHorror"/>
            </li>
          </ul>
          <ul class="G-flex ">
            <li class="secondary--text">
              <p class="secondary--text"> Protect </p>
            </li>
            <li class="G-justify-center">
              <ott-checkbox v-model="protectIsBlood"/>
            </li>
            <li class="G-justify-center">
              <ott-checkbox v-model="protectIsViolence"/>
            </li>
            <li class="G-justify-center">
              <ott-checkbox v-model="protectIsObscene"/>
            </li>
            <li class="G-justify-center">
              <ott-checkbox v-model="protectIsPorn"/>
            </li>
            <li class="G-justify-center">
              <ott-checkbox v-model="protectIsHorror"/>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState, mapMutations } from "vuex";
  import TitleComponent from "@/components/TitleComponent";
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";

  export default {
    name: 'ManageVod',
    components: {OttCheckbox, TitleComponent},
    computed: {
      ...mapState({
        formData: state => state.profileClients.formData,
      }),
      isVodEnable: {
        get() {
          return this.formData.isVodEnable
        },
        set(value) {
          this.setIsVodEnable(value)
        }
      },
      hideIsBlood: {
        get() {
          return this.formData.vodHide.isBlood
        },
        set(value) {
          this.setIsHideBlood(value)
        }
      },
      hideIsViolence: {
        get() {
          return this.formData.vodHide.isViolence
        },
        set(value) {
          this.setIsHideViolence(value)
        }
      },
      hideIsObscene: {
        get() {
          return this.formData.vodHide.isObscene
        },
        set(value) {
          this.setIsHideObscene(value)
        }
      },
      hideIsPorn: {
        get() {
          return this.formData.vodHide.isPorn
        },
        set(value) {
          this.setIsHidePorn(value)
        }
      },
      hideIsHorror: {
        get() {
          return this.formData.vodHide.isHorror
        },
        set(value) {
          this.setIsHideHorror(value)
        }
      },
      protectIsBlood: {
        get() {
          return this.formData.vodProtect.isBlood
        },
        set(value) {
          this.setIsProtectBlood(value)
        }
      },
      protectIsViolence: {
        get() {
          return this.formData.vodProtect.isViolence
        },
        set(value) {
          this.setIsProtectViolence(value)
        }
      },
      protectIsObscene: {
        get() {
          return this.formData.vodProtect.isObscene
        },
        set(value) {
          this.setIsProtectObscene(value)
        }
      },
      protectIsPorn: {
        get() {
          return this.formData.vodProtect.isPorn
        },
        set(value) {
          this.setIsProtectPorn(value)
        }
      },
      protectIsHorror: {
        get() {
          return this.formData.vodProtect.isHorror
        },
        set(value) {
          this.setIsProtectHorror(value)
        }
      },
    },

    methods: {
      ...mapMutations({
        setIsVodEnable: 'profileClients/setIsVodEnable',
        setIsHideBlood: 'profileClients/setIsHideBlood',
        setIsHideViolence: 'profileClients/setIsHideViolence',
        setIsHideObscene: 'profileClients/setIsHideObscene',
        setIsHidePorn: 'profileClients/setIsHidePorn',
        setIsHideHorror: 'profileClients/setIsHideHorror',
        setIsProtectBlood: 'profileClients/setIsProtectBlood',
        setIsProtectViolence: 'profileClients/setIsProtectViolence',
        setIsProtectObscene: 'profileClients/setIsProtectObscene',
        setIsProtectPorn: 'profileClients/setIsProtectPorn',
        setIsProtectHorror: 'profileClients/setIsProtectHorror',
      })
    }
  }

</script>
<style lang="scss" scoped>
  @import "src/assets/scss/formStyles";
  @import "src/assets/scss/variables";

  .P-manage-vod-box {
    &:nth-child(2) {
      margin-top: 16px;
    }
    .P-checkbox {
      margin-top: 12px;
    }
  }

  .P-table-header {
    ul {
      li {
        font-size      : $txt12;
        font-weight    : bold;
        padding        : 10px 17px;
        display        : flex;
        align-items    : center;
        text-transform : capitalize;
      }
    }
  }

  .P-table-body {
    ul {
      li {
        font-size      : $txt12;
        word-break     : break-all;
        padding        : 10px 17px;
        transition     : $transition;
        display        : flex;
        align-items    : center;
        text-transform : capitalize;

        p {
          margin : 0;
        }

        & + li {
          border-left : 1px solid $neutral-color45;
        }
      }

      border-top : 1px solid $neutral-color45;
    }
  }

  .P-label-list {
    overflow      : hidden;
    border-radius : 4px;
    border        : 1px solid $neutral-color45;
    margin-top    : 12px;

    ul {
      padding : 0;

      li {
        max-width : 100%;
        width     : 100%;

        & + li {
          border-left : 1px solid $neutral-color45;
        }

        &:first-child {
          max-width : 75px;
        }
      }
    }
  }
</style>