<template>
  <div>
    <title-component
      :click="edit"
      is-add
      title-text="Balance"
    />
    <div class="balance-content">
      <title-component :title-text="`Total balance: ${
        typeof balanceCredit.mainBalance === 'number' || typeof balanceCredit.mainBalance === 'string'
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(balanceCredit.mainBalance)
          : '$0.00'
      }`" />
      <div class="dept">
        <span>Dept: {{
          typeof balanceCredit.dept === 'number'
            ? new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
              }).format(balanceCredit.dept)
            : '$0.00'
        }}</span>
      </div>
      <div class="balance-content__info" v-if="comment">
        <p>{{comment}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import TitleComponent from "@/components/TitleComponent";

export default {
  name: "Balance",
  components: {TitleComponent },
  computed: {
    ...mapState({
      balanceCredit: state => state.balanceCreditClient,
      comment: state => state.balanceCreditClient.balanceManage.comment
    }),
  },
  methods: {
    ...mapMutations({
      setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      setBalanceManageEdit: 'balanceCreditClient/setBalanceManageEdit'
    }),
    edit() {
      this.setShowSaveBtn(false)
      this.setBalanceManageEdit();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.balance-content {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 5px #00000012;
  border-radius: 4px;
  padding: 12px 22px 20px;
  margin-top: 12px;
  &__info {
    border-top: 1px solid $neutral-color;
    margin-top: 20px;
    p {
      margin: 16px 0 0;
      font-size: $txt12;
      line-height: 19px;
      color: $secondary-color;
    }
  }
}
.dept {
  display: inline-flex;
  align-items: center;
  height: 23px;
  background: $danger-color-light 0 0 no-repeat padding-box;
  border-radius: 12px;
  color: white;
  justify-content: center;
  width: auto;
  padding: 0 10px;
  margin-top: 8px;
  span {
    height: 16px;
    text-align: left;
    font: normal normal 600 12px Segoe UI;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }
}

.P-comment {
  font-size: $txt12;
  margin-bottom: 0;
}
.theme--dark{
  .balance-content{
    background-color: $dark_light;
  }
}
</style>
