<template>
  <div class="balance-manage">
    <title-component 
      :title-text="balanceManage.isEdit 
        ? `<p class='mb-1'>Balance Manage</p> <p>Total: ${ totalBalance }</p>` 
        : `<p class='mb-1'>Balance</p> <p>Total: ${ totalBalance }</p>`"
    />
    <div class="body">
      <div class="G-align-center">
        <div
          :class="{
            'P-padding-33': clientModalIsOpen,
            'pb-4 w-250': !clientModalIsOpen
          }"
        >
          <ott-select
            v-model="paymentMethodValue"
            :items="paymentMethod"
            :error="$v.balanceManage.paymentMethodValue.$error"
            selected-text="name"
            label="Payment Method*"
          />
        </div>
        <div 
          v-if="paymentMethodValue === 1 || paymentMethodValue === 0"           
          :class="{
            'P-padding-33': clientModalIsOpen,
            'px-4 pb-4 w-250': !clientModalIsOpen
          }"
        >
          <ott-input
            v-model="routingNumber"
            :error="$v.balanceManage.routingNumber.$error"
            :label="`${paymentMethodValue===0?'Money order' : 'Check'} Number*`"
            :is-percent="true"
            :customInputClass="'P-order-money'"
          />
        </div>
        <div  
          :class="{
            'P-padding-33': clientModalIsOpen,
            'pb-4 w-250': !clientModalIsOpen,
            'ps-4' : paymentMethodValue !== 0 && paymentMethodValue !== 1 && !clientModalIsOpen
          }"
        >
          <ott-input
            v-model="balance"
            :error="isValidateBalance || $v.balanceManage.balance.$error"
            :wheel="(e) => e.preventDefault()"
            @emitFunc="validateBalance"
            label="Balance Amount*"
            type="number"
          />
        </div>
      </div>
      <div class="P-comment-input">
        <ott-textarea label="Comment" :rows="4" v-model="comment"/>
      </div>
    </div>
    <div class="P-save-btn-block" v-if="!isAddClient && !balanceManage.isEdit">
      <SaveResetButtons
        :left-click-func="cancelForm"
        :right-click-func="save"
        :is-loading="isLoading"
        left-label="Cancel"
      />
    </div>
    <SaveResetButtons
      v-else
      :left-click-func="cancel"
      :right-click-func="save"
      :is-loading="isLoading"
      left-label="Cancel"
    />
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import { required, requiredIf } from "vuelidate/lib/validators";
  // components
  import OttSelect from "@/components/vuetifyComponents/OttSelect";
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
  import OttButton from "@/components/vuetifyComponents/OttButton";
  import TitleComponent from "@/components/TitleComponent";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
  import SaveResetButtons from "@/components/SaveResetButtons";

  export default {
    name: "BalanceForm",
    components: {
      SaveResetButtons,
      OttButtonLoader,
      TitleComponent,
      OttButton,
      OttTextarea,
      OttInput,
      OttSelect,
    },
    validations: {
      balanceManage: {
        paymentMethodValue: {required},
        balance: {required},
        routingNumber: {
          required: requiredIf(function () {
            return (
              this.paymentMethodValue === 0 || this.paymentMethodValue === 1
            );
          })
        },
      }
    },
    data() {
      return {
        isValidateBalance: false
      }
    },

    computed: {
      ...mapState({
        balanceManage: state => state.balanceCreditClient.balanceManage,
        credit: state => state.balanceCreditClient.credit,
        clientId: state => state.clientsModule.clientId,
        showSaveBtn: state => state.personalInfoClient.showSaveBtn,
        isAddClient: state => state.clientsModule.isAddClient,
        isLoading: state => state.appModule.isLoading,
        mainBalance: state => state.balanceCreditClient.mainBalance,
        clientModalIsOpen: state => state.clientsModule.modal.open,
      }),

      totalBalance() {
         return typeof this.mainBalance === 'number' || typeof this.mainBalance === 'string'
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(this.mainBalance)
          : '$0.00'
      },

      paymentMethod: {
        get() {
          return this.balanceManage.paymentMethod;
        }
      },
      paymentMethodValue: {
        get() {
          return this.balanceManage.paymentMethodValue;
        },
        set(value) {
          this.setPaymentMethodValue(value);
        }
      },
      routingNumber: {
        get() {
          return this.balanceManage.routingNumber;
        },
        set(value) {
          this.setRoutingNumber(value);
        }
      },
      balance: {
        get() {
          return this.balanceManage.balance;
        },
        set(value) {
          this.setBalance(value);
        }
      },
      comment: {
        get() {
          return this.balanceManage.comment;
        },
        set(value) {
          this.setComment(value);
        }
      }
    },

    methods: {
      ...mapActions({
        addBalance: 'balanceCreditClient/addBalance',
        getBalanceCredit: 'balanceCreditClient/getBalanceCredit'
      }),
      ...mapMutations({
        saveBalance: 'balanceCreditClient/saveBalance',
        setCreditIsStart: 'balanceCreditClient/setIsStart',
        resetBalanceManage: 'balanceCreditClient/resetBalanceManage',
        resetCreditData: 'balanceCreditClient/resetCreditData',
        setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
        setPaymentMethodValue: "balanceCreditClient/setPaymentMethodValue",
        setRoutingNumber: "balanceCreditClient/setRoutingNumber",
        setBalance: "balanceCreditClient/setBalance",
        setComment: "balanceCreditClient/setComment",
        setData: "balanceCreditClient/setData",
      }),
      validateBalance() {
        this.isValidateBalance = +this.balanceManage.balance.split('')[0] === 0
      },
      async save() {
        this.$v.balanceManage.$touch();
        if (!this.$v.balanceManage.$error) {
          const data = {
            clientId: this.clientId,
            method: this.paymentMethodValue,
            checkNumber: this.paymentMethodValue === 1 ? this.balanceManage.routingNumber : '',
            moneyOrder: this.paymentMethodValue === 0 ? this.balanceManage.routingNumber : '',
            comment: this.balanceManage.comment,
            balance: this.balanceManage.balance
          }
          await this.addBalance(data)
          await this.getBalanceCredit()
          return Promise.resolve(true)
        }
      },

      async cancel() {
        await this.getBalanceCredit()
        this.setShowSaveBtn(true)
        this.saveBalance();
      },

      cancelForm() {
        if (!this.balanceManage.isSave) {
          this.setData({ showBalanceComponents: false })
        } else {
          this.setData({ showBalanceComponents: true })
        }

        if (!this.credit.isStart) {
          this.setData({ showCreditComponents: false })
        } else {
          this.setData({ showCreditComponents: true })
        }   
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/formStyles";
 
  .balance-manage {
    display        : flex;
    flex-direction : column;

    & > .buttons {
      display    : flex;

      & > :nth-child(2) {
        margin-left : 10px;
      }
    }
  }
  .buttons::v-deep{
    .P-save-and-reset-block{
      margin-top: 35px;
    }
  }

  ::v-deep {
    .v-input__slot,
    .v-input--dense>.v-input__control>.v-input__slot {
      margin-bottom: 0;
    }
    .v-text-field__details {
      display: none;
    }
  }

  .w-250 {
    width: 250px;
  }
</style>
