var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"balance-manage"},[_c('title-component',{attrs:{"title-text":_vm.balanceManage.isEdit 
      ? `<p class='mb-1'>Balance Manage</p> <p>Total: ${ _vm.totalBalance }</p>` 
      : `<p class='mb-1'>Balance</p> <p>Total: ${ _vm.totalBalance }</p>`}}),_c('div',{staticClass:"body"},[_c('div',{staticClass:"G-align-center"},[_c('div',{class:{
          'P-padding-33': _vm.clientModalIsOpen,
          'pb-4 w-250': !_vm.clientModalIsOpen
        }},[_c('ott-select',{attrs:{"items":_vm.paymentMethod,"error":_vm.$v.balanceManage.paymentMethodValue.$error,"selected-text":"name","label":"Payment Method*"},model:{value:(_vm.paymentMethodValue),callback:function ($$v) {_vm.paymentMethodValue=$$v},expression:"paymentMethodValue"}})],1),(_vm.paymentMethodValue === 1 || _vm.paymentMethodValue === 0)?_c('div',{class:{
          'P-padding-33': _vm.clientModalIsOpen,
          'px-4 pb-4 w-250': !_vm.clientModalIsOpen
        }},[_c('ott-input',{attrs:{"error":_vm.$v.balanceManage.routingNumber.$error,"label":`${_vm.paymentMethodValue===0?'Money order' : 'Check'} Number*`,"is-percent":true,"customInputClass":'P-order-money'},model:{value:(_vm.routingNumber),callback:function ($$v) {_vm.routingNumber=$$v},expression:"routingNumber"}})],1):_vm._e(),_c('div',{class:{
          'P-padding-33': _vm.clientModalIsOpen,
          'pb-4 w-250': !_vm.clientModalIsOpen,
          'ps-4' : _vm.paymentMethodValue !== 0 && _vm.paymentMethodValue !== 1 && !_vm.clientModalIsOpen
        }},[_c('ott-input',{attrs:{"error":_vm.isValidateBalance || _vm.$v.balanceManage.balance.$error,"wheel":(e) => e.preventDefault(),"label":"Balance Amount*","type":"number"},on:{"emitFunc":_vm.validateBalance},model:{value:(_vm.balance),callback:function ($$v) {_vm.balance=$$v},expression:"balance"}})],1)]),_c('div',{staticClass:"P-comment-input"},[_c('ott-textarea',{attrs:{"label":"Comment","rows":4},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)]),(!_vm.isAddClient && !_vm.balanceManage.isEdit)?_c('div',{staticClass:"P-save-btn-block"},[_c('SaveResetButtons',{attrs:{"left-click-func":_vm.cancelForm,"right-click-func":_vm.save,"is-loading":_vm.isLoading,"left-label":"Cancel"}})],1):_c('SaveResetButtons',{attrs:{"left-click-func":_vm.cancel,"right-click-func":_vm.save,"is-loading":_vm.isLoading,"left-label":"Cancel"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }