<template>
  <div class="P-modal-components">
    <div
      class="P-profile-list"
      v-if="
        profileClients.profilesList.length &&
        !profileClients.isEditMode &&
        !profileClients.isAddMode
      "
    >
      <TitleComponent
        :is-add="true"
        :click="addNewProfile"
        title-text="Profiles"
      />
      <ott-locations-list
        :locations-list="profileClients.profilesList"
        :selected-data="profileClients.selectedLocation"
        @changeLocation="changeLocation"
      />
      <ProfileList />
    </div>
    <ManageProfile v-else />
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import OttLocationsList from "@/components/OttLocationsList/OttLocationsList";
import TitleComponent from "@/components/TitleComponent";
import ManageProfile from "./components/ManageProfile";
import ProfileList from "./components/ProfileList";

export default {
  name: "ProfileClient",
  components: { ProfileList, ManageProfile, TitleComponent, OttLocationsList },
  computed: {
    ...mapState({ profileClients: (state) => state.profileClients }),
  },
  mounted() {
    this.selectLocation(this.profileClients.profilesList[0]);
  },
  methods: {
    ...mapMutations({
      selectLocation: 'profileClients/selectLocation',
      setIsAddMode: 'profileClients/setIsAddMode'
    }),

    changeLocation(data) {
      this.selectLocation(data);
    },
    addNewProfile() {
      this.setIsAddMode(true);
    },
  },
};
</script>