<template>
  <div>
    <TitleComponent
      v-if="!addressClient.allAddresses.length && !showComponents" 
      :is-add="true"
      :click="() => showComponents = true" 
      title-text="Add Address" 
    />

    <div v-if="showComponents">
      <ManageAddress
        v-if="
          addressClient.isAddMode ||
          addressClient.isEditMode ||
          !addressClient.allAddresses.length ||
          !addressClient.showAddressList
        "
        ref="manageAddress"
      />
      <AddressList v-else />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import TitleComponent from "@/components/TitleComponent";
import ManageAddress from "./component/ManageAddress";
import AddressList from "./component/AddressList";

export default {
  name: "Address",
  components: { TitleComponent, AddressList, ManageAddress },
  computed: {
    ...mapState({
      addressClient: (state) => state.addressClient,
    }),

    showComponents: {
      set(value) {
        this.setData({ showComponents: value });
      },
      get() {
        return this.addressClient.showComponents;
      },
    },
  },

  watch: {
    'addressClient.allAddresses'(addressList) {
      if (!addressList.length) {
        this.setData({ showComponents: false });
      } else {
        this.setData({ showComponents: true });
      }
    }
  },

  mounted() {
    if (!this.addressClient.allAddresses.length) {
      this.setData({ showComponents: false });
    } else {
      this.setData({ showComponents: true });
    }
  },

  beforeDestroy() {
    if (this.$route.name === 'Search') {
      this.resetAddress()
      this.setData({ showComponents: false });
    }
  },

  methods: {
    ...mapMutations({
      setData: 'addressClient/setData',
      resetAddress: 'addressClient/resetAddress'
    })
  }
};
</script>
