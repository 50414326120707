<template>
  <div>
    <title-component
      :click="edit"
      is-edit
      title-text="Credit"
    />
    <div class="content-second">
      <title-component :title-text="`Total credit: ${
         typeof credit.creditAmount === 'number'
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(credit.creditAmount)
          : '$0.00'
      }`" />
      <div class="content">
        <div class="P-content-box">
          <span class="P-title neutral--text">Credit Start Date </span>
          <span class="secondary--text">{{ credit.date ? credit.date : '' }}</span>
        </div>
        <div class="P-content-box">
          <span class="P-title neutral--text">Remains days for payment</span>
          <span class="secondary--text">{{credit.selectedCreditTerm}} {{credit.radioValue === 'Day' ? 'Day' : 'Month' }}</span>
        </div>
        <div class="P-content-box" v-if="credit.selectedCreditsPauseAfterDays">
          <span class="P-title neutral--text">Clients Pause After Days</span>
          <span class="secondary--text">
            {{ credit.selectedCreditsPauseAfterDays }} Day
          </span>
        </div>
      </div>
      <p v-if="credit.comment" class="secondary--text P-comment">
        {{ credit.comment }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import TitleComponent from "@/components/TitleComponent";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  name: "Credit",
  components: { TitleComponent },
  mixins: [DateFormatMixin],
  computed: {
    ...mapState({
      credit: state => state.balanceCreditClient.credit,
    }),
  },
  methods: {
    ...mapActions({
      getBalanceCredit: 'balanceCreditClient/getBalanceCredit',
    }),
    ...mapMutations({
      setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      setCreditEdit: 'balanceCreditClient/setCreditEdit'
    }),
    async edit() {
      this.setShowSaveBtn(false)
      this.setCreditEdit()
      await this.getBalanceCredit()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
.content-second {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 5px #00000012;
  border-radius: 4px;
  padding: 12px 22px 20px;
  margin-top: 12px;
}

.content {
  span {
    font-size: $txt12;
    &:first-child {
      min-width: 145px;
      max-width: 145px;
      display: inline-block;
      margin-right: 28px;
    }
    &:last-child {
      font-size: $txt14;
    }
  }
  .P-content-box:first-child {
    margin-bottom: 10px;
  }
}
.P-comment {
  font-size: $txt12;
  margin-bottom: 0;
  border-top: 1px solid $neutral-color;
  margin-top: 20px;
  padding-top: 16px;
}
.theme--dark{
    .content-second{
      background-color: $dark_light;
    }
  }

</style>
