<template>
  <div>
    <manage-equipment
      ref="manageEquipment"
      v-if="
        equipments.isAddMode ||
        equipments.isEditMode ||
        equipments.isReturnMode ||
        !clientEquipments.length
      "
    />

    <EquipmentsList v-else />
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import ManageEquipment from "./components/ManageEquipment";
import EquipmentsList from "./components/EquipmentsList";
import EquipmentsMixin from "./EquipmentsMixin";
export default {
  name: "Equipments",
  components: { ManageEquipment, EquipmentsList },
  mixins: [EquipmentsMixin],

  async created() {
    await Promise.all([
      this.getEquipmentsList(),
      this.getEquipmentsTypes()
    ])
  },

  methods: {
    ...mapMutations({
      resetEquipmentsForm: "equipments/resetFormData",
    }),
  },
};
</script>
