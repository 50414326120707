<template>
  <div class="P-modal-components">
    <div class="P-manage-location-box">
      <TitleComponent :title-text="getTitleName()" />
      <div class="P-manage-location P-manage-client">
        <div class="P-manage-location-top G-flex-wrap">
          <div class="P-padding-33">
            <ott-input
              label="Location Name*"
              v-model="locationName"
              :error="$v.formData.locationName.$error"
            />
          </div>
          <div class="P-padding-33">
            <ott-select
              v-model="maxDevice"
              :items="locationClient.maxDeviceList"
              :multiple="false"
              :persistentHint="true"
              :error="$v.formData.maxDevice.$error"
              label="Max Devices*"
              selected-text="name"
            />
          </div>
          <div class="P-padding-33">
            <ott-select-autocomplete
              v-model="timezone"
              :items="timeZoneLists"
              :multiple="false"
              :persistentHint="true"
              :error="$v.formData.timezone.$error"
              label="Timezone*"
              selected-text="name"
            />
          </div>
          <div class="P-padding-33">
            <ott-select
              v-model="server"
              :items="serverList"
              :multiple="false"
              :persistentHint="true"
              :error="$v.formData.server.$error"
              selected-text="name"
              label="Server*"
            />
          </div>
          <div class="P-padding-33">
            <ott-input
              v-model="parentalCode"
              :error="$v.formData.parentalCode.$error"
              label="Parental Code*"
            />
          </div>
          <!--It depends on commented checkboxes above-->
          <!-- <div class="P-padding-100">
            <transition name="slide">
              <quill-editor
                v-if="isBlockLocation || isPauseSubscriptions"
                v-model="comment"
                :options="{ placeholder: 'Comment' }"
                ref="myQuillEditor"
                :class="
                  $vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'
                "
              ></quill-editor>
            </transition>
          </div> -->
          <div
            v-if="
              locationClient.isEditMode &&
              locationClient.formData.subscriptionState
            "
            class="P-checkbox G-flex"
          >
            <!-- INFO: it is commented because maybe in future need to show these checkboxes -->
            <!-- <ott-checkbox label="Block Location" v-model="isBlockLocation" />
            <ott-checkbox
              v-model="isPauseSubscriptions"
              label="Pause Subscriptions"
            /> -->
            <ott-checkbox label="VOD Enable" v-model="VODEnable" />
            <ott-checkbox label="Archive Enable" v-model="archiveEnable" />
            <ott-checkbox label="Timeshift enable " v-model="timeShiftEnable" />
          </div>
        </div>
      </div>
    </div>
    <div class="P-manage-location-box">
      <TitleComponent title-text="Security And Login" />
      <div class="P-location-password-block">
        <div class="P-location-password-box">
          <ul>
            <li><p class="secondary--text">Login</p></li>
            <li>
              <p class="secondary--text">
                {{ getRandomLogin }}
              </p>
            </li>
          </ul>
        </div>
        <div class="P-location-password-box">
          <ul>
            <li><p class="secondary--text">Password</p></li>
            <li>
              <p class="secondary--text">
                {{ getRandomPassword }}
              </p>
              <span class="secondary--text"
                >Last changed
                {{
                  $moment(getLastChangedPassword).format(
                    ottDateFormat + " HH:mm"
                  )
                }}</span
              >
            </li>
            <li v-if="locationClient.isEditMode">
              <div
                class="P-reset-password G-align-center"
                @click="toggleModalResetPassword(true)"
              >
                <span class="icon-lock-reset" />
                <p class="secondary--text">Reset Password</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="P-save-btn-block" v-if="!isAddClient && !locationClient.locationList.length">
      <ott-button-loader
        :is-loading="isLoading"
        @onClick="addLocation"
        button-text="Save"
        class-name="primary-sm-btn"
      />
    </div> -->

    <SaveResetButtons
      :is-loading="isLoading"
      :left-click-func="cancel"
      :right-label="locationClient.isEditMode ? 'Save' : 'Add'"
      :right-click-func="locationClient.isEditMode ? editLocation : addLocation"
    />
    <UpdatePasswordLocation
      v-if="locationClient.openModal.isOpen"
      @updatePassword="updateLoginPass"
      @closeModal="toggleModalResetPassword(false)"
    />

    <PageLoader v-if="showLoader" />
  </div>
</template>
<script>
import ManageLocationMixin from "./ManageLocationMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import TitleComponent from "@/components/TitleComponent";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import UpdatePasswordLocation from "./UpdatePasswordModal";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import PageLoader from "@/components/pageLoader/PageLoader";
import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";

export default {
  name: "ManageLocationClients",
  components: {
    OttButtonLoader,
    SaveResetButtons,
    UpdatePasswordLocation,
    OttCheckbox,
    OttSelect,
    OttInput,
    TitleComponent,
    PageLoader,
    OttSelectAutocomplete,
  },
  mixins: [ManageLocationMixin, DateFormatMixin],
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/formStyles";

.P-manage-location-top {
  margin: 0 -10px;
}

.P-checkbox {
  padding: 8px 8px 0;

  .Ott-checkbox-cont + .Ott-checkbox-cont {
    margin-left: 20px;
  }
}

.P-manage-location-box:first-child {
  margin-bottom: 16px;
}

.P-location-password-block {
  margin-top: 12px;
  padding: 0 22px;
  box-shadow: 0 0 5px #284e6626;
  border-radius: 3px;

  .P-location-password-box {
    padding: 14px 0;

    ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;

      li {
        width: 100%;
        max-width: 100%;
        margin: 0;

        p {
          margin: 0;
          font-size: $txt14;
          font-weight: 600;
        }

        span {
          font-size: $txt12;
        }

        &:first-child {
          min-width: 150px;
          max-width: 150px;
        }
      }
    }

    & + .P-location-password-box {
      border-top: 1px solid $neutral-color45;
    }
  }
}

.P-reset-password {
  cursor: pointer;

  span {
    color: $neutral-color;
    font-size: $txt18 !important;
    margin-right: 15px;
    display: block;
    transition: $transition;
  }

  p {
    transition: $transition;
  }

  &:hover {
    span,
    p {
      color: $primary-color !important;
    }
  }
}
</style>
