<template>
  <div v-if="showFinanceForm" class="P-modal-components">
    <div class="P-modal-component-body">
      <div class="P-finance-block P-manage-client">
        <TitleComponent title-text="Finance" />
        <div class="P-finance-box G-flex-wrap">
          <div class="P-padding-20">
            <ott-select
              v-model="currency"
              :items="financeClient.currencyList"
              :multiple="false"
              :persistentHint="true"
              :isTranslate="true"
              selected-text="name"
              label="Currency"
            />
          </div>
          <div class="P-padding-30">
            <ott-select
              v-model="priceGroup"
              :items="priceGroupAdmin.clientsPriceGroups"
              :multiple="false"
              :persistentHint="true"
              :isTranslate="true"
              selected-text="name"
              label="Price Group"
            />
          </div>
        </div>
      </div>
      <div class="P-finance-block P-manage-client">
        <TitleComponent title-text="Recurring Payments" />
        <div class="P-finance-box">
          <div class="P-padding-50">
            <ott-select
              v-model="paymentForPackages"
              :items="financeClient.paymentList"
              :multiple="false"
              :clear="true"
              :persistentHint="true"
              label="For Packages"
              selected-text="name"
            />
          </div>
          <!-- <div class="P-padding-50">
            <ott-select
              v-model="paymentForDevice"
              :items="financeClient.paymentList"
              :multiple="false"
              :persistentHint="true"
              :error="$v.formData.paymentForDevice.$error"
              label="For Devices Rent*"
              selected-text="name"
            />
          </div> -->
          <div class="P-padding-50">
            <ott-switch
              v-model="isPaperlessBilling"
              label="Paperless billing"
              class="mb-2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="P-save-btn-block" v-if="!isAddClient || isGeneralSearch">
      <ott-button-loader
        :is-loading="isLoading"
        @onClick="saveData"
        button-text="Save"
        class-name="primary-sm-btn"
      />
    </div>
  </div>

  <div v-else class="P-modal-component-body">
    <div class="P-finance-block P-manage-client">
      <TitleComponent title-text="Finance" />
      <div class="P-table-block">
        <div class="P-table-header">
          <ul class="G-flex">
            <li
              class="secondary--text"
              v-for="(item, key) of tableHeaders"
              :key="key"
            >
              {{ item }}
            </li>
            <li></li>
          </ul>
        </div>
        <div class="P-table-body">
          <ul class="G-flex">
            <li>
              {{ currencyName }}
            </li>
            <li>{{ priceGroupName }}</li>
            <li class="text-center" v-html="paymentForPackagesName" />
            <li>
               <span v-if="isPaperlessBilling" class="G-badge G-approve-badge status-badge">On</span>
               <span v-else class="G-badge G-reject-badge status-badge">Off</span>
            </li>
            <li class="G-align-center">
              <div class="P-actions">
                <v-menu left content-class="P-actions-list">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="financeSetData({ showFinanceForm: true })"
                      class="P-list"
                    >
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FinanceMixin from "./FinanceMixin";
import CreditCardsMixin from "@/mixins/CreditCardsMixin";

// component
import TitleComponent from "@/components/TitleComponent";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "FinanceClient",
  components: {
    OttButtonLoader,
    OttSwitch,
    OttSelect,
    SaveResetButtons,
    TitleComponent,
  },
  mixins: [FinanceMixin, CreditCardsMixin],
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/formStyles";
@import "src/views/clients/modals/manageClient/simpleTable";

.P-finance-box {
  margin: 0 -10px;
}

.P-finance-block {
  margin-bottom: 6px;
}
</style>