<template>
  <div class="P-bank-account">
    <div class="P-full-line G-flex">
      <div class="P-padding-33">
        <OttInput
          label="Bank name*"
          v-model="bankName"
          :error="$v.bankTransfer.bankName.$error"
          @emitKeypress="onlyLetters($event)"
        />
      </div>
      <div class="P-padding-33">
        <OttInput
          label="Routing number*"
          type="number"
          v-model="routingNumber"
          :error="$v.bankTransfer.routingNumber.$error"
        />
      </div>
      <div class="P-padding-33">
        <OttInput
          label="Account number*"
          v-model="accountNumber"
          :error="$v.bankTransfer.accountNumber.$error"
        />
      </div>
    </div>
    <v-radio-group v-model="bankTransfer.type">
      <div class="G-flex P-radio-buttons ms-2">
        <div>
          <OttRadio
            v-model="paymentMethod.bankAccountType.personal"
            label="Personal account"
          />
        </div>
        <div>
          <OttRadio
            v-model="paymentMethod.bankAccountType.business"
            label="Business account"
          />
        </div>
      </div>
    </v-radio-group>
    <div
      class="P-full-line G-flex"
      v-if="bankTransfer.type === paymentMethod.bankAccountType.personal"
    >
      <div class="P-padding-33">
        <OttInput
          label="First name"
          v-model="firstname"
          :error="$v.bankTransfer.firstname.$error"
          @emitKeypress="onlyLetters($event)"
        />
      </div>
      <div class="P-padding-33">
        <OttInput
          label="Last name"
          v-model="lastname"
          :error="$v.bankTransfer.lastname.$error"
          @emitKeypress="onlyLetters($event)"
        />
      </div>
      <div class="P-padding-33">
        <OttInput
          label="Nickname*"
          v-model="nickname"
          :error="$v.bankTransfer.nickname.$error"
        />
      </div>
    </div>
    <div class="P-full-line G-flex" v-else>
      <div class="P-padding-33">
        <OttInput
          label="Company name*"
          v-model="companyName"
          :error="$v.bankTransfer.companyName.$error"
        />
      </div>
    </div>
    <div v-if="!isCheckout">
      <!-- <div class="P-save-btn-block" v-if="!isAddClient && !paymentList.length">
        <ott-button-loader
          button-text="Save"
          class-name="primary-sm-btn"
          :is-loading="isLoading"
          @onClick="saveData"
        />
      </div> -->
      <SaveResetButtons
        :left-click-func="resetData"
        :right-label="paymentMethod.editMode ? 'Save' : 'Add'"
        :right-click-func="paymentMethod.editMode ? editData : saveData"
        :is-loading="isLoading"
        left-label="Cancel"
      />
    </div>
    
    <div class="mt-5">
      <ErrorMessageContainer :message="errorMessage" v-if="error"/>
    </div>
  </div>
</template>
<script>
import BankAccountMixin from "./BankAccountMixin";
// components
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import OttButton from "@/components/vuetifyComponents/OttButton";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "BankAccount",
  components: {
    OttButtonLoader,
    SaveResetButtons,
    OttButton,
    ErrorMessageContainer,
    OttCheckbox,
    OttRadio,
    OttInput,
  },
  mixins: [BankAccountMixin],
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.P-bank-account::v-deep {
  .v-text-field__details {
    margin-bottom: 0;
  }

  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 0;
  }

  .P-checkbox {
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}

.P-bank-account {
  margin-top: 15px;
}

.P-radio-buttons {
  margin-left: -4px;
  padding: 4px 0;
  > div {
    margin-right: 22px;
  }
}

::v-deep {
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
}

.P-save-and-reset-block {
  margin-top: 25px;
}
.P-save-btn-block {
  margin-top: 25px;
}
</style>
