<template>
  <div class="P-image-settings">
    <div class="ott-menu select-all-menu-cont">
      <v-menu offset-y center open-on-hover attach=".select-all-menu-cont">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
            class="P-settings"
            :ripple="false"
          >
            <span
              class="neutral--text P-icon icon-photo_camera_black_24dp"
            ></span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :ripple="false" @click="changeImage">
            <v-list-item-title>Change Profile Photo</v-list-item-title>
          </v-list-item>
          <v-list-item :ripple="false" @click="removeImage">
            <v-list-item-title>Remove</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImageSettingsProfile",
  methods: {
    changeImage() {
      this.$emit("changeImage");
    },
    removeImage() {
      this.$emit("removeImage");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
.select-all-menu-cont {
  .P-icon {
    font-size: 26px !important;
    color: $white !important;
  }
}

.select-all-menu-cont button .mdi-dots-vertical,
.settings-btn button .mdi-settings-outline {
  height: 38px;
  font-size: 24px;
  width: 38px;
  border-radius: 4px;
  background-color: transparent !important;
}

.select-all-menu-cont::v-deep button {
  &:before,
  &:after {
    display: none;
  }
}

.select-all-menu-cont {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: $transition;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
}

.P-image-settings::v-deep {
  .v-menu__content {
    margin-top: 0 !important;
    left: 50% !important;
    transform: translateX(-50%);
    min-width: 185px !important;
  }
}
</style>