import { mapMutations, mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      masks: {
        input: "MM-DD-YYYY"
      },
      tableHeaders: [
        "First Name",
        "Last Name",
        "Date Of Birth",
        "Gender",
      ],
      showForm: false,
    }
  },
  validations: {
    formData: {
      firstname: { required },
      lastname: { required },
      gender: { required },
      provider: { required }
    }
  },

  computed: {
    ...mapState({
      // important variables vor get list and  change  columns
      personalInfoClient: state => state.personalInfoClient,
      formData: state => state.personalInfoClient.formData,
      clientId: state => state.clientsModule.clientId,
      locationList: state => state.locationClient.locationList,
      showSaveBtn: state => state.personalInfoClient.showSaveBtn,
      isAddClient: state => state.clientsModule.isAddClient,
      isLoading: state => state.appModule.isLoading,
      showPersonalInfoForm: state => state.clientsModule.showPersonalInfoForm,
      authProvider: state => state.auth.user.provider,
      resellersList: state => state.providers.resellersList,
      disableFormItems: state => state.personalInfoClient.disableFormItems,
      isAddClientFromSearch: state => state.clientsModule.isAddClientFromSearch,
    }),

    subProviders() {
      let list = [
        {
          name: this.authProvider.name[0].name,
          value: this.authProvider.id
        },
        ...this.resellersList
      ]

      return list
    },

    firstname:{
      get(){
        return this.personalInfoClient.formData.firstname
      },
      set(value){
        this.setFirstName(value)
      }
    },
    lastname:{
      get(){
        return this.personalInfoClient.formData.lastname
      },
      set(value){
        this.setLastName(value)
      }
    },
    dateOfBirth:{
      get(){
        return this.personalInfoClient.formData.dateOfBirth
      },
      set(value){
        this.setDateOfBirth(value)
      }
    },
    gender:{
      get(){
        return this.personalInfoClient.formData.gender
      },
      set(value){
        this.setGender(value)
      }
    },
    
    clientProvider: {
      get(){
        return this.personalInfoClient.formData.provider
      },
      set(value){
        this.setClientProvider(value)
      }
    }
  },

  async created() {
    if (this.$route.name !== 'Search') {
      if (this.clientId) {
        if (this.locationList.length) {
          this.setDisableTabs(9);
          
        } else {
          this.setDisableTabs(6);
        }
      } else {
        this.setDisableTabs(0);
      }
    }
  },

  methods: {
    ...mapMutations({
      setDisableTabs: "clientsModule/setDisableTabs",
      nextTab: 'clientsModule/nextTab',
      showActiveTabs: 'clientsModule/setDisableTabs',
      disableButton: 'clientsModule/setDisableButton',
      setFirstName: 'personalInfoClient/setFirstName',
      setLastName: 'personalInfoClient/setLastName',
      setDateOfBirth: 'personalInfoClient/setDateOfBirth',
      setGender: 'personalInfoClient/setGender',
      setIsBlock: 'personalInfoClient/setIsBlock',
      setInPause: 'personalInfoClient/setInPause',
      setClientProvider: 'personalInfoClient/setClientProvider',
      resetData: 'personalInfoClient/resetData',
    }),

    ...mapActions({
      addPersonalInfo: 'personalInfoClient/addPersonalInfo',
      editPersonalInfo: 'personalInfoClient/editPersonalInfo',
      getPersonalInfo: 'personalInfoClient/getPersonalInfo'
    }),

    getDate(date) {
      return this.$moment(date).format(this.ottDateFormat).valueOf();
    },
    
    resetData() {
      this.resetData()
    },
    async saveData() {
      this.$v.formData.$touch();

      const personalInfo = {
        firstname: this.formData.firstname,
        lastname: this.formData.lastname,
        dateOfBirth: !this.formData.dateOfBirth ? '' : this.formData.dateOfBirth,
        comment: '',
        sex: this.formData.gender,
        provider: this.clientProvider
      };

      if (!this.$v.formData.$error) {
        if (this.clientId) {
          await this.editPersonalInfo({personalInfo, clientId: this.clientId})
          await this.getPersonalInfo({clientId: this.clientId})
        } else {
          await this.addPersonalInfo({personalInfo}).then(data => {
            if ((data && this.$route.name !== 'Search') || (data && this.$route.name === 'Search' && this.isAddClientFromSearch)) {
              this.nextTab()
              this.showActiveTabs(6)
              this.disableButton(false)
            }
          })
        }
      }

      this.showForm = false;
    },

    /**
     * Only letters and spaces - regex
     * **/
    onlyLetters(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[0-9*#+-/=~․ժ։;`!@$%^&()_?><:"}{|\\]+$/.test(char)) {
        return event.preventDefault();
      } else {
        return true;
      }
    }
  },
}