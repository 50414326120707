<template>
  <ott-dialog
    v-if="clients.modal.isOpen"
    :is-open="clients.modal.isOpen"
    :show-footer="false"
    :modal-title="clients.clientId ? 'Edit Client' : 'Add Client'"
    :width="1120"
    close-value="addProvider"
    @outside="cancel"
    @resetDataModal="cancel"
  >
    <template v-slot:modalBody>
      <div class="parent-add-provider-modal">
        <div class="left P-background-neutral7 G-flex-column G-justify-between">
          <div>
            <ul class="list">
              <li
                v-for="(i, index) in clients.showList"
                @click="clickList(index)"
                :key="index"
                :class="{
                  listActive: index === clients.activeTab,
                  'P-disable-tab': index > clients.disableTabs,
                }"
              >
                {{ i.name }}
              </li>
            </ul>
          </div>
          <ott-button-loader
            v-if="isAddClient"
            :disabled="clients.disableButton"
            :is-loading="clients.isDataLoading"
            @onClick="saveAndCloseData"
            button-text="Save and Close"
            className="primary-sm-btn"
          />
        </div>
        <div class="right">
          <div class="main">
            <PersonalInfo v-if="activeTab === 0" ref="clientPersonalInfo" />
            <Contact v-if="activeTab === 1" ref="clientContact" />
            <Address v-if="activeTab === 2" ref="clientAddress" />
            <PaymentMethodClients
              v-if="activeTab === 3"
              ref="clientsPaymentMethods"
            />
            <FinanceClient v-if="activeTab === 4" ref="clientFinance" />
            <BalanceCreditClient
              v-if="activeTab === 5"
              ref="clientBalanceCredit"
            />
            <LocationsClients v-if="activeTab === 6" ref="clientLocation" />
            <!-- <ProfileClient v-if="activeTab === 7" /> -->
            <UsedDevices v-if="activeTab === 7" />
            <PackagesClients v-if="activeTab === 8" ref="packagesClients" />

            <Transition name="fade">
              <v-alert
                v-if="isSaved && activeTab !== 8"
                dense
                text
                class="mt-5"
                type="success"
                style="width:max-content;"
              >
                The request has been saved successfully
              </v-alert>
            </Transition>
          </div>
          <div
            class="footer"
            v-if="showSaveBtn && isAddClient && activeTab !== 8"
          >
            <ott-button-loader
              @onClick="nextFunc"
              :is-loading="isLoading"
              :button-text="nextButton"
              :icon-after="!showPackagesPayBtn"
              className="primary-sm-btn P-login-btn"
            />
          </div>
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";
import ManageClientMixin from "./ManageClientMixin";
import PersonalInfo from "./personalInfo/PersonalInfo";
import Contact from "./contact/Contact";
import Address from "./address/Address";
import PaymentMethodClients from "./paymentMethods/PymentMethods";
import FinanceClient from "./finance/Finance";
import BalanceCreditClient from "./balanceCredit/BalanceCredit";
import LocationsClients from "./location/Location";
import UsedDevices from "./usedDevices/UsedDevices";
import ProfileClient from "./profile/Provile";
import PackagesClients from "./packages/Packages";

export default {
  components: {
    PackagesClients,
    ProfileClient,
    UsedDevices,
    LocationsClients,
    BalanceCreditClient,
    FinanceClient,
    PaymentMethodClients,
    Address,
    Contact,
    PersonalInfo,
    OttButton,
    OttDialog,
    OttButtonLoader,
  },
  mixins: [ManageClientMixin],
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.parent-add-provider-modal {
  display: flex;

  .left {
    min-width: 226px;
    padding: 18px 14px 24px;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    & .list {
      padding-left: 0;
      overflow-y: scroll;
      height: 618px;
      text-transform: capitalize;

      li + li {
        margin-top: 3px;
      }

      & > li {
        padding: 7px 11px;
        cursor: pointer;
        border-radius: 4px;
        font: normal normal 600 14px Segoe UI;
        white-space: nowrap;
        transition: $transition;

        &.P-disable-tab {
          color: $neutral-color;
          cursor: default;
        }

        &:hover:not(.P-disable-tab) {
          background-color: $neutral-color25;
          color: $secondary-color;
        }
      }
    }
  }

  .right {
    padding: 16px 24px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #a5aac6;
      margin: 24px -24px 0 -24px;
      padding: 24px 24px 0;
    }
    ::v-deep {
      .P-modal-component-header {
        margin-bottom: 0;
      }
    }
  }
}
</style>
