<template>
  <div class="P-modal-components">
    <TitleComponent title-text="Packages and Equipments" />
    <div v-if="selectedLocation && locationsList.length">
      <div class="G-flex G-justify-between G-align-center">
        <div
          @click="openEquipments"
          :class="{ 'activeEquipment': showEquipments }"
          class="equipments-button text-center"
        >
          Equipments
        </div>

        <OttLocationsList
          :selected-data="
            !this.showEquipments ? selectedLocation : null
          "
          :locations-list="locationsList"
          :width="tabsWidth !== 100 ? 330 : 512"
          @changeLocation="changeLocation"
        />
      </div>

      <PackagesList v-show="!showEquipments" />
      <Equipments v-if="showEquipments" />
    </div>

    <div v-if="!locationsList.length" class="text-center">
      <p>
        For showing packages data need to add location(s)
      </p>
    </div>

    <div
      v-if="!isOpenManagePackagesModal && 
            showPackagesPayBtn && 
            locationsList.length
          "
      class="footer"
    > 
      <ott-button-loader
        @onClick="openCheckout"
        :disabled="!allowCheckout"
        :button-text="packagesPayBtnLabel"
        :is-loading="isLoading"
        className="primary-sm-btn P-login-btn"
      />


    <!-- Location Comment Modal -->
    <ott-dialog
      v-if="openLocationCommentModal"
      :is-open="openLocationCommentModal"
      :show-footer="false"
      :width="600"
      modal-title="Add Location Comment"
      @outside="closeLocationCommentModal"
      @resetDataModal="closeLocationCommentModal"
    >
      <template #modalBody>
        <div class="P-location-comment-body pt-1 pb-5 mx-5">
          <p class="mb-1"><i>Please add a reason to cancel all packages</i></p>
          <quill-editor
            v-model="locationComment"
            :options="{ placeholder: 'Comment...' }"
            :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
          ></quill-editor>
          <save-reset-buttons
            right-label="Add Comment"
            :is-loading="isLoading"
            :right-click-func="() => setLoctionComment()"
            :left-click-func="() => closeLocationCommentModal()"
          />
        </div>
      </template>
    </ott-dialog>

    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { eventBus } from '@/main';
import PackagesList from "./PackagesList";
import Equipments from "./equipments/Equipments";
import TitleComponent from "@/components/TitleComponent";
import OttLocationsList from "@/components/OttLocationsList/OttLocationsList";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import SaveResetButtons from "@/components/SaveResetButtons";

export default {
  name: "PackagesClients",
  components: {
    PackagesList,
    Equipments,
    OttDialog,
    SaveResetButtons,
    OttLocationsList,
    TitleComponent,
    OttButton,
    OttButtonLoader,
  },

  data() {
    return {
      openLocationCommentModal: false,
      locationComment: null,
      showEquipments: false,
    };
  },

  computed: {
    ...mapState({
      invoiceData: (state) => state.checkoutClients.invoiceData,
      packagesClients: (state) => state.packagesClients,
      equipments: (state) => state.equipments,
      locationsList: (state) => state.locationClient.locationList,
      showSaveBtn: (state) => state.personalInfoClient.showSaveBtn,
      openPackagesFromManage: (state) => state.packagesClients.openPackagesFromManage,
      isOpenManagePackagesModal: (state) => state.clientsModule.isOpenManagePackagesModal,
      showPackagesPayBtn: (state) => state.packagesClients.showPayBtn,
      dataForPackages: (state) => state.packagesClients.dataForPackages,
      allowCheckout: (state) => state.packagesClients.allowCheckout,
      packagesPayBtnLabel: (state) => state.packagesClients.packagesPayBtnLabel,
      tabsWidth: (state) => state.generalSearch.tabsWidth,
      isLoading: (state) => state.appModule.isLoading
    }),

    selectedLocation() {
      return this.packagesClients.selectedLocation;
    },
  },

  watch: {
    locationsList: {
      handler(newList, oldList) {
        if (!oldList.length && newList.length) {
          this.changeLocation(newList[0]);
        }
      },
      deep: true
    }    
  },

  // async created() {
  //     // if (!this.packagesClients.selectedLocation) {
  //     //   await this.changeLocation(this.locationsList[0]);
  //     // }

  //     // if (
  //     //   !this.packagesClients.selectedLocation ||
  //     //   !Object.keys(this.packagesClients.selectedLocation).length
  //     // ) {
  //     //   await this.changeLocation(this.locationsList[0]);
  //     // }
  //   }
  // },

  async created() {
    if (this.$route.name !== 'Search') {
      await this.getLocations();
    }
    
    if ((this.locationsList.length && !this.openPackagesFromManage) || !this.selectedLocation) {
      this.changeLocation(this.locationsList[0]);
    }
    
    if (this.locationsList.length) {
      this.setData({
        roomsCountOld: this.locationsList[0].roomsCount,
        openPackagesFromManage: false
      });
    }
  },

  methods: {
    async setLoctionComment() {
      if (this.locationComment && this.locationComment.length) {
        this.openLocationCommentModal = false;
        await this.setCheckoutInvoice({
        ...this.dataForPackages,
      }).then(() => {
        // this.closeLocationCommentModal();
        this.openCheckoutModal()
      });
      }
    },
    closeLocationCommentModal() {
      this.openLocationCommentModal = false;
    },
    ...mapActions({
      getLocations: "locationClient/getLocations",
      setCheckoutInvoice: 'packagesClients/setCheckoutInvoice',
    }),

    ...mapMutations({
      setData: "packagesClients/setData",
      openCheckoutModal: 'checkoutClients/openModal',
    }),

    async openEquipments() {
      this.showEquipments = true;
    },

    /**
     * Select Location
     * **/
    changeLocation(location) {
      this.setData({
        selectedLocation: { ...location },
      });

      this.showEquipments = false;      

      this.setData({
        selectedLocation: { ...location },
      });

      eventBus.$emit("locationChanged");
    },

    async openCheckout() {
      if (this.packagesClients.isRefund && (!this.locationComment || this.locationComment.length)) {
        this.openLocationCommentModal = true;
      } else {
        await this.setCheckoutInvoice({
        ...this.dataForPackages,
      }).then(() => this.openCheckoutModal());
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";

.equipments-button {
  margin-right: 30px;
  margin-top: 8px;
  padding: 8px 16px;
  box-shadow: 0 0 5px #00000014;
  border-radius: 18px;
  cursor: pointer;
  transition: $transition;
  min-width: 150px;
  font-size: $txt14;
  font-weight: 600;
  transition: $transition;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover,
  &.activeEquipment {
    background-color: $primary-color;
    color: $white !important;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #a5aac6;
  margin: 24px 0;
  padding: 24px 24px 0;
}

</style>