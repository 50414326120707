<template>
    <div class="addresses-parent position-relative">
        <div class="addresses-cont pa-5 G-flex-center flex-column">
          <the-address
            :addressText="badAddressText"
            title="Bad Address" 
          />

          <div class="G-justify-between flex-column">
            <span class="circle mb-2"></span>
            <span class="circle mb-2"></span>
            <span class="circle"></span>
          </div>

          <the-address
            :addressText="verifiedAddressText"
            :show-button="true"
            @buttonClick="$emit('verfiedButtonClick')"
            title="Verified Address" 
          /> 
        </div>
      </div>
</template>

<script>
import TheAddress from "./TheAddress"

export default {
  name: 'AddressVerification',
  components: {
    TheAddress,
  },
  props: {
    address: {
      type: Object,
      default: () => null
    },
    badAddressText: {
      type: String,
      default: ''
    },
    verifiedAddressText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
  @import "src/assets/scss/variables";

  .addresses-parent {
    position: relative;
    min-height: 480px;

    position: relative; 
    min-height: 355px; 
    width: 260px;
  }

  .addresses-cont {
    width: auto;
    background-color: $light-blue-darker ;

    & .circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ffffffb8;
    }
  }
</style>