<template>
  <div class="P-manage-profile-block P-manage-client">
    <div class="P-manage-profile-box">
      <TitleComponent
        :title-text="profileClients.isEditMode ? 'Edit Profile' : 'Add Profile'"
      />
      <div class="P-upload-image-block G-flex G-align-center">
        <div
          class="P-image-crop-value"
          :class="{ 'P-active-image': formData.avatar }"
          :style="{
            backgroundImage: `url('${formData.avatar ? formData.avatar : ''}')`,
          }"
        >
          <svg v-if="!formData.avatar" viewBox="0 0 100 100">
            <circle
              cx="50"
              cy="50"
              r="48"
              stroke-dasharray="8, 10"
              fill="transparent"
              stroke-width="1"
            />
          </svg>
          <EmptyImageProfile v-if="!formData.avatar" />
          <ImageSettingsProfile
            v-else
            @changeImage="selectImage"
            @removeImage="removeImage"
          />
        </div>
        <div class="P-upload-image-label">
          <label>
            <input
              @change="uploadFile"
              ref="uploadFile"
              type="file"
              accept=".png, .jpeg, .jpg"
            />
            <ott-button
              v-if="!formData.avatar"
              :click="selectImage"
              icon-before
              text="Upload A Photo"
              icon-before-class="P-icon-printer icon-tray-arrow-up"
              custom-class="neutral-sm-btn P-upload-photo-btn"
            />
          </label>
        </div>
      </div>
    </div>
    <div class="P-manage-profile-box">
      <TitleComponent title-text="Basic Information" />
      <div class="P-manage-inputs G-flex-wrap">
        <div class="P-padding-33">
          <OttInput
            v-model="nickname"
            :error="$v.formData.nickname.$error"
            label="Nickname"
          />
        </div>
        <div class="P-padding-33">
          <OttInput
            v-model="protectCode"
            label="Protect Code"
            :is-percent="true"
            :maxLength="6"
            :customInputClass="'P-protect-code'"
            :error="isValidProtectCode || $v.formData.protectCode.$error"
            @emitFunc="validateProtectCode"
          />
        </div>
        <div class="P-padding-33">
          <ott-select
            v-model="language"
            :items="profileClients.languageList"
            :multiple="false"
            :persistentHint="true"
            :error="$v.formData.language.$error"
            label="Language"
            selected-text="name"
          />
        </div>
        <div class="P-padding-33">
          <ott-select
            v-model="inactivityTimeout"
            :items="profileClients.inactivityTimeoutList"
            :multiple="false"
            :persistentHint="true"
            :error="$v.formData.inactivityTimeout.$error"
            selected-text="name"
            label="Inactivity Timeout"
          />
        </div>
        <div class="P-padding-33">
          <ott-select
            v-model="timeShift"
            :items="profileClients.timeShiftList"
            :multiple="false"
            :persistentHint="true"
            :error="$v.formData.timeShift.$error"
            label="Timeshift"
            selected-text="name"
          />
        </div>
        <div class="P-padding-33">
          <ott-select
            v-model="audioTrackDefault"
            :items="profileClients.audioTrackDefaultList"
            :multiple="false"
            :persistentHint="true"
            :error="$v.formData.audioTrackDefault.$error"
            label="Audiotrack Default"
            selected-text="name"
          />
        </div>
        <div class="P-padding-33">
          <ott-select
            v-model="channelsOrderMode"
            :items="profileClients.channelsOrderModeList"
            :multiple="false"
            :persistentHint="true"
            :error="$v.formData.channelsOrderMode.$error"
            label="Channels Order Mode"
            selected-text="name"
          />
        </div>
      </div>
      <div class="P-checkbox G-align-center">
        <ott-checkbox label="Enable Profile" v-model="isEnableProfile" />
        <ott-checkbox label="Is Admin" v-model="isAdmin" />
        <ott-checkbox label="Protect Boot" v-model="protectBoot" />
        <ott-checkbox label="Protect Setting" v-model="protectSetting" />
      </div>
    </div>
    <div class="P-manage-profile-box">
      <TitleComponent title-text="Parental Control" />
      <div class="P-manage-inputs">
        <div class="P-padding-33">
          <ott-select
            v-model="ageGroup"
            :items="profileClients.ageGroupList"
            :multiple="false"
            :persistentHint="true"
            :error="$v.formData.ageGroup.$error"
            label="Age Group"
            selected-text="name"
          />
        </div>
      </div>
      <div class="P-checkbox G-align-center">
        <ott-checkbox
          v-model="protectMediaByAge"
          label="Protect Media By Age"
        />
        <ott-checkbox label="Hide Media By Age" v-model="hideMediaByAge" />
      </div>
    </div>
    <div class="P-manage-profile-box">
      <ManageVod />
    </div>
    <SaveResetButtons
      :is-loading="isLoading"
      :left-click-func="addProfile"
      :right-click-func="resetData"
      right-label="Cancel"
    />

    <CropAndUploadImageProfile
      v-if="uploadImageModal.modal.isOpen"
      @removeImage="removeImage"
    />
  </div>
</template>
<script>
import ManageProfileMixin from "./ManageProfileMixin";
import TitleComponent from "@/components/TitleComponent";
import EmptyImageProfile from "./EmptyImage";
import ImageSettingsProfile from "./ImageSettings";
import CropAndUploadImageProfile from "./UploadImageModal";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import ManageVod from "./ManageVod";
import SaveResetButtons from "@/components/SaveResetButtons";

export default {
  name: "ManageProfile",
  components: {
    SaveResetButtons,
    ManageVod,
    OttCheckbox,
    OttInput,
    OttSelect,
    OttButton,
    CropAndUploadImageProfile,
    ImageSettingsProfile,
    EmptyImageProfile,
    TitleComponent,
  },
  mixins: [ManageProfileMixin],
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.P-image-crop-value {
  width: 145px;
  height: 145px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  margin-right: 40px;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    stroke: $neutral-color;
  }

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(23, 43, 77, 0.44);
    transition: $transition;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
  }

  &.P-active-image {
    cursor: pointer;
  }

  &.P-active-image:hover:before {
    opacity: 1;
    visibility: visible;
  }
}

.theme--dark {
  .P-image-crop-value {
    svg {
      stroke: $dark-gray;
    }
  }
}

.P-image-crop-value::v-deep {
  &:hover {
    .select-all-menu-cont {
      opacity: 1;
      visibility: visible;
    }
  }
}

.P-upload-image-label {
  input {
    display: none;
  }
}

.P-error {
  stroke: $danger-color-dark !important;
}

.P-upload-image-block {
  margin-top: 12px;
}

.P-manage-inputs {
  margin: 0 -10px;
}

.P-checkbox {
  .Ott-checkbox-cont + .Ott-checkbox-cont {
    margin-left: 20px;
  }
}

.P-manage-profile-box {
  & + .P-manage-profile-box {
    margin-top: 16px;
  }
  .P-checkbox {
    margin: 8px -2px 0;
  }
}

.P-manage-profile-block {
  padding-bottom: 35px;
}
</style>
