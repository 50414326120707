import { mapState, mapMutations, mapActions } from "vuex";

export default {
  
  computed: {
    ...mapState({
      usedDevicesClient: state => state.usedDevicesClient,
      clientId: state => state.clientsModule.clientId,
      locationsList: state => state.locationClient.locationList,
      tabsWidth: state => state.generalSearch.tabsWidth,
    }),

    showDevicesList() {
      if (this.usedDevicesClient.selectedLocation &&
        this.usedDevicesClient.usedDevices.length) {
          return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    locationsList: {
      handler(newList, oldList) {
        if (!oldList.length && newList.length) {
          this.changeLocation(newList[0]);
        }
      },
      deep: true
    }    
  },

  async created() {
    if (this.clientId && this.$route.name !== 'Search') {
      await Promise.all([
        this.getLocations(),
        this.changeLocation(this.locationsList[0])
      ]) 
    }

    await this.getUsedDevicesOptions();
  },

  methods: {
    ...mapMutations({
      setData: "usedDevicesClient/setData"
    }),

    ...mapActions({
      getUsedDevicesOptions: "usedDevicesClient/getUsedDevicesOptions",
      getLocations: "locationClient/getLocations",
      getUsedDevices: "usedDevicesClient/getUsedDevices"
    }),

    /**
     * Set Location, change location
     * **/

    async changeLocation(data) {
      let selectedLocationIndex = this.locationsList.findIndex(item => data === item);

      this.setData({
        selectedLocation: data,
        initialSelectedLocation: data,
        selectedDeviceIndex: null,
        selectedLocationIndex
      })

      await this.getUsedDevices({ syncCdn: true});
    },
  },
}