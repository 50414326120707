import { mapState, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      isValidProtectCode: false,
    }
  },

  validations: {
    formData: {
      nickname: {required},
      protectCode: {required},
      language: {required},
      inactivityTimeout: {required},
      timeShift: {required},
      audioTrackDefault: {required},
      channelsOrderMode: {required},
      ageGroup: {required},
    }
  },

  computed: {
    ...mapState({
      profileClients: state => state.profileClients,
      formData: state => state.profileClients.formData,
      uploadImageModal: state => state.uploadImageModal,
      isLoading: state => state.appModule.isLoading
    }),
    nickname: {
      get() {
        return this.formData.nickname
      },
      set(value) {
        this.setNikName(value)
      }
    },
    protectCode: {
      get() {
        return this.formData.protectCode
      },
      set(value) {
        this.setProtectCode(value)
      }
    },
    language: {
      get() {
        return this.formData.language
      },
      set(value) {
        this.setLanguage(value)
      }
    },

    inactivityTimeout: {
      get() {
        return this.formData.inactivityTimeout
      },
      set(value) {
        this.setInactivityTimeout(value)
      }
    },
    timeShift: {
      get() {
        return this.formData.timeShift
      },
      set(value) {
        this.setTimeShift(value)
      }
    },
    audioTrackDefault: {
      get() {
        return this.formData.audioTrackDefault
      },
      set(value) {
        this.setAudioTrackDefault(value)
      }
    },
    channelsOrderMode: {
      get() {
        return this.formData.channelsOrderMode
      },
      set(value) {
        this.setChannelsOrderMode(value)
      }
    },
    isEnableProfile: {
      get() {
        return this.formData.isEnableProfile
      },
      set(value) {
        this.setIsEnableProfile(value)
      }
    },
    isAdmin: {
      get() {
        return this.formData.isAdmin
      },
      set(value) {
        this.setIsAdmin(value)
      }
    },
    protectBoot: {
      get() {
        return this.formData.protectBoot
      },
      set(value) {
        this.setProtectBoot(value)
      }
    },
    protectSetting: {
      get() {
        return this.formData.protectSetting
      },
      set(value) {
        this.setProtectSetting(value)
      }
    },
    ageGroup: {
      get() {
        return this.formData.ageGroup
      },
      set(value) {
        this.setAgeGroup(value)
      }
    },

    protectMediaByAge: {
      get() {
        return this.formData.protectMediaByAge
      },
      set(value) {
        this.setProtectMediaByAge(value)
      }
    },
    hideMediaByAge: {
      get() {
        return this.formData.hideMediaByAge
      },
      set(value) {
        this.setHideMediaByAge(value)
      }
    },

  },

  methods: {
    ...mapMutations({
      setImagePath: 'profileClients/setImagePath',
      openModal: 'uploadImageModal/openModal',
      setImageData: 'profileClients/setImageData',
      setNikName: 'profileClients/setNikName',
      setProtectCode: 'profileClients/setProtectCode',
      setLanguage: 'profileClients/setLanguage',
      setInactivityTimeout: 'profileClients/setInactivityTimeout',
      setTimeShift: 'profileClients/setTimeShift',
      setAudioTrackDefault: 'profileClients/setAudioTrackDefault',
      setChannelsOrderMode: 'profileClients/setChannelsOrderMode',
      setIsEnableProfile: 'profileClients/setIsEnableProfile',
      setIsAdmin: 'profileClients/setIsAdmin',
      setProtectBoot: 'profileClients/setProtectBoot',
      setProtectSetting: 'profileClients/setProtectSetting',
      setAgeGroup: 'profileClients/setAgeGroup',
      setProtectMediaByAge: 'profileClients/setProtectMediaByAge',
      setHideMediaByAge: 'profileClients/setHideMediaByAge',
      setIsAddMode: 'profileClients/setIsAddMode'
    }),
    /**
     * Validate  protect code min and max length 6 digits
     * **/

    validateProtectCode() {
      this.isValidProtectCode = !(this.protectCode.toString().length === 6)
    },

    /**
     * Add  profile data
     * **/
    addProfile() {
      this.$v.formData.$touch()
      if (!this.$v.formData.$error) {
        console.log(this.formData, 'Profile add  data')
      }
    },

    /**
     * Reset Data
     * **/

    resetData() {
      if (!this.profileClients.isEditMode) {
        this.setIsAddMode(false)
      }
    },

    /**
     * select image from PC
     * **/
    selectImage() {
      setTimeout(() => {
        this.$refs["uploadFile"].click();
      }, 0)
    },

    /**
     * Upload image
     * **/
    uploadFile(data) {
      let reader = new FileReader();
      reader.onload = e => {
        this.setImagePath(e.target.result);
        this.openModal()
        this.setImageData(data.target.files[0]);

      };
      reader.readAsDataURL(data.target.files[0]);
    },
    /**
     * Remove Image
     * **/

    removeImage() {
      this.$refs["uploadFile"].value = null
      this.setImagePath('')
    },

  },
}
