import { mapActions, mapMutations, mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import { eventBus } from "@/main";
import allCountry from "@/constants/all-countries";
import _ from "lodash";

export default {
  data() {
    return {
      phoneList: [],
      countryList: allCountry,
      errorMessage: '',
      isShowImage: false,
      selectedImageSrc: null,
      selectedAddressImage: null
    }
  },
  validations: {
    formData: {
      firstname: {
        required
      },
      lastname: {
        required
      },
      address: {
        required
      },
      country: {
        required
      },
      city: {
        required
      },
      zip: {
        required
      },
      province: {
        required
      },
      phone: {
        required: requiredIf(function () {
          return this.contact.allPhones.length;
        }),
      }
    }
  },
  computed: {
    ...mapState({
      addressClient: state => state.addressClient,
      isEditMode: state => state.addressClient.isEditMode,
      personalInfo: state => state.personalInfoClient,
      contact: state => state.contactClient,
      formData: state => state.addressClient.formData,
      allAddresses: state => state.addressClient.allAddresses,
      clientId: state => state.clientsModule.clientId,
      billingAddressList: state => state.addressClient.billingAddressList,
      showSaveBtn: state => state.personalInfoClient.showSaveBtn,
      isAddClient: state => state.clientsModule.isAddClient,
      isLoading: state => state.appModule.isLoading,
    }),
    
    isExistShippingAddress() {
      return this.allAddresses.some((item) => item.isShipping)
    },

    isExistBillingAddress() {
      return this.allAddresses.some((item) => item.isBilling)
    },

    firstname: {
      get() {
        return this.formData.firstname
      },
      set(firstname) {
        this.setFormData({ firstname })
      }
    },
    lastname: {
      get() {
        return this.formData.lastname
      },
      set(lastname) {
        this.setFormData({ lastname })
      }
    },
    phone: {
      get() {
        return this.formData.phone
      },
      set(phone) {
        this.setFormData({ phone })
      }
    },
    address: {
      get() {
        return this.formData.address
      },
      set(address) {
        this.setFormData({ address })
      }
    },
    country: {
      get() {
        return this.formData.country
      },
      set(country) {
        this.setFormData({ country })
      }
    },
    city: {
      get() {
        return this.formData.city
      },
      set(city) {
        this.setFormData({ city })
      }
    },
    province: {
      get() {
        return this.formData.province
      },
      set(province) {
        this.setFormData({ province })
      }
    },
    suite: {
      get() {
        return this.formData.suite
      },
      set(suite) {
        this.setFormData({ suite })
      }
    },
    zip: {
      get() {
        return this.formData.zip
      },
      set(zip) {
        this.setFormData({ zip })
      }
    },
    forContactInvoice: {
      get() {
        return this.formData.forContactInvoice
      },
      set(forContactInvoice) {
        this.setFormData({ forContactInvoice })
      }
    },
    isBilling: {
      get() {
        return this.formData.isBilling
      },
      set(isBilling) {
        this.setFormData({ isBilling })
      }
    },
    isShipping: {
      get() {
        return this.formData.isShipping
      },
      set(isShipping) {
        this.setFormData({ isShipping })
      }
    },
    isResident: {
      get() {
        return this.formData.isResident
      },
      set(isResident) {
        this.setFormData({ isResident })
      }
    },
    isExistingName: {
      get() {
        return this.addressClient.isExistingName
      },
      set(isExistingName) {
        this.setData({ isExistingName })
      }
    }
  },

  watch: {
    'contact.allPhones'(allPhones) {
      this.phoneList = []
      if (allPhones.length) {
        allPhones.map(item => {
          this.phoneList.push({
            name: item.phone,
            value: item.phone
          })
        })
      }
    }
  },

  created() {
    this.errorMessage = ''
    this.setShowSaveBtn(false)
    if (this.contact.allPhones.length) {
      this.contact.allPhones.map(item => {
        this.phoneList.push({
          name: item.phone,
          value: item.phone
        })
      })
    }
    if (this.addressClient.allAddresses && this.addressClient.allAddresses.length) {
      this.setShowAddressList(true)
    }
  },
  destroyed() {
    this.setShowSaveBtn(true)
    this.selectedAddressImage = null
  },
  methods: {
    ...mapActions({
      addAddress: 'addressClient/addContactAddress',
      fetchAddress: 'addressClient/fetchAddress',
      getAddressImage: 'addressClient/getAddressImage'
    }),

    ...mapMutations({
      setData: 'addressClient/setData',
      setFormData: 'addressClient/setFormData',
      checkForContactAddress: 'addressClient/checkForContactAddress',
      setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      setShowAddressList: 'addressClient/setShowAddressList',
      setIsEditMode: 'addressClient/setIsEditMode',
      setIsAddMode: 'addressClient/setIsAddMode',
      resetAddressMutation: 'addressClient/resetAddress',
    }),

    addressImageClick() {
      if (this.selectedAddressImage) {
        this.selectedImageSrc = this.selectedAddressImage
      } 
      
      if (!this.selectedAddressImage && this.formData?.image) {
        this.selectedImageSrc = 'data:image/jpeg;base64,'+this.formData?.image
      }
      this.isShowImage = true
    },

    closeImageModal() {
      this.selectedImageSrc = null
      this.isShowImage = false
    },

    /**
     * set is existing personal  name
     * **/

    setPersonalExistingName() {
      if (this.addressClient.isExistingName) {
        this.addressClient.formData.firstname = this.personalInfo.formData.firstname
        this.addressClient.formData.lastname = this.personalInfo.formData.lastname
      }  else {
        this.setFormData({
          firstname: '',
          lastname: ''
        })
      }
    },

    /**
     * Add Address
     * **/

    async saveData() {
      this.errorMessage = ''
      this.$v.formData.$touch()
      const address = {
        existingName: this.addressClient.isExistingName,
        firstname: !this.addressClient.isExistingName ? this.formData.firstname : this.personalInfo.formData.firstname,
        lastname: !this.addressClient.isExistingName ? this.formData.lastname : this.personalInfo.formData.lastname,
        phone: this.formData.phone,
        address: this.formData.address,
        country: this.formData.country,
        city: this.formData.city,
        province: this.formData.province,
        suite: this.formData.suite,
        zip: this.formData.zip,
        isBilling: this.formData.isBilling,
        isShipping: this.formData.isShipping,
        isResident: this.formData.isResident,
        forContactInvoice: this.formData.forContactInvoice,
      }

      if (this.formData.lat) {
        address.lat = this.formData.lat
      }

      if (this.formData.long) {
        address.long = this.formData.long
      }

      if (!this.$v.formData.$error && this.contact.allPhones.length) {
        this.checkForContactAddress(this.formData)
        const addresses = this.allAddresses
        addresses.push(address)
        await this.addAddress({addresses})
        eventBus.$emit('addressAdded')
        this.resetAddress()
        return Promise.resolve(true)
      }

      if (!this.contact.allPhones.length) {
        this.errorMessage = 'The client has not a phone number, please add phone number before adding a address...'
      }
    },


    /**
     * Edit Address
     * **/

    async editData() {
      this.$v.formData.$touch()
      if (!this.$v.formData.$errorMessage) {
        this.checkForContactAddress(this.formData)
        let addresses = _.clone(this.allAddresses)
        let addressIndex = {}
        
        addresses.forEach((item, index) => {
          if (index === this.addressClient.selectedAddressIndex) {
            item.existingName = this.addressClient.isExistingName
            item.firstname = this.formData.firstname
            item.lastname = this.formData.lastname
            item.phone = this.formData.phone
            item.address = this.formData.address
            item.country = this.formData.country
            item.city = this.formData.city
            item.province = this.formData.province
            item.suite = this.formData.suite
            item.zip = this.formData.zip
            item.isBilling = this.formData.isBilling
            item.isShipping = this.formData.isShipping
            item.isResident = this.formData.isResident
            item.forContactInvoice = this.formData.forContactInvoice
            
            if (this.formData.lat) {
              item.lat = this.formData.lat
            }
            
            if (this.formData.long) {
              item.long = this.formData.long
            }

            addressIndex = index
          }
        })

        await this.addAddress({addresses})
        this.resetAddress()
        eventBus.$emit('addressUpdated', addressIndex)
      }
    },

    resetAddress() {
      this.setIsEditMode(false)
      this.setIsAddMode(false)
      this.resetAddressMutation()
      this.setShowSaveBtn(true)

      if (!this.allAddresses.length) {          
        this.setData({ showComponents: false })
      }
    },


    /**
     * get address title
     * default - Address
     * IsAddMode - Add Addresses
     * IsEditMode - EditAddress
     * **/

    getAddressTitle() {
      let addressTitle = 'Address';
      if (this.addressClient.isEditMode) {
        addressTitle = 'Edit Addresses'
      }
      if (this.addressClient.isAddMode) {
        addressTitle = 'Add Address'
      }
      return addressTitle
    },

    /**
     * Update address autocomplete SmartStreet
     * **/

    async updateAddress(addressData) {
      let address = `${addressData?.fullAddress} ${addressData?.suite || ''} ${addressData?.city || ''} ${addressData?.province || ''} ${addressData?.zipCode || ''}`
  
      const imageResult = await this.getAddressImage({
        address,
        lat: addressData?.lat,
        long: addressData?.long
      })
      
      if (imageResult) {
        this.selectedAddressImage =  'data:image/jpeg;base64,'+imageResult?.image
      }

      let countryValue = allCountry.filter(x => x.name === addressData.country)[0].value
      this.setFormData({
        zip: addressData.zipCode,
        suite: addressData.suite,
        province: addressData.province,
        city: addressData.city,
        country: countryValue,
        address: addressData.fullAddress,
        lat: addressData.lat,
        long: addressData.long
      })
    },

    saveAddress() {
      const [address] = arguments;
      this.setFormData({ address });
    },

    /**
     * Only letters and spaces - regex
     * **/
    onlyLetters(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[a-zA-Z\s]*$/.test(char)) return true;
      else event.preventDefault();
    }
  }, 
}