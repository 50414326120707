<template>
  <div class="P-modal-components">
    <TitleComponent title-text="Personal Info" />
    <div
      v-if="isAddClient || showForm"
      class="P-modal-component-body"
    >
      <div class="P-personal-info P-manage-client G-flex-wrap">
        <div class="P-padding-50">
          <ott-input
            v-model="firstname"
            :error="$v.formData.firstname.$error"
            :disabled="disableFormItems"
            @emitKeypress="onlyLetters($event)"
            label="First Name*"
          />
        </div>
        <div class="P-padding-50">
          <ott-input
            v-model="lastname"
            :error="$v.formData.lastname.$error"
            :disabled="disableFormItems"
            @emitKeypress="onlyLetters($event)"
            label="Last Name*"
          />
        </div>
        <div class="G-align-center P-width-100">
          <div class="P-padding-50 G-date-picker">
            <DatePicker
              v-model="dateOfBirth"
              :disabled="disableFormItems"
              :masks="{ input: ottDateFormat }"
              :max-date="newDateWithMainUserTZ"
              :popover="{ visibility: 'click' }"
              :is-dark="$vuetify.theme.dark"
              locale="en"
              class="ott-date-picker"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div class="ott-date-picker-cont">
                  <v-text-field
                    v-model="inputValue"
                    v-on="inputEvents"
                    :disabled="disableFormItems"
                    :readonly="true"
                    outlined
                    dense
                    height="38px"
                    label="Date of birth"
                    class=""
                    append-icon="mdi mdi-calendar-blank-outline neutral--text"
                  />
                </div>
              </template>
            </DatePicker>
          </div>
          <div class="P-padding-50">
            <v-radio-group v-model="gender" class="G-radio-group">
              <div class="G-flex">
                <div class="P-radio-box">
                  <OttRadio
                    v-model="personalInfoClient.genderType.male"
                    label="Male"
                  />
                </div>
                <div class="P-radio-box">
                  <OttRadio
                    label="Female"
                    v-model="personalInfoClient.genderType.female"
                  />
                </div>
                <div class="P-radio-box">
                  <OttRadio
                    v-model="personalInfoClient.genderType.other"
                    label="Other"
                  />
                </div>
              </div>
            </v-radio-group>
          </div>
        </div>

        <div class="P-padding-50">
          <ott-select
            v-model="clientProvider"
            :disabled="disableFormItems"
            :items="subProviders"
            :error="$v.formData.provider.$error"
            :clear="true"
            selected-text="name"
            label="Select Provider*"
          />
        </div>
          <!-- @emitFunc="selectReseller(providerId)" -->
        <!-- <div class="P-checkbox G-align-center">
          <ott-checkbox label="Block client" v-model="isBlocked"/>
          <ott-checkbox label="Pause all subscriptions" v-model="inPaused"/>
        </div> -->
      </div>
      <div class="P-save-btn-block" v-if="!isAddClient">
        <ott-button-loader
          :is-loading="isLoading"
          @onClick="saveData"
          button-text="Save"
          class-name="primary-sm-btn"
        />
      </div>
    </div>

    <div v-else class="P-table-block">
      <div class="P-table-header">
        <ul class="G-flex">
          <li
            class="secondary--text"
            v-for="(item, key) of tableHeaders"
            :key="key"
          >
            {{ item }}
          </li>
          <li></li>
        </ul>
      </div>
      <div class="P-table-body">
        <ul class="G-flex">
          <li>{{ firstname }}</li>
          <li>{{ lastname }}</li>
          <li>{{ getDate($moment(dateOfBirth)) }}</li>
          <li>{{ gender }}</li>
          <li class="G-align-center">
            <div class="P-actions">
              <v-menu left content-class="P-actions-list">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showForm = true" class="P-list">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import PersonalInfoMixin from "./PersonalInfoMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";

// components
import OttInput from "@/components/vuetifyComponents/OttInput";
import DatePicker from "v-calendar/src/components/DatePicker";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import TitleComponent from "@/components/TitleComponent";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "PersonalInfo",
  components: {
    OttButtonLoader,
    TitleComponent,
    OttCheckbox,
    OttTextarea,
    OttSelect,
    OttRadio,
    OttInput,
    DatePicker,
  },
  mixins: [DateFormatMixin, PersonalInfoMixin, ConvertTimeZoneMixin],
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/formStyles";
@import "src/views/clients/modals/manageClient/simpleTable";

.P-personal-info {
  margin: 0 -10px;
}

.P-full-line {
  margin-top: 7px;
}

.P-width-100 {
  width: 100%;
}

.P-radio-box::v-deep {
  .v-label {
    top: -1px !important;
  }
}

.P-radio-box {
  margin-right: 22px;
  &:first-child {
    margin-left: -5px;
  }
}

.P-checkbox {
  padding: 4px 8px 0;

  .Ott-checkbox-cont + .Ott-checkbox-cont {
    margin-left: 20px;
  }
}

.P-success-message {
  margin-top: 24px;
}

::v-deep {
  .Ott-checkbox-cont .v-label {
    text-transform: inherit !important;
  }
}
</style>
