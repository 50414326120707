import { mapActions, mapMutations, mapState } from "vuex";
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators"
import { eventBus } from "@/main"

export default {
  props: {
    isCheckout: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      isMainCheck:false,
      error:false,
      errorMessage:''
    }
  },
  validations: {
    bankTransfer: {
      bankName: {required},
      routingNumber: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(9)
      },
      accountNumber: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(12)
      },
      companyName: {
        required: requiredIf(function () {
          return (
            this.bankTransfer.type===this.paymentMethod.bankAccountType.business
          );
        })
      },
      firstname: {
        required: requiredIf(function () {
          return (
            this.bankTransfer.type===this.paymentMethod.bankAccountType.personal
          );
        })
      },
      lastname: {
        required: requiredIf(function () {
          return (
            this.bankTransfer.type===this.paymentMethod.bankAccountType.personal
          );
        })
      },
      nickname: {
        required: requiredIf(function () {
          return (
            this.bankTransfer.type===this.paymentMethod.bankAccountType.personal
          );
        })
      },
    },
  },
  computed: {
    ...mapState({
      paymentMethod: state => state.paymentMethodClients,
      bankTransfer: state => state.paymentMethodClients.bankTransfer,
      personalInfoClient: state => state.personalInfoClient,
      clientId: state => state.clientsModule.clientId,
      paymentMethodId: state => state.paymentMethodClients.paymentMethodId,
      bankAccount: state => state.paymentMethodClients.bankAccount,
      showSaveBtn: state => state.personalInfoClient.showSaveBtn,
      paymentList: state => state.paymentMethodClients.paymentList,
      isAddClient: state => state.clientsModule.isAddClient,
      isLoading: state => state.appModule.isLoading
    }),
    bankName: {
      get() {
        return this.paymentMethod.bankTransfer.bankName
      },
      set(value) {
        this.setBankName(value)
      }
    },
    routingNumber: {
      get() {
        return this.paymentMethod.bankTransfer.routingNumber
      },
      set(value) {
        this.setRoutingNumber(value)
      }
    },
    accountNumber: {
      get() {
        return this.paymentMethod.bankTransfer.accountNumber
      },
      set(value) {
        this.setAccountNumber(value)
      }
    },
    companyName: {
      get() {
        return this.paymentMethod.bankTransfer.companyName
      },
      set(value) {
        this.setCompanyName(value)
      }
    },
    firstname: {
      get() {
        return this.paymentMethod.bankTransfer.firstname
      },
      set(value) {
        this.setFirstName(value)
      }
    },
    lastname: {
      get() {
        return this.paymentMethod.bankTransfer.lastname
      },
      set(value) {
        this.setLastName(value)
      }
    },
    nickname: {
      get() {
        return this.paymentMethod.bankTransfer.nickname
      },
      set(value) {
        this.setNickName(value)
      }
    },
    type: {
      get() {
        return this.paymentMethod.bankTransfer.type
      },
      set(value) {
        this.setType(value)
      }
    },    
  },

  mounted() {
    this.isMainCheck = this.getCurrentData()
    this.getSomeData()
  },

  methods: {
    ...mapActions({
      addPaymentMethod: 'paymentMethodClients/addPaymentMethod',
      editPaymentMethod: 'paymentMethodClients/editPaymentMethod',
      getPaymentMethod: 'paymentMethodClients/getPaymentMethod'
    }),
    ...mapMutations({
      setData: 'paymentMethodClients/setData',
      resetPaymentMethod: 'paymentMethodClients/resetPaymentMethod',     
      setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      setBankName: 'paymentMethodClients/setBankName',
      setRoutingNumber: 'paymentMethodClients/setRoutingNumber',
      setAccountNumber: 'paymentMethodClients/setAccountNumber',
      setCompanyName: 'paymentMethodClients/setCompanyName',
      setFirstName: 'paymentMethodClients/setFirstName',
      setLastName: 'paymentMethodClients/setLastName',
      setNickName: 'paymentMethodClients/setNickName',
      setType: 'paymentMethodClients/setType',
      setIsDefault: 'paymentMethodClients/setIsDefault',
      paymentMethodClients: 'paymentMethodClients/closeAddEditPaymentData'
    }),
    /**
     * Add bank account
     * **/
    async saveData() {
      this.$v.bankTransfer.$touch()
      if (!this.$v.bankTransfer.$error) {
        this.error = false;
        this.errorMessage = ''

        const data = {
          clientId: this.clientId,
          paymentMethod: 1,
          bankTransfer: {
            bankName: this.bankTransfer.bankName,
            routingNumber: this.bankTransfer.routingNumber,
            accountNumber: this.bankTransfer.accountNumber,
            account: this.bankTransfer.type,
          }
        }

        if (this.bankTransfer.type === 'personalAccount') {
          data.bankTransfer.personalData = {
            firstname: this.bankTransfer.firstname,
            lastname: this.bankTransfer.lastname,
            nickname: this.bankTransfer.nickname
          }
        } else {
          data.bankTransfer.companyName = this.bankTransfer.companyName
        }

        this.paymentList.push(data)

        try {
          await this.addPaymentMethod(data)
        } catch(e) {
          if (e.response.status >= 300) {
            this.error = true;
            this.errorMessage = e.response.data.message
          }
        }
        
        if (!this.error) {
          await this.getPaymentMethod({clientId: this.clientId})
          this.resetPaymentMethod()
          return Promise.resolve(true)
        }
      }
      // this.checkIsDefault()
      // this.$store.commit('paymentMethodClients/addPaymentBankAccount')
      // this.$store.commit('paymentMethodClients/closeAddPayment')
    },

    /**
     * Edit bank account
     * **/
    async editData() {
      this.$v.bankTransfer.$touch()
      if (!this.$v.bankTransfer.$error) {
        this.error = false;
        this.errorMessage = '';
        
        const paymentMethodId = this.bankAccount.id
        const data = {
           paymentMethod: 1,
           bankTransfer: {
              bankName: this.bankTransfer.bankName,
              routingNumber: this.bankTransfer.routingNumber,
              accountNumber: this.bankTransfer.accountNumber,
              account: this.bankTransfer.type
          }
        }

        if (this.bankTransfer.type === 'personalAccount') {
          data.bankTransfer.personalData = {
            firstname: this.bankTransfer.firstname,
            lastname: this.bankTransfer.lastname,
            nickname: this.bankTransfer.nickname
          }
        } else {
          data.bankTransfer.companyName = this.bankTransfer.companyName
        }

        try {
          await this.editPaymentMethod({paymentMethodId, data})

          if (this.$route.name === 'Search') {
            eventBus.$emit("paymentMethodUpdated");
          }
        } catch(e) {
          if (e.response.status >= 300) {
            this.error = true;
            this.errorMessage = e.response.data.message
          }
        }

        await this.getPaymentMethod({clientId: this.clientId})
        this.resetPaymentMethod()
      }
    },


    /**
     * Check is Default payment method
     * when added in first time
     * **/

    checkIsDefault() {
      if (!this.paymentMethod.paymentList.length) {
        this.setIsDefault(true)
      }
    },

    resetData() {
      this.paymentMethodClients()
      this.setShowSaveBtn(true)

      if (!this.paymentList.length) {
        this.setData({ showComponents: false })
      }
    },

    /**
     * get current  data for check inMain
     * **/
    getCurrentData() {
      let currentIndex = false
      if (!this.paymentMethod.addMode && this.paymentMethod.paymentList) {
        this.paymentMethod.paymentList.forEach((item, index) => {
          if (item.id === this.paymentMethod.creditCard.id) {
            currentIndex = item.default
          }
        })
      }
      return currentIndex
    },

    /**
     * Get company name, first name anf last  name data
     * **/

    getSomeData() {
      if (this.personalInfoClient.formData.firstname &&
        !this.paymentMethod.editMode
      ) {
        this.setFirstName(this.personalInfoClient.formData.firstname)
      }
      if (this.personalInfoClient.formData.lastname &&
        !this.paymentMethod.editMode
      ) {
        this.setLastName(this.personalInfoClient.formData.lastname)
      }
    },

    /**
     * Only letters and spaces - regex
     * **/
    onlyLetters(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[0-9*#+-/=~․ժ։;`!@$%^&()_?><:"}{|\\]+$/.test(char)) {
        return event.preventDefault();
      } else {
        return true;
      }
    }
  }
}