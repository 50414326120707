<template>
  <div class="P-no-image G-flex-column G-align-center G-justify-center">
    <span class="icon-image-multiple-outline"/>
    <p>No Image</p>
  </div>
</template>
<script>

  export default {
    name: 'EmptyImageProfile',
  }
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables";

  .P-no-image {
    p {
      margin-bottom : 0;
      font-size     : $txt12;
      color         : $neutral-color;
      font-weight   : $semiBold;
      margin-top    : 10px;
    }

    span {
      color     : $neutral-color;
      font-size : 28px;
    }
  }

  .theme--dark {
    .P-no-image {
      p, span {
        color : $dark-gray;
      }
    }
  }
</style>