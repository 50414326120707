<template>
  <div class="'P-contact-list-block">
    <div class="P-contact-list-box">
      <TitleComponent title-text="Phone Numbers" :is-add="true" :click="addPhone"/>
      <div class="P-table-block">
        <div class="P-table-header">
          <ul class="G-flex">
            <li v-for="(item, index) of phoneHeader" :key="index" class="secondary--text">{{ item }}</li>
            <li></li>
          </ul>
        </div>
        <div class="P-table-body">
          <ul class="G-justify-center" v-for="(item, index) of allPhones" :key="index">
            <li><p class="secondary--text">{{ item.phone }}</p></li>
            <li><p class="secondary--text">{{ item?.name }}</p></li>
            <li><span v-if="item.forCall" class="mdi mdi-check checkTable"/></li>
            <li><span v-if="item.forMessages" class="mdi mdi-check checkTable"/></li>
            <li class="G-align-center">
              <div class="P-actions">
                <v-menu left content-class="P-actions-list">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item class="P-list" @click="editPhone(item, index)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="P-list" @click="$emit('deletePhone', {index, type: 'phone', phone : item.phone})">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  // component
  import TitleComponent from "@/components/TitleComponent";

  export default {
    name: 'ContactListPhone',
    components: {TitleComponent},
    data() {
      return {
        phoneHeader: ['Phone Number', 'Name', 'Call', 'Message']
      }
    },
    computed: {
      ...mapState({
        contact: state => state.contactClient,
        phoneData: state => state.contactClient.phoneData,
        clientId: state => state.clientsModule.clientId,
        allPhones: state => state.contactClient.allPhones
      }),
    },
    methods: {
      ...mapActions({
        updatePhone: 'contactClient/addContactPhone',
        getContact: 'contactClient/getContact'
      }),
      ...mapMutations({
        isEditMode: 'contactClient/changeIsEditMode',
        isAddMode: 'contactClient/changeIsAddMode',
        manageValue: 'contactClient/changeManageValue',
        resetPhoneData: 'contactClient/resetPhoneData',
        selectedPhoneIndex: 'contactClient/setSelectedPhoneIndex',
        emailSection: 'contactClient/setEmailSection',
        setShowSaveBtn: 'personalInfoClient/setShowSaveBtn'
      }),
      addPhone() {
        this.manageValue(this.contact.manageType.phone)
        this.resetPhoneData()
        this.isAddMode(true)
        this.emailSection(false)
        this.setShowSaveBtn(false)
      },
      editPhone(item, index) {
        this.isAddMode(false)
        this.isEditMode(true)
        this.selectedPhoneIndex(index)
        this.phoneData.phone = item.phone
        this.phoneData.isCall = item.forCall
        this.phoneData.isMessage = item.forMessages
        this.manageValue(this.contact.manageType.phone)
        this.emailSection(false)
        this.setShowSaveBtn(false)
      },
      async deletePhone(index) {
        let phones = []
        phones = this.allPhones.forEach((item, i) => {
          if (i === index) {
            this.allPhones.splice(i, 1)
          }
        })
        phones = [...this.allPhones]
        await this.updatePhone({phones, clientId: this.clientId})
        await this.getContact({clientId: this.clientId})
      }
    },   
  }
</script>

<style scoped lang="scss">
  @import "src/assets/scss/variables";
  @import "../../simpleTable";

  .P-contact-list-block {
    margin-top : 20px;
  }
</style>