import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      tableHeaders: [
        'Currency',
        'Price Group',
        'Recurring Payment',
        'Paperless Billing'
      ]
    }
  },
  computed: {
    ...mapState({
      financeClient: state => state.financeClient,
      paymentForPackagesObj: state => state.financeClient.paymentForPackagesObj,
      showFinanceForm: state => state.financeClient.showFinanceForm,
      formData: state => state.financeClient.formData,
      currencyList: state => state.financeClient.currencyList,
      clientId: state => state.clientsModule.clientId,
      priceGroupAdmin: state => state.priceGroupAdmin,
      showSaveBtn: state => state.personalInfoClient.showSaveBtn,
      isAddClient: state => state.clientsModule.isAddClient,
      isLoading: state => state.appModule.isLoading,
      isGeneralSearch: state => state.generalSearch.isGeneralSearch,
      clientProviderId: state => state.clientsModule.clientProviderId
    }),

    currencyName() {
      let currency = 'USD'
      this.currencyList.map((item) => {
        if (item.value === this.currency) currency = item.name[0].name
      })
      return currency
    },

    priceGroupName() {
      let priceGroup = 'Default'

      if (this.priceGroup === 'default') {
        priceGroup = 'Default'
      }

      if (this.priceGroup !== 'default') {
        this.priceGroupAdmin.clientsPriceGroups.map((item) => {
          if (item.value === this.priceGroup) priceGroup = item.name.map(name => name.lang === 'en' ? name.name : item.name[0].name)[0]
        })
      }

      return priceGroup
    },

    paymentForPackagesName() {
      let name = '<span class="G-badge G-reject-badge status-badge me-0">Off</span>'
      
      if (this.paymentForPackagesObj?.id) {
        // name = item.name
        if (this.paymentForPackagesObj?.bankTransfer) { // getting bank name with bank icon
          name = `
            <div class="G-align-center">
              <svg class="P-bank-account-icon me-1">
                <use xlink:href="/sprite.svg#bank-icon"></use>
              </svg>

              <span>${ this.paymentForPackagesObj.bankTransfer.accountNumber.slice(-4) }</span>
            </div>
          `
        } 
        
        if (this.paymentForPackagesObj?.creditCard) {  // getting card name with card icon
          name = `
              <div class="G-align-center">
                <div class="P-credit-card-image me-1" style="background-image: url(${ this.getCardBrand(this.paymentForPackagesObj.creditCard.brand) })"></div>
                <span>${
                  this.paymentForPackagesObj.creditCard.cardNumber.slice(-4)
                }</span>
              </div>
            `
        }
      }

      return name
    },

    currency: {
      get() {
        return !this.financeClient.formData.currency ? 'default' : this.financeClient.formData.currency 
      },
      set(value) {
        this.setCurrency(value)
      }
    },

    priceGroup: {
      get() {
        return this.formData.priceGroup ? this.formData.priceGroup : null
      },
      set(value) {
        this.setPriceGroup(value)
      }
    },

    paymentForPackages: {
      get() {
        return this.formData.paymentForPackages
      },
      set(value) {
        this.setPaymentForPackages(value)
      }
    },

    paymentForDevice: {
      get() {
        return this.formData.paymentForDevice
      },
      set(value) {
        this.setPaymentForDevice(value)
      }
    },

    isPaperlessBilling: {
      get() {
        return this.formData.isPaperlessBilling
      },
      set(value) {
        this.setIsPaperlessBilling(value ? true : false)
      }
    }
  },
  async created() {
    await Promise.all([
      this.getPaymentList(),
      this.getCurrencyList(),
      this.getPriceGroupList()
    ])
  },
  methods: {
    ...mapActions({
      addFinance: 'financeClient/addFinance',
      getPriceGroupByTypeList: 'priceGroupAdmin/getPriceGroupByTypeList',
      getCurrencyList: 'financeClient/getCurrencyList',
      getPaymentList: 'financeClient/getPaymentList',
      getClientData: 'clientsModule/getClientData',
      setClientContactData: "contactClient/setContactData",
      setClientAddresses: "addressClient/setAddresses",
    }),
    ...mapMutations({
      financeSetData: 'financeClient/setData',
      resetFinanceData: 'financeClient/resetFinanceData',
      generateAllPriceGroups: 'priceGroupAdmin/generateAllPriceGroups',
      setCurrency: 'financeClient/setCurrency',
      setPriceGroup: 'financeClient/setPriceGroup',
      setPaymentForPackages: 'financeClient/setPaymentForPackages',
      setPaymentForDevice: 'financeClient/setPaymentForDevice',
      setIsPaperlessBilling: 'financeClient/setIsPaperlessBilling',
      clientSetData: "clientsModule/setData",
      setClientAdditionalInfo: "personalInfoClient/setAdditionalInfoData",
      setClientPersonalInfo: "personalInfoClient/setPersonalInfoData",
      setFinanceFormData: 'financeClient/setFormData',
    }),

    resetData() {
      this.resetFinanceData()
    },
    async saveData() {
      const finance = {}
      if (this.formData.currency && this.formData.currency !== 'default') {
        finance.currency = this.formData.currency
      }

      if (this.paymentForPackages && this.financeClient.paymentList.length) {
        // check if the payment method is client's payment method then use it
        this.financeClient.paymentList.map(item => {
          if (item.value === this.paymentForPackages) {
            finance.forPackages = this.formData.paymentForPackages
          }
        })
      }

      finance.paperlessBilling = this.formData.isPaperlessBilling

      if (this.priceGroup === 'default') {
        finance.priceGroup = null
      }

      if (this.priceGroup && this.priceGroup !== 'default' && this.priceGroupAdmin.clientsPriceGroups) {
        // check if the price group is client's price group then use it
        this.priceGroupAdmin.clientsPriceGroups.map((item) => {
          if (item.value === this.priceGroup) {
            finance.priceGroup = this.priceGroup
          }
        })
      }

      // if (this.formData.paymentForDevice) {
      //   finance.forDevicesRent = this.formData.paymentForDevice
      // }

      await this.addFinance({ finance, clientId: this.clientId })

      await this.getClientData().then((r) => {
        this.clientSetData({ clientProviderId: r?.provider?.id })
        this.setClientAdditionalInfo(r); // for personal info tab
        this.setClientPersonalInfo(r?.personalInfo); // for personal info tab
        this.setClientContactData({ emails: r.emails, phones: r.phones }); // for contact tab
        if (r.addresses.length) {
          this.setClientAddresses({ addresses: r.addresses });
        } // for addresses tab

        /*For Finance tab set formData*/
          let finance = {
            currency: null,
            priceGroup: null,
            paymentForPackages: null,
            paymentForDevice: null,
            isPaperlessBilling: false
          } // TODO hardcoded.
         
          let paymentForPackagesObj = null
          r.finance ? finance = { ...r.finance } : this.financeSetData({ showFinanceForm: true })
          paymentForPackagesObj = r.finance?.forPackages
          
          this.setFinanceFormData(finance)
          this.financeSetData({ paymentForPackagesObj })
          /********************************/
      });

      this.financeSetData({ showFinanceForm: false })
      return Promise.resolve(true)
    },
    /**
     * Get price group list
     * **/
    getPriceGroupList() {
      this.getPriceGroupByTypeList({ 
        type: 1,
        provider: this.clientProviderId ? this.clientProviderId : null
      }).then(data => {
        this.generateAllPriceGroups(data)
      })
    }
  }
}