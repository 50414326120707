import {mapState} from "vuex";

export default {
  data() {
    return {
      emailOrPhone: "",
      emailOrPhoneIndex: null,
      showWarningModal: false,
      warningModalBtnText: "",
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      warningModalType: "",
    }
  },
  computed: {
    ...mapState({
      contactClient: state => state.contactClient,
    }),
    
    warningModalHeading() {
      return `${this.warningModalBtnText} client's ${this.warningModalType}?`
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> client's <span class="text-lowercase">${this.warningModalType}</span> ${this.warningModalType === 'phone' ? '<br />' : ''} <ins>${this.emailOrPhone}</ins>. If you're not sure, you can cancel this operation.`
    },
  },  
  methods:{
    /**
     * Get email and phone number title 
     * **/
    getEmailName(){
      let emailText = 'Email Address'
      if(this.contactClient.isEditMode){
        emailText = 'Edit ' + emailText
      }
      if(this.contactClient.isAddMode){
        emailText = 'Add ' + emailText
      }
      return emailText
    },
    getPhoneNumberTitle(){
      let phoneText = 'Phone Number'

      if(this.contactClient.isEditMode){
        phoneText = 'Edit ' + phoneText
      }
      if(this.contactClient.isAddMode){
        phoneText = 'Add ' + phoneText
      }
      return phoneText
    },

    openDeleteModal(e) {
      this.warningModalType = e.type
      this.emailOrPhoneIndex = e.index
      e.email ? this.emailOrPhone = e.email : ''
      e.phone ? this.emailOrPhone = e.phone : ''
      this.warningModalIsLoading = false;
      this.warningModalBtnText = "Delete";
      this.showWarningModal = true;
    },

    async warningModalBtnFunc() {
      await this.warningModalBtnFunction().finally(() => {
        this.warningModalIsLoading = false;
      });
    },

    async warningModalBtnFunction() {
      this.warningModalIsLoading = true;

      this.warningModalType === "email" ?
        await this.$refs.emailList.deleteEmail(this.emailOrPhoneIndex) :
        await this.$refs.phoneList.deletePhone(this.emailOrPhoneIndex)

      this.showWarningModal = false; 
    }
  },
}