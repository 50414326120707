<template>
  <div class="P-modal-components">
    <title-component
      v-if="!paymentList.length && !showComponents" 
      :is-add="clientData?.provider?.hasValidPaymentGateway && hasSelectedPaymentGateway"
      :click="() => showComponents = true" 
      title-text="Add Payment Method" 
    />
    <div v-if="showComponents">
      <div class="P-modal-component-header G-justify-between">
        <title-component
          v-if="paymentList.length && !addMode && !editMode"
          title-text="Saved payments"
        />
        <div v-if="this.bankAccount">
          <TitleComponent
            v-if="editMode && this.bankAccount.paymentMethod === 0"
            title-text="Edit credit card"
          />
          <TitleComponent
            v-if="editMode && this.bankAccount.paymentMethod === 1"
            title-text="Edit bank account"
          />
        </div>
        <div
          v-if="paymentList.length && !addMode  && !editMode && clientData?.provider?.hasValidPaymentGateway"
          @click="addNewPayment"
          class="icon-cont"
        >
          <span class="mdi mdi-plus neutral--text" />
        </div>
      </div>
      <div class="P-modal-component-body">
        <ManagePayments v-if="(!paymentList.length || addMode || editMode) && hasSelectedPaymentGateway" ref="managePayments" />
        <PaymentList :hasSelectedPaymentGateway="hasSelectedPaymentGateway" v-else />
      </div>
    </div>
    <error-message-container
      v-if="!clientData?.provider?.hasValidPaymentGateway"
      message="The client's provider not has a valid payment gateway."
      classes="w-max-content mt-3"
    />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
// components
import ManagePayments from "./ManagePayments";
import PaymentList from "./PaymentList";
import TitleComponent from "@/components/TitleComponent";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";

export default {
  name: "PaymentMethodClients",
  components: { TitleComponent, PaymentList, ManagePayments, ErrorMessageContainer },
  computed: {
    ...mapState({
      paymentList: state => state.paymentMethodClients.paymentList,
      addMode: state => state.paymentMethodClients.addMode,
      editMode: state => state.paymentMethodClients.editMode,
      paymentType: state => state.paymentMethodClients.paymentType,
      selectedPaymentType: state => state.paymentMethodClients.selectedPaymentType,
      addProviderModal: state => state.paymentMethodClients,
      bankAccount: state => state.paymentMethodClients.bankAccount,
      showAllComponents: state => state.paymentMethodClients.showComponents,
      clientData: state => state.clientsModule.client,
    }),

    showComponents: {
      set(value) {
        this.setData({ showComponents: value });
      },
      get() {
        return this.showAllComponents;
      },
    },
  },
  watch: {
    paymentList(payments) {
      if (!payments.length) {
        this.setData({ showComponents: false });
      } else {
        this.setData({ showComponents: true });
      }
    }
  },
  data () {
    return {
      hasSelectedPaymentGateway: false
    }
  },
  async created() {
    const providerId = this.clientData?.provider?.id;
    const gateway = await this.getPaymentGateway(providerId);
    this.hasSelectedPaymentGateway = false;

    if (gateway?.autopay || gateway?.cards || gateway?.bank) {
      this.hasSelectedPaymentGateway = true
    }

    if (this.paymentList.length) {
      this.setData({ showComponents: true });
    } else {
      this.setData({ showComponents: false });

    }
  },
  methods: {
    ...mapMutations({
      setData: 'paymentMethodClients/setData',
      goBackPaymentsList: 'paymentMethodClients/closeAddEditPaymentData',
      setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      addNewPaymentMethod: 'paymentMethodClients/addNewPaymentMethod'
    }),
    ...mapActions({
      getPaymentGateway: 'merchant/getPaymentGateway',
    }),

    addNewPayment() {
      this.addNewPaymentMethod()
      this.setShowSaveBtn(false)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
.P-modal-component-header {
  margin-bottom: 10px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}
</style>
