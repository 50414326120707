<template>
  <div class="P-location-list">
    <TitleComponent title-text="Location info" :is-add="true" :click="addNewLocation"/>

    <div v-if="locationList.length" class="P-label-list G-scroll-list">
      <table :class="{'P-table-for-search': $route.name === 'Search'}">
        <tr>
          <th class="secondary--text">Location Name</th>
          <th class="secondary--text">Login</th>
          <th class="secondary--text">Server</th>
          <th class="secondary--text">Status</th>
          <th class="secondary--text">Commenter</th>
          <th class="secondary--text" :class="$route.name !== 'Search' ? 'P-comment-column' : 'P-comment-column-serch'">Comment</th>
          <th></th>
        </tr>

        <template v-for="(item, index) of locationList">
          <tr :key="index" class="P-hovered-tr">
            <td class="secondary--text">
              <p v-if="item.locationName" class="secondary--text">
                {{ item.locationName }}
              </p>
            </td>
            <td class="secondary--text">{{ item.login}}</td>
            <td class="secondary--text">{{ getServerName(item.server) }}</td>
            <td class="secondary--text">
              <div class="d-flex align-center justify-center" v-html="item.status" />
            </td>
            <td class="secondary--text">
              <div v-if="item.commentUser" class="d-flex justify-center align-center">
                <img 
                  v-if="item?.commentUser?.avatar" 
                  :src="baseApiUrl + 'files/icon/' + item.commentUser.avatar"
                  @click="getCommenterImage(baseApiUrl + 'files/icon/' + item.commentUser.avatar)"
                  class="P-user-avatar cursor-pointer" 
                />
                <span class="ms-2">{{ item.commentUser.firstname + ' ' + item.commentUser.lastname }}</span>
              </div>
            </td>
            <td class="P-comment-column text-wrap" :class="$route.name !== 'Search' ? 'P-comment-column' : 'P-comment-column-serch'" v-html="item.comment" />
            <td class="G-align-center">
              <div class="P-actions">
                <v-menu left content-class="P-actions-list">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item class="P-list row-pointer" @click="editLocationFromList(item.id)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="P-list row-pointer" @click="openWarningModal(item.id, item.locationName)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
        </template>
      </table>
    </div>

    <page-loader v-else :opacityBackground="false" />
    
    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false"     
      @btnFunction="deleteLocation(deletedLocationId)"  
      btnText="Delete"
    />

    <image-modal
      v-if="isShowImage"
      :isOpenModal="isShowImage"
      :imageSrc="imageSrc"
      @closeModal="() => { isShowImage = false, isCommenterImg = false }"
      :modal-title="isCommenterImg ? 'Commenter Image' : 'Comment Image'"
    />

    <PageLoader v-if="showLoader" />
  </div>
</template>
<script>
  import TitleComponent from "@/components/TitleComponent";
  import LocationListMixin from "./LocationListMixin";
  import WarningModal from "@/components/WarningModal";
  import ImageModal from "@/components/ImageModal";
  import PageLoader from "@/components/pageLoader/PageLoader";

  export default {
    name: 'LocationListClients',
    components: { TitleComponent, WarningModal, ImageModal, PageLoader },
    mixins: [LocationListMixin]
  }
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/variables";

  .theme--light {
    & th{
      background-color  : white;  
    }
  }

  .P-hovered-tr {
    position: relative;

    &:after {
      width: 2px;
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      transition: $transition;
      background-color: $primary-color;
      content: "";
    }

    &:hover {
      &:after {
        height: 100%;
      }

      & td {
        background-color : $neutral-color7;
      }
    }
  }

  .theme--dark {
    & th {
      background-color  : $dark_light;  
    }

    .P-hovered-tr {
      &:hover {
        & td {
          background-color : transparent !important;
        }
      }
    }
  }

  table {
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    tr, td, th {
      border: 1px solid $neutral-color45;
      text-align: center;
    }

    & tr {
      th {
        font-size      : $txt12;
        font-weight    : bold;
        padding        : 8px;
        vertical-align : middle;
        text-transform : capitalize;
      }

      td {
        font-size      : $txt14;
        word-break     : break-all;
        padding        : 8px;
        transition     : $transition;
        vertical-align : middle;
        text-transform : capitalize;

        // & + li {
        //   border-left : 1px solid $neutral-color45;
        // }

        p {
          margin-bottom: 0;
        }

        &:last-child {
          padding: 15px 0;
          border : 1px solid transparent;
        }
      }

      // border-top : 1px solid $neutral-color45;

      &:hover {
        .P-actions {
          opacity    : 1;
          visibility : visible;

        }
        td{
          background-color: $neutral-color7;
        }
      }
    }
  }

  .P-label-list {
    border-radius : 4px;
    margin-top    : 12px;
  }

  .P-table-body::v-deep {
    .v-input--switch {
      margin-top : 0;
    }

    .v-input__slot {
      margin-bottom : 0;
    }

    .v-messages {
      display : none;
    }

    .v-btn--icon.v-size--default {
      height: auto;
    }
  }

  .P-actions {
    opacity    : 0;
    visibility : hidden;
    transition : $transition;
  }
  
  .P-comment-column {
    min-width: 150px;
  }

  .P-comment-column-serch {
    min-width: unset;
    width: max-content;
  }

  .P-comment-column-serch, .P-comment-column {
    border-right: 1px solid transparent;
  }

  .P-user-avatar {
    width: 30px;
    height: 30px;
    object-fit: cover; 
    border-radius: 50%;
    box-shadow: 2px 2px 2px lightgrey;
  }

  .P-table-for-search::v-deep {
    td {
      font-size: 13px;
    }
  }
</style>

<style>
  .G-badge {
    width: max-content !important;
    padding: 3px 10px !important;
  }
</style>