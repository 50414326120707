<template>
    <div class="P-payment">
      <div class="P-payment__item P-payment-box" v-for="(payment, index) of paymentList" :key="index">
        <span class="P-payment__item-disabled" v-if="!payment.inUse">Deactivate</span>
          <div class="P-payment__item-info G-flex G-align-center G-justify-between" :class="{'P-disabled': !payment.inUse}">
              <div class="G-align-center G-justify-between w-100">
                  <div class="G-align-center">
                    <v-radio-group v-model="selectedCreditCard" @change="selectDefault(payment.id)">
                      <ott-radio :value="payment.id" />
                    </v-radio-group>
                    <div class="P-credit-card-image" v-if="payment.creditCard">
                      <div :style="{backgroundImage:`url('${getCardBrand(payment.creditCard.brand)}')`}"></div>
                    </div>
                    <svg class="P-bank-account-icon" v-else>
                      <use xlink:href="/sprite.svg#bank-icon"></use>
                    </svg>
                    <div v-if="payment.creditCard">
                      <p class="P-card-number">{{payment.creditCard.cardNumber}}</p>
                    </div>
                    <div v-if="payment.bankTransfer">
                      <p class="P-card-number">{{payment.bankTransfer.accountNumber}}</p>
                    </div>
                  </div>

                  <v-tooltip v-if="!payment.isValid" top color="error">
                    <template v-slot:activator="{ on, attrs }">
                      <span  
                        v-bind="attrs"
                        v-on="on"
                        :style="{ color: $vuetify.theme.themes.light.error }"
                        class="mdi mx-2 mdi-alert cursor-pointer"
                      ></span>
                    </template>
                    <span>{{ payment?.validationMessage }}</span>
                  </v-tooltip>
              </div>

              <div v-if="!isCheckout" class="P-actions">
                <v-menu left content-class="P-actions-list">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                          <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-if="hasSelectedPaymentGateway" class="P-list" @click="edit(payment)">
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="P-list" @click="openDeleteModal(payment.id)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                </v-menu>
              </div>
          </div>
      </div>

      <WarningModal 
        v-if="showWarningModal"
        :isModalOpen="showWarningModal"
        :modalHeading="warningModalHeading" 
        :modalText="warningModalText"  
        :isDangerButton="warningModalIsDangerButton"
        :isLoading="warningModalIsLoading"
        @closeModal="showWarningModal = false" 
        @btnFunction="deletePaymentFunc"       
        btnText="Delete"
      />
      <PageLoader v-if="isPaymentsLoading && isOpenClientsModal" />
    </div>
</template>
<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  import { eventBus } from '@/main';
  import CreditCardsMixin from "@/mixins/CreditCardsMixin";
  import OttRadio from "@/components/vuetifyComponents/OttRadio";
  import WarningModal from "@/components/WarningModal";
  import PageLoader from "@/components/pageLoader/PageLoader";

  export default {
    name: 'PaymentList',
    components: { OttRadio, WarningModal, PageLoader },
    mixins: [ CreditCardsMixin ],
    props: {
      isCheckout: {
        type: Boolean,
        default: () => false
      },

      hasSelectedPaymentGateway: {
        type: Boolean,
        default: () => false
      }
    },
    data() {
      return {
        showWarningModal: false,
        deletedPaymentId: null,
        warningModalIsDangerButton: true,
        warningModalIsLoading: false,
        isPaymentsLoading: false
      }
    },
    computed: {
      ...mapState({
        paymentList: state => state.paymentMethodClients.paymentList,
        selectedPaymentMethod: state => state.paymentMethodClients.selectedCreditCard,
        editMode: state => state.paymentMethodClients.editMode,
        bankTransfer: state => state.paymentMethodClients.bankTransfer,
        creditCard: state => state.paymentMethodClients.creditCard,
        paymentMethodId: state => state.paymentMethodClients.paymentMethodId,
        clientId: state => state.clientsModule.clientId,
        isOpenClientsModal: state => state.clientsModule.modal.isOpen,
      }),

      warningModalHeading() {
        return `Delete client's payment method?`
      },

      warningModalText() {
        return `You're about to <ins>delete</ins> client's payment method. If you're not sure, you can cancel this operation.`
      },

      selectedCreditCard: {
        set(value) {
          this.setData({ selectedCreditCard: value })
        },
        get() {
          return this.selectedPaymentMethod
        }
      },
    },
    beforeDestoy() {
      this.$hubEventBus.$off("card-info", this.handleCardInfo); // if loggedIn get permissions with WS
    },

    async created() {
      this.$hubEventBus.$on("card-info", this.handleCardInfo); // if loggedIn get permissions with WS

      this.isPaymentsLoading = true;
      if (this.$route.name !== 'Search' || (this.$route.name === 'Search' && this.isOpenClientsModal)) {
        await this.getPaymentData().finally(() => this.isPaymentsLoading = false);
      }
    },

    destroyed() {
      this.selectedCreditCard = null;
    },
    
    methods: {
    async handleCardInfo(info) {
      console.log('socket-info', info.message);
      this.getPaymentData();
    },
      ...mapActions({
        deleteBankAccount: 'paymentMethodClients/deleteBankAccount',
        getPaymentMethod: 'paymentMethodClients/getPaymentMethod',
        getBankAccount: 'paymentMethodClients/getBankAccount',
        editPaymentMethod: 'paymentMethodClients/editPaymentMethod',
        getFinancePaymentList: 'financeClient/getPaymentList'
      }),

      ...mapMutations({
        setData: 'paymentMethodClients/setData',
        isAddMode: 'paymentMethodClients/setAddMode',
        isEditMode: 'paymentMethodClients/setEditMode',
        selectPaymentType: 'paymentMethodClients/selectPaymentType',
        setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
        setDataSecondLevels: 'paymentMethodClients/setDataSecondLevels'
      }),

      openDeleteModal(paymentId) {
        this.showWarningModal = true;
        this.deletedPaymentId = paymentId;
      },

      edit(payment) {
        console.log(payment, 'payment')
        if (payment.creditCard) {
          this.setDataSecondLevels({
            creditCard: {
              cardNumber: payment?.creditCard?.cardNumber,
              cardholderName: payment?.creditCard?.cardholderName,
              brand: payment?.creditCard?.brand,
              expireDate: payment?.creditCard?.month + ' / ' + payment?.creditCard?.year,
              cvc: payment?.creditCard?.cvc,
              month: payment?.creditCard?.month,
              year: payment?.creditCard?.year,
              address: payment?.creditCard?.billingAddress?.address,
              country: payment?.creditCard?.billingAddress?.country,
              city: payment?.creditCard?.billingAddress?.city,
              suite: payment?.creditCard?.billingAddress?.suite,
              state: payment?.creditCard?.billingAddress?.province,
              zip: payment?.creditCard?.billingAddress?.zip,
              existingAddress: payment?.creditCard?.existingAddress,
              anExistingAddress: payment?.creditCard?.anExistingAddress,
              phone: payment?.creditCard?.billingAddress?.phone
            },
          })
        }

        if (payment.bankTransfer) {
          this.setDataSecondLevels({
            bankTransfer: {
              bankName: payment?.bankTransfer?.bankName,
              routingNumber: payment?.bankTransfer?.routingNumber,
              accountNumber: payment?.bankTransfer?.accountNumber,
              companyName: payment?.bankTransfer?.companyName,
              firstname: payment?.bankTransfer?.firstname,
              lastname: payment?.bankTransfer?.lastname,
              nickname: payment?.bankTransfer?.nickname,
            },
          })
        }

        this.setData({ 
          editMode: true,
          paymentMethodId: payment?.id
        })
      },

      async selectDefault(paymentMethodId) {
        this.selectedCreditCard = paymentMethodId;
        const data = {
          default: true,
          inUse: true
        }

        await this.editPaymentMethod({paymentMethodId, data})
        await this.getPaymentData()
      },

      async deletePaymentFunc() {
        await this.deletePayment().finally(() => {
          this.warningModalIsLoading = false;
        });

        await this.getFinancePaymentList() // For update finance payments select 

        if (this.$route.name === 'Search') {
          eventBus.$emit("paymentMethodDeleted");
        }
      },

      async deletePayment() {
        this.warningModalIsLoading = true;
        await this.deleteBankAccount({paymentMethodId: this.deletedPaymentId})
        await this.getPaymentData();

        this.showWarningModal= false;
      },

      async getPaymentData() {
        await this.getPaymentMethod({clientId: this.clientId})
        if (this.paymentList.length) {
          this.paymentList.map(item => {
            if (item.default) {
              this.selectedCreditCard = item.id
            }
          })
        }
      },      
    },
  
  }
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables";
 
  .P-selected-payment span {
    width         : 16px;
    height        : 16px;
    border-radius : 50%;
    border        : 1px solid $neutral-color;
    display       : block;
    position      : relative;
    cursor        : pointer;

    &:before {
      position         : absolute;
      width            : 100%;
      height           : 100%;
      background-color : $primary-color;
      opacity          : 0;
      visibility       : hidden;
      border-radius    : 50%;
      transform        : scale(0.7);
      content          : '';
    }

    &.P-active-payment {
      border-color : $primary-color;

      &:before {
        opacity    : 1;
        visibility : visible;
      }
    }
  }

  .P-actions {
    opacity    : 0;
    visibility : hidden;
    transition : $transition;
  }

  .theme--dark {
    .P-payment-box {
      background-color : rgba(255, 255, 255, 0.05);
    }

    .P-card-number {
      color : $white !important;
    }

    .P-credit-card-image {
      fill : white;
    }
  }

/* ------------- */
.P-payment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 22px;
  grid-row-gap: 24px;
  margin-top: 12px;
  &__item {
    position: relative;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 5px #284E6626;
    border-radius: 4px;
    padding: 12px 16px;

    .P-card-number {
      margin-bottom: 0;
      font-size: $txt18;
      font-weight: $semiBold;
      color: $secondary-color;
    }

    .P-disabled {
      opacity : 0.5;
    }

    &-disabled {
      position         : absolute;
      padding          : 5px 5px;
      border-radius    : 6px;
      background-color : rgba(0, 0, 0, 0.54);
      left             : 25px;
      top              : -25px;
      font-size        : 12px;
      color            : $white;
      transition       : $transition;
      opacity          : 0;
      visibility       : hidden;
    }

    &:hover {
      .P-actions {
        opacity    : 1;
        visibility : visible;
      }
    }
  }
}

.P-payment__item {
  padding: 10px;
  .P-card-number {
    font-size: 16px;
  }
}
.P-payment__item:hover {
    .P-payment__item-disabled {
        opacity    : 1;
        visibility : visible;
    }
}
.P-credit-card-image div {
  width               : 48px;
  height              : 28px;
  background-position : center;
  background-repeat   : no-repeat;
  background-size     : contain;
  margin-right        : 7px;
}

.P-credit-card-image {
  margin-right: 8px;
}

.w-100 {
  width: 100%;
}

.v-btn--fab.v-size--default .v-icon, 
.v-btn--fab.v-size--small .v-icon, 
.v-btn--icon.v-size--default .v-icon, 
.v-btn--icon.v-size--small .v-icon {
  height: 10px;
  font-size: 24px;
  width: 10px;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0;
}

::v-deep {
  .v-btn--icon.v-size--default {
    height: inherit;
    width: inherit;
  }
  .v-input {
      width: inherit;
  }
  .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
  }
  .v-input__slot {
      margin-bottom: 0;
  }
  .v-messages {
      display: none;
  }
  .v-input--selection-controls__input {
      width: inherit;
  }
}
</style>
