<template>
  <div class="P-modal-component-body">
    <div class="P-manage-contact">
      <div class="P-manage-contact-box" v-if="contact.emailSection">
        <div
          class="P-contact-box"
          v-if="
            !allEmails.length ||
            (contact.manageValue === contact.manageType.email &&
            (contact.isEditMode || contact.isAddMode))
          "
        >
          <TitleComponent 
            v-if="!allEmails.length && !showEmailComponents" 
            :is-add="true" 
            :click="() => showEmailComponents = true" 
            title-text="Add Email Address" 
          />
          
          <div v-if="showEmailComponents">
            <TitleComponent :title-text="getEmailName()" />
            <EmailContent ref="contactEmail" />
          </div>
        </div>
        <ContactListEmail
          v-else
          @deleteEmail="openDeleteModal($event)"
          ref="emailList"
        />
      </div>
      <div class="P-manage-contact-box" v-if="contact.phoneSection">
        <div
          class="P-contact-box"
          v-if="
            !allPhones.length ||
            (contact.manageValue === contact.manageType.phone &&
            (contact.isEditMode || contact.isAddMode))
          "
        >
          <TitleComponent 
            v-if="!allPhones.length && !showPhoneComponents" 
            :is-add="true" 
            :click="() => showPhoneComponents = true" 
            title-text="Add Phone Number" 
          />

          <div v-if="showPhoneComponents">
            <TitleComponent :title-text="getPhoneNumberTitle()" />
            <PhoneContent ref="contactPhone" />
          </div>

        </div>
        <ContactListPhone
          @deletePhone="openDeleteModal($event)"
          ref="phoneList"
          v-else
        />
      </div>
    </div>

    <WarningModal
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false"
      @btnFunction="warningModalBtnFunc"
    />
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import ContactMixin from "./ContactMixin";
// components
import WarningModal from "@/components/WarningModal";
import TitleContact from "@/components/addProviderModal/contact/components/TitleContact";
import TitleComponent from "@/components/TitleComponent";
import EmailContent from "./component/Email";
import PhoneContent from "./component/Phone";
import ContactListPhone from "./component/ContactListPhone";
import ContactListEmail from "./component/ContactListEmail";

export default {
  name: "Contact",
  components: {
    WarningModal,
    ContactListEmail,
    ContactListPhone,
    PhoneContent,
    EmailContent,
    TitleComponent,
    TitleContact,
  },
  mixins: [ContactMixin],
  computed: {
    ...mapState({
      contact: (state) => state.contactClient,
      allEmails: (state) => state.contactClient.allEmails,
      allPhones: (state) => state.contactClient.allPhones,
      isAddClient: (state) => state.clientsModule.isAddClient,
      showSaveBtn: (state) => state.personalInfoClient.showSaveBtn,
    }),

    showEmailComponents: {
      set(value) {
        this.setData({ showEmailComponents: value });
      },
      get() {
        return this.contact.showEmailComponents;
      },
    },

    showPhoneComponents: {
      set(value) {
        this.setData({ showPhoneComponents: value });
      },
      get() {
        return this.contact.showPhoneComponents;
      },
    }
  },

  watch: {
    allEmails(emailsList) {
      if (!emailsList.length) {
        this.setData({ showEmailComponents: false });
      } else {
        this.setData({ showEmailComponents: true });
      }       
    },

    allPhones(phonesList) {
      if (!phonesList.length) {
        this.setData({ showPhoneComponents: false });
      } else {
        this.setData({ showPhoneComponents: true });
      }    
    }
  },

  created() {
    if (this.allEmails.length) {
      this.setData({ showEmailComponents: true });
    } else {
      this.setData({ showEmailComponents: false });
    }

    if (this.allPhones.length) {
      this.setData({ showPhoneComponents: true });
    } else {
      this.setData({ showPhoneComponents: false });
    }
  },

  methods: {
    ...mapMutations({
      setData: "contactClient/setData"
    })
  }
}
</script>
<style scoped lang="scss">
.P-manage-contact-box:first-child {
  margin-bottom: 16px;
}
</style>