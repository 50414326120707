<template>
  <div class="P-phone-content P-manage-client d-flex flex-wrap">
    <div 
      :class="
        $route.name !== 'Search'? 'P-padding-50' :
        $route.name === 'Search' && tabsWidth !== 100 ? 'P-padding-50' : 'P-padding-40'
      ">
      <div class="P-padding-100">
        <ott-phone-number
        v-model="phone"
        :error="(isValidateNumber || validPhoneNumber || $v.phoneData.phone.$error || isDuplicatedPhone) && showPhoneError"
        @onInput="onInputPhoneNumber"
        @onChange="showPhoneError = true"
      />
      </div>

      <div class="P-padding-100">
        <ott-input
          v-model="name"
          label="Name"
        />
      </div>
      <div class="P-checkbox G-align-center">
        <p class="P-notification secondary--text">Notifications</p>
        <ott-checkbox label="Call" v-model="isCall"/>
        <ott-checkbox label="Message" v-model="isMessage"/>
      </div>
      <ErrorMessageContainer
        v-if="isDuplicatedPhone"
        :message="errorMessage"
      />
      <div>
        <SaveResetButtons
          v-if="!showDuplicatedClients"
          :right-label="contact.isEditMode ? 'Save' : 'Add'"
          :left-click-func="resetData"
          :right-click-func="contact.isEditMode ? editData : saveData"
          :is-loading="isLoading"
          left-label="Cancel"
        />
        <SaveResetButtons
          v-else
          :right-label="getSaveBtnName"
          :left-click-func="resetData"
          :right-click-func="contact.isEditMode ? editDataWithDuplicate : saveDataWithDuplicate"
          :is-loading="isLoading"
          left-label="Cancel"
        />
      </div>
    </div>
    
    <transition name="fade">
      <div 
        v-if="showDuplicatedClients" 
        :class="
          $route.name !== 'Search' ? 'P-padding-100' :
          $route.name === 'Search' && tabsWidth === 100 ? 'P-padding-60' : 'P-padding-100'
        "
      >
        <v-card>
          <v-toolbar
            color="pink"
            dark
          >
            <v-toolbar-title class="text-center text-17 mx-auto">This phone number is used by other clients <br /> 
              If you want to continue and save please click continue </v-toolbar-title>
            </v-toolbar>

          <v-list two-line>
            <v-list-item-group
              multiple
            >
              <template v-for="(item, index) in duplicateClientsData">
                <v-list-item :key="item.title">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title class="text-15" v-html="item.clientInfo"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < duplicateClientsData.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
    </transition>
  </div>
</template>
<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import { required } from "vuelidate/lib/validators";
  import allCountry from "@/constants/all-countries";
  import EmailPhoneMixin from "./EmailPhoneMixin";

  // components
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
  import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
  import SaveResetButtons from "@/components/SaveResetButtons";

  export default {
    name: 'PhoneContent',
    components: { SaveResetButtons, OttButtonLoader, ErrorMessageContainer, OttCheckbox, OttInput, OttPhoneNumber },
    mixins: [ EmailPhoneMixin ],
    data() {
      return {
        validPhoneNumber: false,
        isValidateNumber: false,
        showPhoneError: false,
        isDuplicatedPhone: false,
        errorMessage: "",
        initialPhone: ""
      }
    },
    validations: {
      phoneData: {
        phone: { required },
      }
    },
    created() {
      this.setShowSaveBtn(false)
    },
    mounted() {
      this.initialPhone = this.contact.phoneData.phone
      this.getCountryCode()
    },
    destroyed() {
      this.setShowSaveBtn(true)
    },
    computed: {
      ...mapState({
        contact: state => state.contactClient,
        phoneData: state => state.contactClient.phoneData,
        userData: state => state.auth.user,
        allPhones: state => state.contactClient.allPhones,
        clientId: state => state.clientsModule.clientId,
        client: state => state.clientsModule.client,
        showSaveBtn: state => state.personalInfoClient.showSaveBtn,
        isAddClient: state => state.clientsModule.isAddClient,
        isLoading: state => state.appModule.isLoading,
        isGeneralSearch: state => state.generalSearch.isGeneralSearch
      }),

      phone: {
        get() {
          return this.contact.phoneData.phone
        },
        set(value) {
          this.setPhone(value)
        }
      },
      name: {
        get() {
          return this.contact.phoneData.name
        },
        set(value) {
          this.setName(value)
        }
      },
      isCall: {
        get() {
          return this.contact.phoneData.isCall
        },
        set(value) {
          this.setIsCall(value)
        }
      },
      isMessage: {
        get() {
          return this.contact.phoneData.isMessage
        },
        set(value) {
          this.setIsMessage(value)
        }
      }
    },
    methods: {
      ...mapActions({
        addPhone: 'contactClient/addContactPhone',
        getContact: 'contactClient/getContact',
             checkDuplicatedEmailPhone: 'contactClient/checkDuplicatedEmailPhone'
      }),
      ...mapMutations({
        setData: "contactClient/setData",
        isEditMode: 'contactClient/changeIsEditMode',
        isAddMode: 'contactClient/changeIsAddMode',
        emailSection: 'contactClient/setEmailSection',
        manageValue: 'contactClient/changeManageValue',
        resetPhoneData: 'contactClient/resetPhoneData',

        setIsPhoneLoading: 'contactClient/setIsPhoneLoading',
        setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
        setPhone: 'contactClient/setPhone',
        setName: 'contactClient/setName',
        setIsCall: 'contactClient/setIsCall',
        setIsMessage: 'contactClient/setIsMessage',        
      }),

      validate() {
        this.$v.phoneData.$touch()
        return !this.$v.phoneData.$error && !this.validPhoneNumber && !this.isValidateNumber && !this.isDuplicatedPhone
      },
      async saveData() {
        const phone = {
            phone: this.phoneData.phone,
            name: this.phoneData?.name,
            forMessages: this.phoneData.isMessage,
            forCall: this.phoneData.isCall,
        }
        await this.checkIsDuplicate();

        if (this.validate() && !this.isDuplicatedPhone && !this.showDuplicatedClients) {
          this.setIsPhoneLoading(true)
          const phones = [...this.allPhones]
          phones.push(phone)

          await this.addPhone({phones, clientId: this.clientId})
          await this.getContact({clientId: this.clientId})
          this.resetData()
          return Promise.resolve(true)
        } else {
          return Promise.resolve(false)
        }
      },
      async saveDataWithDuplicate() {
        const phone = {
            phone: this.phoneData.phone,
            name: this.phoneData?.name,
            forMessages: this.phoneData.isMessage,
            forCall: this.phoneData.isCall,
        }
        await this.checkIsDuplicate();

        if (this.validate() && !this.isDuplicatedPhone) {
          this.setIsPhoneLoading(true)
          const phones = [...this.allPhones]
          phones.push(phone)

          await this.addPhone({phones, clientId: this.clientId})
          await this.getContact({clientId: this.clientId})
          this.resetData()
          return Promise.resolve(true)
        } else {
          return Promise.resolve(false)
        }
      },
      async editData() {
        if (this.phone !== this.initialPhone) await this.checkIsDuplicate();

        if (this.validate() && !this.isDuplicatedPhone && !this.showDuplicatedClients) {
          this.setIsPhoneLoading(true)
          let phones = [...this.allPhones];
          phones.map((item, index) => {
            if (index === this.contact.selectedPhoneIndex) {
              item.phone = this.phoneData.phone
              item.name = this.phoneData?.name
              item.forMessages = this.phoneData.isMessage
              item.forCall = this.phoneData.isCall

              delete item.updatedAt
              delete item.createdAt
            }
          })
          await this.addPhone({phones, clientId: this.clientId})
          await this.getContact({clientId: this.clientId})
          this.resetData()
        }
      },
      async editDataWithDuplicate() {
        if (this.validate() && !this.isDuplicatedPhone) {
          this.setIsPhoneLoading(true)
          let phones = [...this.allPhones];
          phones.map((item, index) => {
            if (index === this.contact.selectedPhoneIndex) {
              item.phone = this.phoneData.phone
              item.name = this.phoneData?.name
              item.forMessages = this.phoneData.isMessage
              item.forCall = this.phoneData.isCall

              delete item.updatedAt
              delete item.id
              delete item.value
            }
          })
          await this.addPhone({phones, clientId: this.clientId})
          await this.getContact({clientId: this.clientId})
          this.resetData()
        }
      },
      resetData() {
        this.showDuplicatedClients = false
        this.duplicateClientsData = []
        this.resetPhoneData()
        this.emailSection(true)
        this.manageValue()
        this.isEditMode(false)
        this.isAddMode(false)
        this.setShowSaveBtn(true)

        if (!this.allPhones.length) {
          this.setData({ showPhoneComponents: false })
        }
      },

      onInputPhoneNumber() {
        const [phone, obj] = arguments;
        this.validPhoneNumber = !obj.isValid;
        this.$v.phoneData.phone.$touch();
        let str = obj.number.input.replace(/[^A-Z0-9]/ig, '')
        this.isValidateNumber = !Number(str)      
      },

      async checkIsDuplicate() {
        if (this.allPhones.some(item => item.phone === this.phone)) {
          this.isDuplicatedPhone = true
          this.errorMessage = "Duplicate Phone Number"
        } else {
          this.isDuplicatedPhone = false
        }

        await this.checkDuplicatedEmailPhone({phone: this.phone, client: this.clientId}).then((res) => {
          /**
           Is checking if the phone number is already used by other clients, if yes, 
            creating new content to show all the clients' important information
          **/
         
          if (res?.isDublicated && !this.isDuplicatedPhone) {
            this.showDuplicatedClients = true
            this.duplicateClientsData = []
        
            res.duplicateClients.map(item => { 
              this.duplicateClientsData.push({
                id: item.id,
                clientInfo: `<p class="G-align-center flex-wrap mb-0"><b>${item.personalInfo.firstname} ${item.personalInfo.lastname}</b><i>${ this.createAddress(item.addresses[0]) ? ', ' + this.createAddress(item.addresses[0]) : ' ' }${ item.phones.length ? ', <ins>' + this.phone + '</ins>, ' : ', ' }</i>
                &nbsp; ${ this.getClientStatus(item) }</p>`
              })
              // clientInfo: `<b>${item.personalInfo.firstname} ${item.personalInfo.lastname} (${item.provider ? item.provider.name[0].name : 'No Provider Name'})</b><i>${ this.createAddress(item.addresses[0]) ? ', ' + this.createAddress(item.addresses[0]) : ' ' }${ item.phones.length ? ', <ins>' + this.phone + '</ins>, ' : ', ' }</i>
              // &nbsp; ${ this.getClientStatus(item) }`
            })
                            
          } else {
            this.showDuplicatedClients = false
          }

        })
      },

      getCountryCode() {
        if (!this.phone) {
          allCountry.forEach(item => {
            if (item.iso2 === this.userData?.geoInfo?.countryCode.toUpperCase()) {
              this.setPhone("+" + item.dialCode);
            }
          });
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/formStyles";
  @import "src/assets/scss/variables";

  .P-phone-content {
    margin : 0 -10px;
  }

  .P-checkbox {
    margin-top: 10px;

    .Ott-checkbox-cont + .Ott-checkbox-cont {
      margin-left : 20px;
    }
  }

  .P-notification {
    margin-bottom  : 0;
    margin-right   : 40px;
    font-size      : $txt12;
    font-weight    : $semiBold;
  }

  ::v-deep {
    .error-massage-cont {
      margin: 10px 0 0;
    }

    .v-list-item__content {
      padding: 8px 0;
    }

    .v-list--two-line .v-list-item, .v-list-item--two-line {
      min-height: unset;
    }

    .v-list {
      padding: 0;
    }

    .v-list-item {
      padding: 0 12px;
    }
  }

  .text-17 {
    font-size: 17px;
  }

  .text-15 {
    font-size: 15px;
  }

</style>