<template>
  <div class=" P-manage-client P-manage-address">

    <TitleComponent :title-text="getAddressTitle()" />
    <div class="P-manage-address-block G-flex-wrap">
      <div class="P-padding-100">
        <ott-switch
          v-model="isExistingName"
          @emitFunction="setPersonalExistingName"
          label="Existing name"
        />
      </div>
      <div class="P-padding-33">
        <ott-input
          v-model="firstname"
          :error="$v.formData.firstname.$error"
          :disabled="addressClient.isExistingName"
          :class="
            addressClient.isExistingName && $v.formData.firstname.$error
              ? 'P-error'
              : ''
          "
          @emitKeypress="onlyLetters($event)"
          label="First Name*"
        />
        <p class="P-read-only-text" v-if="addressClient.isExistingName">
          Read-Only
        </p>
      </div>
      <div class="P-padding-33">
        <ott-input
          v-model="lastname"
          :error="$v.formData.lastname.$error"
          :disabled="addressClient.isExistingName"
          :class="
            addressClient.isExistingName && $v.formData.firstname.$error
              ? 'P-error'
              : ''
          "
          @emitKeypress="onlyLetters($event)"
          label="Last Name*"
        />
        <p class="P-read-only-text" v-if="addressClient.isExistingName">
          Read-Only
        </p>
      </div>
      <div
        v-if="contact.allPhones.length"
        class="P-padding-33"
      >
        <ott-select
          v-model="phone"
          :items="phoneList"
          :multiple="false"
          :persistentHint="true"
          :clear="true"
          selected-text="name"
          :error="$v.formData.phone.$error"
          label="Phone Number*"
        />
      </div>
      <div class="P-padding-100">
        <ott-us-autocomplate
          :addressData="formData.address"
          :error-address="$v.formData.address.$error"
          @updateAddress="updateAddress"
          @saveAddress="saveAddress"
          ref="usAddress"
          isRequiredField
        />
      </div>
      <div class="G-flex G-flex-wrap" style="width: 66.666%;">
        <div class="P-padding-50">
          <ott-select-autocomplete
            v-model="country"
            :height="38"
            :items="countryList"
            :error="$v.formData.country.$error"
            :clear="true"
            outlined
            selected-text="name"
            label="Country*"
          />
        </div>
        <div class="P-padding-50">
          <ott-input
            v-model="city"
            :error="$v.formData.city.$error"
            label="City*"
          />
        </div>
        <div class="P-padding-50">
          <ott-input
            v-model="suite"
            label="Suite / Unit"
          />
        </div>
        <div class="P-padding-50">
          <ott-input
            v-model="zip"
            label="Zip Code*"
            :error="$v.formData.zip.$error"
          />
        </div>

        <div class="P-bottom-block">
          <div class="P-checkbox px-0 py-0 G-align-center">
            <ott-checkbox label="Contact / Invoice" v-model="forContactInvoice" />
            <ott-checkbox v-if="isEditMode || (!isEditMode && !isExistBillingAddress)" label="Is billing" v-model="isBilling" />
            <ott-checkbox v-if="isEditMode || (!isEditMode && !isExistShippingAddress)" label="Is Shipping" v-model="isShipping" />
            <ott-checkbox  label="Is Resident" v-model="isResident" />
          </div>

          <transition name="fade">
            <div v-if="errorMessage" class="my-2 w-max-content">
              <ErrorMessageContainer
                :message="errorMessage"
                width="w-max-content"
              />
            </div>
          </transition>

          <SaveResetButtons     
            left-label="Cancel"
            :right-label="addressClient.isEditMode ? 'Save' : 'Add'"
            :left-click-func="resetAddress"
            :right-click-func="addressClient.isEditMode ? editData : saveData"
            :is-loading="isLoading"
          />
        </div>
      </div>
      <div style="width: 33.333%">
        <div class="P-padding-100">
          <ott-input
            v-model="province"
            :error="$v.formData.province.$error"
            label="State / Province*"
          />
        </div>

        <div class="P-padding-100">
          <transition name="fade">
            <img
              v-if="selectedAddressImage"
              @click="addressImageClick"
              :src="selectedAddressImage" 
              class="P-address-image"
              title="Address Image" 
            />
            <img
              v-if="!selectedAddressImage && formData?.image"
              @click="addressImageClick"
              :src="'data:image/jpeg;base64,' + formData?.image" 
              class="P-address-image"
              title="Address Image" 
            />
          </transition> 
        </div>
      </div>
    </div>

    <image-modal
      v-if="isShowImage"
      @closeModal="closeImageModal()"
      :isOpenModal="isShowImage"
      :showEditBtn="false"
      :imageSrc="selectedImageSrc"
      modal-title="Address Image"
    />
    
  </div>
</template>
<script>
import ManageAddressMixin from "./ManageAddressMixin";
// components
import TitleComponent from "@/components/TitleComponent";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttUsAutocomplate from "@/components/vuetifyComponents/OttUsAutocomplate";
import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import SaveResetButtons from "@/components/SaveResetButtons";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import ImageModal from "@/components/ImageModal";

export default {
  name: "ManageAddress",
  components: {
    SaveResetButtons,
    OttButtonLoader,
    OttCheckbox,
    OttSelectAutocomplete,
    OttUsAutocomplate,
    OttSelect,
    OttInput,
    OttSwitch,
    TitleComponent,
    ErrorMessageContainer,
    ImageModal
  },
  mixins: [ManageAddressMixin],
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/formStyles";

.P-manage-address-block {
  margin: 0 -10px;
}

.P-manage-address-block::v-deep {
  .v-input--switch {
    margin: 0;
  }
}

.P-checkbox {
  .Ott-checkbox-cont + .Ott-checkbox-cont {
    margin-left: 20px;
  }
}

.P-read-only-text {
  color: $neutral-color;
  font-size: $txt10;
  margin-left: 18px;
  margin-bottom: -6px;
}

.P-error::v-deep {
  .v-input--is-disabled,
  fieldset,
  .v-label {
    border-width: 1.5px;
    color: $danger-color-dark !important;
    caret-color: $danger-color-dark !important;
  }
}

.P-address-image {
  object-fit: contain;
  width: 100%;
  cursor: pointer;
}
</style>


<style lang="scss">
  @import "src/assets/scss/formStyles";

  .P-image-modal-content {
    .header {
      height: 30px;
      padding: 2px 8px;

      & p {
        font-size: $txt16;
      }
    }
  }

  .P-bottom-block {
    padding: 0 10px;

    .P-checkbox {
      margin-left: -3px;
    }
  }
</style>