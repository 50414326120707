<template>
  <div class="G-align-center justify-center P-slider-location" v-if="locationsList.length">
    <v-sheet :max-width="width">
      <v-slide-group show-arrows>
        <v-slide-item
          v-for="(item, index) in locationsList"
          :key="index"
          v-slot="{ active, toggle }"
        >
          <div
            :class="{ 'P-active-location': selectedData && item.id === selectedData.id }"
            @click="selectLocationData(item)"
            class="P-items text-center"
          >

            <p class="secondary--text">
              {{ item.login }} ({{ item.locationName }})
            </p>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>
<script>
export default {
  name: "OttLocationsList",
  props: {
    locationsList: {
      type: Array,
      default: () => [],
    },

    selectedData: {
      type: Object,
      default: () => null,
    },
    width: {
      type: Number,
      default: () => 512,
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * Select  location data
     * **/
    selectLocationData(data) {
      this.$emit("changeLocation", data);
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";

.G-locations-list {
  display: grid;
  position: relative;
  overflow: hidden;
  transition: $transition;
  scroll-behavior: smooth;

  &.P-has-scroll {
    width: calc(100% - 50px);
  }

  &.P-shadow {
    &:after {
      width: 0;
      height: 100%;
      left: 0;
      top: 0;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
      content: "";
      position: absolute;
    }

    &:before {
      width: 0;
      height: 100%;
      right: 0;
      top: 0;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
      content: "";
      position: absolute;
    }
  }
}

.P-scroll-icon {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $txt25;
  color: $neutral-color;
  cursor: pointer;
  margin-left: 10px;
  transition: $transition;
  border-radius: 3px;

  &:hover {
    background-color: $neutral-color25;
    color: $secondary-color;
  }
}

.P-items {
  padding: 8px 10px;
  box-shadow: 0 0 5px #00000014;
  border-radius: 18px;
  cursor: pointer;
  transition: $transition;
  min-width: 150px;

  p {
    margin: 0;
    font-size: $txt14;
    font-weight: 600;
    transition: $transition;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & + .P-items {
    margin-left: 20px;
  }

  &:hover,
  &.P-active-location {
    background-color: $primary-color;

    p {
      color: $white !important;
    }
  }
}

.P-slider-location {
  margin-top: 8px;
  position: relative;
  width: 100%;
  ::v-deep {
    .v-slide-group__content {
      padding: 4px;
    }

    .v-slide-group__prev--disabled,
    .v-slide-group__next--disabled {
      opacity: 0.4;
    }

    .v-slide-group__prev,
    .v-slide-group__next {
      min-width: 24px;
      position: absolute;
      top: 0;
      bottom: 0;
    }

    .v-slide-group__prev {
      left: 0;
    }
    .v-slide-group__next {
      right: 0;
    }
    .v-item-group {
      position: unset;
    }
  }
}

.theme--dark {  
  .v-sheet {
    background-color: unset;
    border-color: unset;
  }

  .P-items {
      box-shadow: 0 0 5px #ffffffab;
  }
}
</style>