<template>
  <div class="P-payment-components">
    <div class="d-flex flex-wrap">
      <div 
        :class="{
          'P-padding-100': isOpenClientModal || (!isOpenClientModal && tabsWidth !== 100),
          'P-padding-50': !isOpenClientModal && tabsWidth === 100
        }"
        id="card-container" 
      ></div>
    </div>

    <div  class="P-full-line G-flex">
      <div
        v-if="!isSquare"
        :class="{
          'P-padding-50': isOpenClientModal || (!isOpenClientModal && tabsWidth !== 100),
          'P-padding-25': !isOpenClientModal && tabsWidth === 100 && anExistingAddress,
          'P-padding-33' : !isOpenClientModal && !anExistingAddress
        }"
      >
        <ott-input
          v-if="cardNumber"
          v-model="cardNumber"
          @input="cardNumberTyped"
          :error="$v.creditCard.cardNumber.$error"
          v-cardformat:formatCardNumber
          label="Card number*"
          class="card-number cardfields"
          @paste="handlePasteCardNumber"
        />
        <ott-input
          v-else
          v-model="cardNumber"
          :error="$v.creditCard.cardNumber.$error"
          label="Card number*"
          class="card-number cardfields"
          @paste="handlePasteCardNumber"
        />
      </div>
      <div 
        :class="{
          'P-padding-50': isOpenClientModal || (!isOpenClientModal && tabsWidth !== 100),
          'P-padding-25': !isOpenClientModal && tabsWidth === 100 && anExistingAddress,
          'P-padding-33' : !isOpenClientModal && !anExistingAddress
        }"
      >
        <ottInput
          label="Card holder name*"
          v-model="cardholderName"
          :error="$v.creditCard.cardholderName.$error"
          @paste="handlePasteCardHolder"
          @emitKeypress="onlyLetters($event)"
        />
      </div>
    </div>
    <div class="P-full-line G-flex" v-if="!isSquare">
      <div class="P-padding-25">
        <ottInput
          v-model="expireDate"
          v-cardformat:formatCardExpiry
          @input="expireDateTyped"
          :error="!validateExpiryDate || $v.creditCard.expireDate.$error"
          :maxLength="7"
          :customInputClass="'P-expiry-date'"
          @paste="handlePasteExpiryDate"
          @emitFunc="expiryDateValidation"
          label="Expiry date*"
        />
      </div>
      <div class="P-padding-25">
        <ottInput
          v-model="cvc"
          @input="cvvTyped"
          v-cardformat:formatCardCVC
          :maxLength="4"
          :customInputClass="'P-cvv'"
          :error="$v.creditCard.cvc.$error"
          @paste="handlePasteCvc"
          label="CVC/CVV*"
        />
      </div>
    </div>
    <div>
      <div class="Ott-switch-cont P-padding-100">
        <v-switch
          inset
          :ripple="false"
          v-model="anExistingAddress"
          label="An existing address"
        ></v-switch>
      </div>
    </div>
    <div
      v-if="!anExistingAddress"
      class="P-full-line G-align-center"
    >
      <div class="P-padding-33">
        <ott-select
          v-if="contact.allPhones.length"
          v-model="phone"
          label="Phone Number*"
          :items="phoneList"
          :multiple="false"
          :persistentHint="true"
          selected-text="name"
          :clear="true"
          :error="$v.creditCard.phone.$error"
        />
        <ottInput
          v-else
          v-model="phone"
          label="Phone Number*"
          :error="$v.creditCard.phone.$error"
          is-percent
        />
      </div>
      <div class="P-padding-66">
        <ott-us-autocomplate
          ref="usAddress"
          @updateAddress="updateAddress"
          @saveAddress="saveAddress"
          :error-address="$v.creditCard.address.$error"
          :address-data="address"
          isRequiredField
        />
      </div>
    </div>
    <div
      v-if="!anExistingAddress"
      class="P-full-line G-flex-wrap"
    >
      <div class="P-padding-33">
        <ott-select-autocomplete
          :items="countryList"
          outlined
          :height="38"
          selected-text="name"
          label="Country*"
          v-model="country"
          :error="$v.creditCard.country.$error"
          :clear="true"
        />
      </div>
      <div class="P-padding-33">
        <ottInput
          label="City*"
          v-model="city"
          :error="$v.creditCard.city.$error"
        />
      </div>
      <div class="P-padding-33">
        <ottInput
          label="State/province*"
          v-model="state"
          :error="$v.creditCard.state.$error"
        />
      </div>
      <div class="P-padding-33">
        <ottInput label="Suite/unit" v-model="suite" />
      </div>
      <div class="P-padding-33">
        <ottInput
          label="Zip code*"
          v-model="zip"
          :error="$v.creditCard.zip.$error"
        />
      </div>
    </div>
    <div v-if="anExistingAddress" class="P-full-line">
      <div 
       :class="{
          'P-padding-50': isOpenClientModal || (!isOpenClientModal && tabsWidth !== 100),
          'P-padding-25': !isOpenClientModal && tabsWidth === 100
        }">
        <ott-select
          v-model="existingAddress"
          :error="$v.creditCard.existingAddress.$error"
          :items="billingAddressList"
          label="Billing addresses"
          selected-text="name"
        />
      </div>
    </div>
    <div class="P-padding-100">
      <div class="G-flex P-credit-card-type" v-if="!isCheckout">
        <ott-checkbox
          v-model="creditCard.default"
          label="Default"
          class="P-default-checkbox"
        />
        <ott-checkbox label="In Use" v-model="creditCard.inUse" />
      </div>

      <div class="G-flex P-credit-card-type" v-else>
        <ott-checkbox
          v-model="creditCard.save"
          label="Save"
          class="P-default-checkbox"
        />
      </div>
    </div>

    <div class="P-padding-100" v-if="!isCheckout">
      <!-- <div class="P-save-btn-block" v-if="!isAddClient && !paymentList.length">
        <ott-button-loader
          button-text="Save"
          class-name="primary-sm-btn"
          :is-loading="isLoading"
          @onClick="saveData"
        />
      </div> -->
      <SaveResetButtons
        :left-click-func="resetData"
        :right-label="paymentMethod.editMode ? 'Save' : 'Add'"
        :right-click-func="paymentMethod.editMode ? editData : saveData"
        :is-loading="isLoading"
        left-label="Cancel"
      />
    </div>

    <div class="mt-5">
      <ErrorMessageContainer :message="errorMessage" v-if="error"/>
    </div>
    
  </div>
</template>
<script>
import CreditCardMixin from "./CreditCardMixin";
// components
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttUsAutocomplate from "@/components/vuetifyComponents/OttUsAutocomplate";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "CreditCard",
  components: {
    OttButtonLoader,
    SaveResetButtons,
    OttSelectAutocomplete,
    OttButton,
    ErrorMessageContainer,
    OttCheckbox,
    OttSelect,
    OttUsAutocomplate,
    OttSwitch,
    OttInput,
  },
  mixins: [CreditCardMixin],
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";
@import "./src/assets/scss/mixins";

.P-payment-components::v-deep {
  .v-text-field__details {
    margin-bottom: 0;
  }

  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 0;
  }

  .P-checkbox {
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}

.P-payment-components {
  margin-top: 15px;
}

::v-deep {
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
}

::v-deep {
  .v-input--switch--inset .v-input--switch__track,
  .v-input--switch--inset .v-input--selection-controls__input {
    width: 32px;
  }
}

.Ott-switch-cont::v-deep {
  .v-input--switch--inset .v-input--switch__track {
    height: 18px;
    width: 32px;
    margin-top: 5px;
    left: -2px;
  }
  .v-input--switch--inset .v-input--switch__track,
  .v-input--switch--inset .v-input--selection-controls__input {
    width: 32px;
  }
  .v-input--switch__thumb {
    width: 14px;
    height: 14px;
    background-color: white;
    top: calc(50% - 7px);
  }
  .v-input--switch--inset .v-input--switch__track {
    opacity: 1;
  }
  .theme--light.v-input--switch .v-input--switch__track {
    color: $neutral-color45;
  }
  .theme--dark.v-input--switch .v-input--switch__track {
    color: $neutralBtnMD;
  }
  .v-input--selection-controls__input {
    margin-right: 15px;
    height: 18px;
  }
  .v-input__slot {
    height: 18px;
  }
  .v-input input,
  .v-input textarea {
    height: 18px;
  }
  .v-label {
    font-size: $txt14;
    text-transform: capitalize !important;
  }
  .theme--light {
    &.v-label {
      color: $secondary-color;
    }
    &.v-icon {
      color: $neutral-color45;
    }
  }
  .theme--dark {
  }
  .v-input--selection-controls .v-input__slot > .v-label p,
  .v-input--selection-controls .v-radio > .v-label p {
    margin-bottom: 0 !important;
  }
  .v-input--switch--inset.v-input--is-dirty
    .v-input--selection-controls__ripple,
  .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
    transform: translate(14px, 0) !important;
  }
  .v-input--is-label-active .v-input--switch__track {
    background: $primary-color !important;
  }
  .theme--dark {
    &.v-label {
      color: $white;
    }

    &.v-input--switch__thumb {
      //background: $darkBgLighterDM;
    }
    &.v-input--is-label-active .v-input--switch__track {
      background: $primary-colorDM !important;
    }
    .P-text {
      color: $white;
    }
  }
  .P-text {
    text-transform: capitalize;
  }
}

.P-credit-card-type {
  margin-left: -3px;
  .P-default-checkbox {
    margin-right: 22px;
  }
}
</style>
