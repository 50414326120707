<template>
  <ott-dialog
    v-if="uploadImageModal.modal.isOpen"
    :is-open="uploadImageModal.modal.isOpen"
    :show-footer="false"
    :showHeader="false"
    :width="360"
    close-value="addProvider"
    @outside="cancel"
    @resetDataModal="resetDataModal"
  >
    <template v-slot:modalBody>
      <div class="P-crop-image-section">
        <div class="P-crop-image-title">
          <h3 class="secondary--text">Image Customize</h3>
        </div>
        <div class="P-image-crop-data">
          <clipper-fixed
            ref="clipper"
            :src="profileClients.formData.avatar"
            :round="true"
            :ratio="3 / 3"
            :corner="false"
            :outline="0"
            :bg-color="$vuetify.theme.dark ? '#494A4D' : '#ffffff'"
            :shadow="
              $vuetify.theme.dark ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.7)'
            "
            :scale="scale"
            class="P-test"
            :grid="false"
          ></clipper-fixed>
          <div class="P-zoom-image G-flex G-align-center">
            <span
              class="icon-image_black_24dp P-range-icons P-small-icon"
              @click="changeScale(1)"
            />
            <clipper-range
              v-model="scale"
              :max="4"
              :min="1"
              class="P-range-line"
              @input="zoomImage"
            />
            <span
              class="icon-image_black_24dp P-range-icons P-big-icon"
              @click="changeScale(4)"
            />
          </div>
        </div>
        <div class="P-buttons G-flex">
          <div class="P-checkbox">
            <ott-button
              :click="saveImage"
              :text="'Save'"
              custom-class="neutral-sm-btn"
            />
          </div>
          <div class="P-checkbox">
            <ott-button
              :click="closeClipper"
              :text="'Cancel'"
              custom-class="transparent-sm-btn"
            />
          </div>
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";

export default {
  name: "CropAndUploadImageProfile",
  components: {
    OttButton,
    OttDialog,
  },
  mounted() {
    setTimeout(() => {
      this.zoomImage();
      document.querySelector(".vuejs-clipper-fixed__area.js-area").style.width =
        "80%";
    }, 0);
  },
  data() {
    return {
      scale: 2,
    };
  },

  watch: {
    scale(val) {
      if (val) {
        this.$refs.clipper.setWH$.next(+val);
      }
    },
  },
  computed: {
    ...mapState({
      uploadImageModal: (state) => state.uploadImageModal,
      profileClients: (state) => state.profileClients,
    }),
  },

  methods: {
    ...mapMutations({
      closeModal: 'uploadImageModal/closeModal',
      setImagePath: 'profileClients/setImagePath',

    }),

    closeClipper() {
      this.closeModal();
      if (!this.users.userId) {
        this.$emit("removeImage");
      } else {
        this.$emit("removeImage");
      }
    },

    saveImage() {
      this.closeModal();
      const canvas = this.$refs.clipper.clip(); //call component's clip method
      let resultURL = canvas.toDataURL("image/jpeg", 1);
      let x = this.b64toBlob(resultURL, "image/jpeg");
      this.setImagePath(resultURL);
      // this.$store.dispatch('fileUpload/uploadImage', x).then(data => {
      //   this.$store.commit('generalInfoUser/setAvatarData', data.id)
      // })
    },

    /**
     * convert To blob file
     * **/
    b64toBlob(b64Data, contentType, sliceSize) {
      b64Data = b64Data.split(",")[1];
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      let byteCharacters = atob(b64Data);
      let byteArrays = [];
      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new File(byteArrays, this.profileClients.imageData.name, {
        type: contentType,
      });
    },
    zoomImage(data) {
      if (data) {
        this.scale = data;
        this.$refs.clipper.setWH$.next(data);
      } else {
        this.scale = 2.5;
        this.$refs.clipper.setWH$.next(1.9);
      }
    },
    changeScale(data) {
      this.scale = data;
      this.$refs.clipper.setWH$.next(data);
    },
    cancel() {
      this.resetDataModal();
    },

    validateAddProvider() {},
    resetDataModal() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.P-crop-image-section {
  padding: 25px;
}

.P-crop-image-title {
  margin-bottom: 20px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}

.vuejs-clipper-fixed__area {
}

.theme--dark {
  .P-range-icons {
    color: $dark-gray;
  }
}

.theme--dark {
  .vuejs-clipper-fixed__wrap {
    background-color: $dark-gray !important;
  }

  .vuejs-clipper-fixed__area {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}

.P-range-icons {
  width: 26px;
  height: 26px;
  background-color: rgba(165, 170, 198, 0.15);
  color: $neutral-color;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  transition: $transition;
  cursor: pointer;

  &:hover {
    color: $secondary-color;
  }

  &.P-small-icon {
    font-size: 16px;
  }

  &.P-big-icon {
    font-size: 20px;
  }
}

.P-range-line {
  width: calc(100% - 52px);
}

.P-range-line::v-deep {
  .bar {
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }

  .stick {
    height: 3px;
    background-color: rgba(165, 170, 198, 0.35);
  }

  .wrap {
    height: 16px;
  }
}

.P-zoom-image {
  margin-top: 20px;
}

.P-test::v-deep {
  &.js-clipper-fixed {
    .vuejs-clipper-fixed__cover {
      .vuejs-clipper-fixed__area {
        width: 80% !important;
      }
    }
  }
}

.P-buttons {
  margin-top: 20px;
}

.P-checkbox {
  &:last-child {
    margin-left: 20px;
  }
}

.P-test {
  border: 2px dashed $neutral-color;
}
</style>
