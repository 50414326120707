import {mapActions, mapMutations, mapState} from "vuex";
import {minLength, maxLength, required, requiredIf} from "vuelidate/lib/validators";
import allCountry from "@/constants/all-countries";
import { eventBus } from "@/main"

export default {
  props: {
    isCheckout: {
      type: Boolean,
      default: () => false
    },
    squaretoken: {
      type: Function,
    }
  },  
  data() {
    return {
      // addressList: [],
      validateExpiryDate: true,
      isMainCheck: false,
      error: false,
      errorMessage: '',
      countryList: allCountry,
      isSquare: false,
      card: null,
      phoneList: [],
    }
  },
  validations: {
    creditCard: {
      cardNumber: {
        required,
        // minLength: minLength(19),
        // maxLength: maxLength(20)
      },
      cardholderName: {
        required
      },
      expireDate: {
        required
      },
      cvc: {
        required,
        // minLength: minLength(3)
      },
      phone: {
        required: requiredIf(function () {
          return (
            !this.creditCard.anExistingAddress
          );
        })
      },
      country: {
        required: requiredIf(function () {
          return (
            !this.creditCard.anExistingAddress
          );
        })
      },
      address: {
        required: requiredIf(function () {
          return (
            !this.creditCard.anExistingAddress
          );
        })
      },
      city: {
        required: requiredIf(function () {
          return (
            !this.creditCard.anExistingAddress
          );
        })
      },
      zip: {
        required: requiredIf(function () {
          return (
            !this.creditCard.anExistingAddress
          );
        })
      },
      state: {
        required: requiredIf(function () {
          return (
            !this.creditCard.anExistingAddress
          );
        })
      },
      existingAddress: {
        required: requiredIf(function () {
          return (
            this.creditCard.anExistingAddress
          );
        })
      },
    },
  },

  watch: {
    'paymentMethod.creditCard.brand'(cardBrand) {
      const cardNumber = document.querySelector('.card-number input');
      if (cardNumber) {
        cardNumber.className += `identified,  ${cardBrand}`;
        this.cardBrand = cardBrand
      }
    },

    async '$vuetify.theme.dark'() {
      this.card?.destroy()
      await this.createSquareForm()
    }
  },

  computed: {
    ...mapState({
      paymentMethodId: state => state.paymentMethodClients.paymentMethodId,
      paymentMethod: state => state.paymentMethodClients,
      creditCard: state => state.paymentMethodClients.creditCard,
      addressesList: state => state.addressClient.addressList,
      contact: state => state.contactClient,
      clientId: state => state.clientsModule.clientId,
      currentClient: state => state.clientsModule.client,
      bankAccount: state => state.paymentMethodClients.bankAccount,
      billingAddressList: state => state.addressClient.billingAddressList,
      showSaveBtn: state => state.personalInfoClient.showSaveBtn,
      paymentList: state => state.paymentMethodClients.paymentList,
      isAddClient: state => state.clientsModule.isAddClient,
      tabsWidth: state => state.generalSearch.tabsWidth,
      isOpenClientModal: state => state.clientsModule.modal.isOpen,
      isLoading: state => state.appModule.isLoading
    }),
    cardBrand: {
      get() {
        return this.paymentMethod.creditCard.brand
      },
      set(value) {
        this.setCardBrand(value)
      }
    },
    cardNumber: {
      get() {
        return this.paymentMethod.creditCard.cardNumber
      },
      set(value) {
        this.setCardNumber(value)
      }
    },
    cardholderName: {
      get() {
        return this.paymentMethod.creditCard.cardholderName.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
      },
      set(value) {
        this.setCardholderName(value)
      }
    },
    expireDate: {
      get() {
        return this.paymentMethod.creditCard.expireDate
      },
      set(value) {
        this.setExpireDate(value)
      }
    },
    cvc: {
      get() {
        return this.paymentMethod.creditCard.cvc
      },
      set(value) {
        this.setCVC(value)
      }
    },
    address: {
      get() {
        return this.paymentMethod.creditCard.address
      },
      set(value) {
        this.setAddress(value)
      }
    },
    country: {
      get() {
        return this.paymentMethod.creditCard.country
      },
      set(value) {
        this.setCountry(value)
      }
    },
    city: {
      get() {
        return this.paymentMethod.creditCard.city
      },
      set(value) {
        this.setCity(value)
      }
    },
    suite: {
      get() {
        return this.paymentMethod.creditCard.suite
      },
      set(value) {
        this.setSuit(value)
      }
    },
    zip: {
      get() {
        return this.paymentMethod.creditCard.zip
      },
      set(value) {
        this.setZip(value)
      }
    },
    state: {
      get() {
        return this.paymentMethod.creditCard.state
      },
      set(value) {
        this.setState(value)
      }
    },
    existingAddress: {
      get() {
        return this.paymentMethod.creditCard.existingAddress
      },
      set(value) {
        this.setExistingAddress(value)
      }
    },
    inUse: {
      get() {
        return this.paymentMethod.creditCard.inUse
      },
      set(value) {
        this.setInUse(value)
      }
    },
    default: {
      get() {
        return this.paymentMethod.creditCard.default
      },
      set(value) {
        this.setIsDefault(value)
      }
    },
    phone: {
      get() {
        return this.paymentMethod.creditCard.phone
      },
      set(value) {
        this.setPhone(value)
      }
    },
    anExistingAddress: {
      get() {
        return this.paymentMethod.creditCard.anExistingAddress
      },
      set(value) {
        this.setCreditCardAnExisting(value)
      }
    }
  },

  async created() {
    await this.createSquareForm()

    if (this.contact.allPhones.length) {
      this.contact.allPhones.map(item => {
        this.phoneList.push({
          name: item.phone,
          value: item.phone
        })
      })
    }
  },

  async mounted() {
    this.isMainCheck = this.getCurrentData()
    this.getContact({clientId: this.clientId})
  },

  methods: {
    ...mapActions({
      addPaymentMethod: 'paymentMethodClients/addPaymentMethod',
      getPaymentGateway: 'merchant/getPaymentGateway',
      editPaymentMethod: 'paymentMethodClients/editPaymentMethod',
      getPaymentMethod: 'paymentMethodClients/getPaymentMethod',
      getContact: 'contactClient/getContact'
    }),
    ...mapMutations({
      setData: 'paymentMethodClients/setData',
      resetPaymentMethod: 'paymentMethodClients/resetPaymentMethod',
      setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      setCardNumber: 'paymentMethodClients/setCardNumber',
      setCardBrand: 'paymentMethodClients/setCardBrand',
      setCardholderName: 'paymentMethodClients/setCardholderName',
      setExpireDate: 'paymentMethodClients/setExpireDate',
      setCVC: 'paymentMethodClients/setCVC',
      setAddress: 'paymentMethodClients/setAddress',
      setCountry: 'paymentMethodClients/setCountry',
      setCity: 'paymentMethodClients/setCity',
      setSuit: 'paymentMethodClients/setSuit',
      setZip: 'paymentMethodClients/setZip',
      setState: 'paymentMethodClients/setState',
      setExistingAddress: 'paymentMethodClients/setExistingAddress',
      setInUse: 'paymentMethodClients/setInUse',
      setIsDefault: 'paymentMethodClients/setIsDefault',
      setPhone: 'paymentMethodClients/setPhone',
      setCreditCardAnExisting: 'paymentMethodClients/setCreditCardAnExisting',
      setYear: 'paymentMethodClients/setYear',
      setMonth: 'paymentMethodClients/setMonth',
      closeAddEditPaymentData: 'paymentMethodClients/closeAddEditPaymentData'
    }),

    async createSquareForm() {
      const providerId = this.currentClient?.provider?.id;
      const gateway = await this.getPaymentGateway(providerId);
      if (gateway?.cards === 'square') this.isSquare = true;
      if (this.isSquare) {
        console.log(this.currentClient)
        let applicationId = gateway.square.applicationId;
        let locationId = gateway.square.locationId;
  
        let squareCardFormStyles = {
          '.input-container': {
            borderColor: '#a5aac6',
            borderRadius: '5px',
          },
          '.input-container.is-focus': {
            borderColor: '#01B2B8',
          },
          '.input-container.is-error': {
            borderColor: '#f53a40',
          },
          '.message-text': {
            color: '#f53a40',
          },
          '.message-icon': {
            color: '#f53a40',
          },
          '.message-text.is-error': {
            color: '#f53a40',
          },
          '.message-icon.is-error': {
            color: '#f53a40',
          },
          input: {
            backgroundColor: 'transparent',
            color: '#0a2768',
            fontFamily: 'helvetica neue, sans-serif',
            fontSize: '14px'
          },
          'input::placeholder': {
            color: '#a5aac6',
          },
          'input.is-error': {
            color: '#f53a40',
          },
          '@media screen and (max-width: 600px)': {
             'input': {
                'fontSize': '12px',
             }
          }     
        };
        
        if (this.$vuetify.theme.dark) {
          squareCardFormStyles = {
            '.input-container': {
              borderColor: '#a5aac6',
              borderRadius: '5px',
            },
            '.input-container.is-focus': {
              borderColor: 'white',
            },
            '.input-container.is-error': {
              borderColor: '#f53a40',
            },
            '.message-text': {
              color: '#f53a40',
            },
            '.message-icon': {
              color: '#f53a40',
            },
            '.message-text.is-error': {
              color: '#f53a40',
            },
            '.message-icon.is-error': {
              color: '#f53a40',
            },
            input: {
              backgroundColor: 'transparent',
              color: 'white',
              fontFamily: 'helvetica neue, sans-serif',
              fontSize: '14px'
            },
            'input::placeholder': {
              color: 'white',
            },
            'input.is-error': {
              color: '#f53a40',
            },
            '@media screen and (max-width: 600px)': {
               'input': {
                  'fontSize': '12px',
               }
            }     
          };
        }
      
        const payments = Square.payments(applicationId, locationId);
        this.card = await payments.card({
          style: squareCardFormStyles
        });
        await this.card.attach('#card-container');
      }
    },

    square() {
      return this.isSquare;
    },
    async tokenize() {
      const response = {status: false};
      const tokenizeResult = await this.card.tokenize();
      if (tokenizeResult.status === 'OK') {
        const data = { creditCard: {}};
        data.creditCard.token = tokenizeResult.token;
        data.creditCard.cardNumber = tokenizeResult.details.card.last4;
        data.creditCard.cvc = `***`;
        data.creditCard.month = tokenizeResult.details.card.expMonth.toString();
        data.creditCard.year = tokenizeResult.details.card.expYear.toString();
        data.creditCard.brand = tokenizeResult.details.brand;
        const creditCard = { ...this.creditCard, ...{
          brand : tokenizeResult.details.brand,
          year : tokenizeResult.details.card.expYear.toString(),
          month: tokenizeResult.details.card.expMonth.toString(),
          cvc: "***",
          cardNumber: tokenizeResult.details.card.last4,
          token: tokenizeResult.token,
        }}
        this.setData({
          creditCard
        })
        response.data = data;
        response.status = true;
        console.log(tokenizeResult)
      }
      return response;
    },
    
    cardNumberTyped() {
console.log(`card number typed`);
    },
    expireDateTyped() {
console.log(`expire date typed`);
    },
    cvvTyped() {
console.log(`cvv typed`);
    },
    handlePasteCardNumber(event) {
      // Get the pasted text
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const isValidCreditCard = this.isValidCreditCardFormat(pastedText);

      // If the pasted text is not a valid credit card, prevent the default paste behavior
      if (!isValidCreditCard) {
        event.preventDefault();
      }
    },

    handlePasteCardHolder(event) {
      // Get the pasted text
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const isValidCardHolder = this.isValidCardHolder(pastedText);
      // If the pasted text is not a valid card, prevent the default paste behavior
      if (!isValidCardHolder) {
        event.preventDefault();
      }
    },

    handlePasteExpiryDate(event) {
      // Get the pasted text
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const isValidExpiryDate = this.isValidExpiryDate(pastedText);
      // If the pasted text is not a valid card, prevent the default paste behavior
      if (!isValidExpiryDate) {
        event.preventDefault();
      }
    },

    handlePasteCvc(event) {
      // Get the pasted text
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const isValidCvc = this.isValidCvc(pastedText);
      // If the pasted text is not a valid card, prevent the default paste behavior
      if (!isValidCvc) {
        event.preventDefault();
      }
    },

    isValidCreditCardFormat(cardNumber) {
      // Define a regular expression for a generic credit card format
      let regex = /^(?:\d{4} ){3}\d{4}$|^\d{16}$/;
      return regex.test(cardNumber);
    },

    isValidCardHolder(cardHolder) {
      // Define a regular expression for a generic card holder format
      let regex = /^[a-zA-Z]+ [a-zA-Z]+$/;
      return regex.test(cardHolder);
    },

    isValidExpiryDate(expiry) {
      let regex = /^(0[1-9]|1[0-2])\s*\/\s*\d{2}$/;
      return regex.test(expiry);
    },

    isValidCvc(cvc) {
      let regex = /^\d{1,4}$/;
      return regex.test(cvc);
    },

    /**
     * Add credit Card
     * **/
    async saveData() {
      this.$v.creditCard.$touch()
      
      this.error = false;
      this.errorMessage = '';
   
      // Select the input element by ID
      // var myInputElement = document.getElementById('cardNumber');
      // // Set the value of the input element
      // myInputElement.value = this.cardNumber;
      if (!this.$v.creditCard.$error || this.isSquare) {
        const data = {
          clientId: this.clientId,
          paymentMethod: 0,
          inUse: this.creditCard.inUse,
          default: this.creditCard.default,
          creditCard: {
            cardNumber: this.creditCard.cardNumber,
            cardholderName: this.creditCard.cardholderName,
            month: this.creditCard.month,
            year: this.creditCard.year,
            cvc: this.creditCard.cvc,
            anExistingAddress: this.creditCard.anExistingAddress,
            brand: this.cardBrand,            
          }
        }

        if (this.isSquare) {
          const tokenizeResult = await this.tokenize();
          if (tokenizeResult.data) {
            data.creditCard = { ...data.creditCard, ...tokenizeResult.data.creditCard};
          }
        }

        if (this.creditCard.anExistingAddress) {
          data.creditCard.existingAddress = this.creditCard.existingAddress
        } else {
          data.creditCard.billingAddress = {
            address: this.creditCard.address,
            country: this.creditCard.country,
            city: this.creditCard.city,
            suite: this.creditCard.suite,
            state: this.creditCard.state,
            zip: this.creditCard.zip,
            phone: this.creditCard.phone
          }
        }

        try {
          await this.addPaymentMethod(data)

          this.paymentList.push(data)

          await this.getPaymentMethod({clientId: this.clientId})
          this.setData({
            editMode : false,
            addMode : false,
          })
          return Promise.resolve(true)
        } catch(e) {
          if (e.response.status >= 300) {
            this.error = true;
            this.errorMessage = e.response.data.message
          }
          return Promise.resolve(true)
        } 

      }
    },

    /**
     * Edit Credit Card
     * **/
    async editData() {
      this.$v.creditCard.$touch()
      
      this.error = false;
      this.errorMessage = '';

      let month, year;

      month = this.creditCard.expireDate.split(" / ")[0];
      year = this.creditCard.expireDate.split(" / ")[1];      

      if (!this.$v.creditCard.$error) {
        const data = {
          paymentMethod: 0,
          inUse: this.creditCard.inUse,
          default: this.creditCard.default,
          creditCard: {
            cardNumber : this.creditCard.cardNumber,
            cardholderName: this.creditCard.cardholderName,
            cvc: this.creditCard.cvc,
            anExistingAddress: this.creditCard.anExistingAddress,
            month,
            year,
            brand: this.cardBrand,
          }
        }

        if (this.creditCard.anExistingAddress) {
          data.creditCard.existingAddress = this.creditCard.existingAddress
        } else {
          data.creditCard.billingAddress = {
            address: this.creditCard.address,
            country: this.creditCard.country,
            city: this.creditCard.city,
            suite: this.creditCard.suite,
            state: this.creditCard.state,
            zip: this.creditCard.zip,
            phone: this.creditCard.phone
          }
        }

        try {
          await this.editPaymentMethod({ paymentMethodId: this.paymentMethodId, data })
          this.setData({
            paymentMethodId: null
          })

          this.setData({
            editMode : false,
            addMode : false,
          })

          if (this.$route.name === 'Search') {
            eventBus.$emit("paymentMethodUpdated");
          }
        } catch(e) {
          this.setData({
            editMode : true,
          })
          this.error = true;
          this.errorMessage = e.message
        } 


        await this.getPaymentMethod({clientId: this.clientId})
      }
    },

    /**
     * Update Address autocomplete
     * **/

    saveAddress() {
      const [address] = arguments
      this.setAddress(address)
    },
    updateAddress() {
      const [addressData] = arguments;
      this.setAddress(addressData.fullAddress)
      this.setCity(addressData.city)
      this.setState(addressData.province)
      this.setSuit(addressData.suite)
      this.setZip(addressData.zipCode)
      let countryValue = allCountry.filter(x => x.name === addressData.country)[0].value
      this.setCountry(countryValue)
    },

    /**
     * Validate Expiry Date
     * @Year
     * @month
     * **/

    expiryDateValidation() {
      let month = new Date().getMonth();
      let year = new Date()
        .getFullYear()
        .toString()
        .split("")
        .splice(2, 2)
        .join("");
      if (this.creditCard.expireDate) {
        let x = this.creditCard.expireDate.split("/");
        if (+x[0] > 12) {
          x[0] = "12";
        }
        x[1] && this.setYear(x[1].trim())
        x[0] && this.setMonth(x[0].trim())
        if(x.length===2){
          this.setExpireDate(x[0] + "/" + x[1])
          this.validateExpiryDate =
            (+x[0] >= month + 1 && +x[1] === +year) || (+x[1] > +year && +x[0] > 0);
        }
      }
    },

    resetData() {
      this.closeAddEditPaymentData()
      if (this.$refs["usAddress"]) {
        this.$refs["usAddress"].resetAddress();
      }
      this.setShowSaveBtn(true)
      
      if (!this.paymentList.length) {
        this.setData({ showComponents: false })
      }
    },

    /**
     * get current  data for check inMain
     * **/
    getCurrentData() {
      let currentIndex = false
      if (!this.paymentMethod.addMode && this.paymentMethod.paymentList) {
        this.paymentMethod.paymentList.forEach((item, index) => {
          if (item.id === this.paymentMethod.creditCard.id) {
            currentIndex = item.default
          }
        })
      }
      return currentIndex
    },
    changeSelectedAddress() {},

    /**
     * Only letters and spaces - regex
     * **/
    onlyLetters(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[a-zA-Z\s]*$/.test(char)) return true;
      else event.preventDefault();
    }
  },
}
