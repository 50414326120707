var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"P-device-information"},[_c('div',[_vm._l((_vm.data.geoIpInfo),function(geoIpItem,geoIpKey,geoIpIndex){return [(geoIpKey !== 'city' && geoIpKey !== 'country' && geoIpKey !== 'location')?_c('li',{key:geoIpIndex + 'i',class:{
          'order-5': geoIpKey === 'countryCode',
          'order-7': geoIpKey === 'postalCode',
          'order-9': geoIpKey === 'timezone',
          'order-2': geoIpKey === 'realIp',
          'order-4': geoIpKey === 'network',
          'order-6': geoIpKey === 'domain',
          'order-8': geoIpKey === 'organization',
          'order-10': geoIpKey === 'isp'
        }},[_c('p',{staticClass:"neutral--text"},[_vm._v(_vm._s(geoIpKey.replace(/([a-z0-9])([A-Z])/g, '$1 $2')))]),_c('p',{staticClass:"secondary--text"},[_c('pre',[_vm._v(_vm._s(geoIpItem || "-"))])])]):_vm._e(),(geoIpKey === 'city' || geoIpKey === 'country')?_c('li',{key:geoIpIndex + 'j',class:{
          'order-1': geoIpKey === 'city',
          'order-3': geoIpKey === 'country'
        }},[_c('p',{staticClass:"neutral--text"},[_vm._v(_vm._s(geoIpKey.replace(/([a-z0-9])([A-Z])/g, '$1 $2')))]),_c('p',{staticClass:"secondary--text"},[_c('pre',[_vm._v(_vm._s(_vm.getName(geoIpItem.names) || "-"))])])]):_vm._e()]})],2),_c('div',[_vm._l((_vm.data),function(item,key,index){return [(key !== 'geoIpInfo' &&  key !== 'lastUpdate' && key !== 'locationId' && key !== '_id')?_c('li',{key:index},[_c('p',{staticClass:"neutral--text"},[_vm._v(_vm._s(key.replace(/([a-z0-9])([A-Z])/g, '$1 $2')))]),_c('p',{staticClass:"secondary--text"},[_vm._v(_vm._s(item || "-"))])]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }