import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      showLoader: false,
      showWarningModal: false,
      warningModalIsDangerButton: true,
      deletedLocationId: null,
      warningModalIsLoading: false,
      deletedLocationName: "",
      locationList: [],
      isShowImage: false,
      commentImages: [],
      imageSrc: "",
      isCommenterImg: false,
      syncClientId: null,
    }
  },

  watch: {
    'locationClient.locationList'(locationsList) {
      if (locationsList.length) this.getLocationsWithStatuses()
    },
  },

  computed: {
    ...mapState({
      locationClient: state => state.locationClient,
      clientId: state => state.clientsModule.clientId,
      isGeneralSearch: state => state.generalSearch.isGeneralSearch,
      serverList: state => state.clientsModule.serverList,
      isOpenClientModal: state => state.clientsModule.modal.isOpen,
    }),

    baseApiUrl() {
      return process.env.VUE_APP_API_URL;
    },

    warningModalHeading() {
      return `Delete client's location?`
    },

    warningModalText() {
      return `You're about to <ins>delete</ins> client's location <ins> ${this.deletedLocationName} </ins>.  If you're not sure, you can cancel this operation.`
    },
  },

  async created() {
    if (this.$route.name === 'Search') {
      this.syncClientId = this.$route.params['id'];
      this.$hubEventBus.$on(`location-info-${this.syncClientId}`, this.handleInfo); // if loggedIn get permissions with WS
    }
    if (this.clientId) {
      if (this.$route.name !== 'Search' || (this.$route.name === 'Search' && this.isOpenClientModal)) {
        await this.getLocations();
        // { clientId : this.$route.name === 'Search' ? this.$route.params.id : null }
      }
    }

    if (this.locationClient.locationList.length) {
      this.getLocationsWithStatuses()
    }
  },
  beforeDestroy() {
    if (this.$route.name === 'Search') {
      this.syncClientId = this.$route.params['id'];
      this.$hubEventBus.$off(`location-info-${this.syncClientId}`, this.handleInfo); // if loggedIn get permissions with WS
    }
  },

  mounted() {
    setTimeout(() => {
      this.getCommentImage();
    },0)
  },

  methods: {
    async handleInfo(info) {
      await this.getLocations();
      if (this.locationClient.locationList.length) {
        this.getLocationsWithStatuses()
      }
    },
    ...mapActions({
      getLocations: "locationClient/getLocations",
      getLocation: "locationClient/getLocation",
      delete: "locationClient/deleteLocation",
    }),

    ...mapMutations({
      setData: "locationClient/setData",
      setDisableTabs: "clientsModule/setDisableTabs",
      setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      resetFormData: 'locationClient/resetFormData'
    }),

    getCommentImage() {
      this.commentImages = document.querySelectorAll(".P-comment-cont img");
      this.commentImages.forEach((img) => {
        img.addEventListener("click", (e) => {
          e.stopPropagation();
          e.preventDefault();
          this.imageSrc = img.getAttribute("src");
          this.isShowImage = true;
        });
      });
    },

    getCommenterImage(imageSrc) {
      this.isCommenterImg = true
      this.imageSrc = imageSrc
      this.isShowImage = true
    },

    getLocationsWithStatuses() { 
      /* 
        location status by subscriptionState value
        0 - Inactive
        1 - Pending
        2 - Canceled
        3 - Active
      */

      let status = ''
      let pauseDuration = null
      let expireDate = null
      let pauseStartDate = null
      
      this.locationList = []

      this.locationClient.locationList.map((location) => {
        //get pause duration
        if (location?.subscriptionExpireDate) {
          expireDate = this.$moment(location.subscriptionExpireDate)
          pauseStartDate = this.$moment(location.pauseStartDate)
          pauseDuration = this.convertMsToDays(expireDate.diff(pauseStartDate))
        }

        if (location.isBlockLocation === undefined || location.isPauseSubscriptions === undefined || (!location.isBlockLocation && !location.isPauseSubscriptions)) {
          status = ''
        }

        if (location.subscriptionState === 0) {
          status = `<span class="G-badge G-advanced-badge">Inactive</span>`
        }

        if (location.subscriptionState === 1) {
          status = `<span class="G-badge G-pending-badge">Pending ${ location?.subscriptionPendingDate ? `</br> ${location.subscriptionPendingDate}` : '' }</span>`
        }

        if (location.subscriptionState === 2) {
          status = `<span class="G-badge G-disabled-badge">Canceled ${ location?.subscriptionCancelDate ? `</br> ${location.subscriptionCancelDate}` : '' }</span>`
        }

        if (location.subscriptionState === 3) {
          if (location.isBlockLocation && location.isPauseSubscriptions) {
            status = `<span class="G-badge G-reject-badge mb-2">Blocked</span>
                      <span class="G-badge G-paused-badge G-paused-badge-time">Paused </br> ${pauseDuration}</span>`
          }

          if (location.isBlockLocation && !location.isPauseSubscriptions) {
            status = `<span class="G-badge G-reject-badge">Blocked</span>`
          }

          if (!location.isBlockLocation && location.isPauseSubscriptions) {
            status = `<span class="G-badge G-paused-badge G-paused-badge-time">Paused </br> ${pauseDuration}</span>`
          }

          if (!location.isBlockLocation && !location.isPauseSubscriptions) {
            status = `<span class="G-badge G-approve-badge">Active ${ location?.subscriptionActivationDate ? `</br> ${location.subscriptionActivationDate}` : '' }</span>`
          }
        }

        this.locationList.push({
          ...location,
          status
        })
      })
    },

    convertMsToDays(t) {
      var cd = 24 * 60 * 60 * 1000,
          ch = 60 * 60 * 1000,
          d = Math.floor(t / cd),
          h = Math.floor((t - d * cd) / ch),
          m = Math.round((t - d * cd - h * ch) / 60000);

      return `${d}d ${h}h ${m}m`
    },

    openWarningModal(locationId, locationName) {
      this.showWarningModal = true;
      this.warningModalIsLoading = false;
      this.deletedLocationId = locationId;
      this.deletedLocationName = locationName;
    },

    /**
     * Add new location
     * **/

    addNewLocation() {
      this.setShowSaveBtn(false)
      this.resetFormData();
      this.setData({ isAddMode: true });
    },

    /**
     * Edit location
     * **/

    async editLocationFromList(editLocationId) {
      this.showLoader = true;
      this.setShowSaveBtn(false)
      this.setData(
        {
          editLocationId,
          isAddMode: false,
          passwordIsUpdated: false
        }
      );

      await this.getLocation(editLocationId);
      this.showLoader = false;
      
      setTimeout(() => {
        this.setData({ isEditMode: true });
      }, 0)
    },

    /**
     * Delete location
     * **/

    async deleteLocation(locationId) {
      this.warningModalIsLoading = true;
      await this.delete(locationId).finally(() => {
        this.warningModalIsLoading = false;
      });

      if (this.locationClient.locationList.length) {
        this.getLocationsWithStatuses()
      }
      else {
        this.setDisableTabs(6);
        this.resetFormData();
        this.setData({ showComponents: false });
      }
      this.showWarningModal = false;
    },

    getServerName(serverId) {
      const filtered = this.serverList.filter(x => x.id === serverId);
      return filtered.length ? filtered[0].name : '';
    },
  },
}
