<template>
    <div :class="classes">{{text}}</div>
</template>

<script>
export default {
    name: "CustomBadge",
    props: {
        classes: {
            type: String,
            default: () => null
        },

        text: {
            type: String,
            default: () => null
        }
    }

};
</script>

<style scopped>
    .bounce-enter-active {
        animation: bounce-in 1.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 2.1s;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(0);
        }
    }
</style>