<template>
  <div class="P-table P-addresses-list">
    <TitleComponent
      :click="addNewAddress"
      is-add
      title-text="Addresses"
    />
    <div
      class="P-table-block px-0"
      :class="{
        'P-table-block-small': $route.name !== 'Search' || ($route.name === 'Search' && tabsWidth !== 100),
        'P-table-block-big': $route.name === 'Search' && tabsWidth === 100
      }"
    >
      <div class="P-table-header">
        <ul class="G-flex">
          <li class="secondary--text" v-for="(item, key) of header" :key="key">{{ item }}</li>
          <li></li>
        </ul>
      </div>
      <div class="P-table-body">
        <ul
          v-for="(item, index) of allAddresses" 
          :key="index"
          class="G-flex" 
        >
          <li>
            <p class="secondary--text" v-html="getAddress(item)" />
          </li>
          <!-- <li v-if="$route.name !== 'Search' || ($route.name === 'Search' && tabsWidth === 100)">
            <img
               @click="addressImageClick(item?.image)"
              :src="
                !item?.image 
                  ? require('@/assets/images/Image_not_available.png/')
                  : 'data:image/jpeg;base64,' + item?.image
              " 
              class="P-address-image"
              title="Address Image"
            />
          </li> -->
          <li>
            <p class="secondary--text">
              {{ item.phone }}
            </p>
          </li>
          <li>
            <span v-if="item.forContactInvoice" class="mdi mdi-check checkTable"/>
          </li>
          <li>
            <span v-if="item.isShipping" class="mdi mdi-check checkTable"/>
          </li>
          <li>
            <span v-if="item.isBilling" class="mdi mdi-check checkTable"/>
          </li>
          <li>
            <span v-if="item?.isResident" class="mdi mdi-check checkTable"/>
          </li>
          <li class="G-flex-center flex-column">
            <span
              @click="openVerificationModal(item)"
              class="P-address-verify-icon position-absolute mdi mdi-check-circle"
              :class="{
                'invalid-address': !item?.isValid,
                'valid-address': item?.isValid,
              }"
            /> 

            <div class="P-actions">
              <v-menu left content-class="P-actions-list">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="P-list" @click="editAddress(item, index)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="P-list" @click="openDeleteModal({index, address : getAddress(item) })">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false" 
      @btnFunction="warningModalBtnFunc"
    />

    <image-modal
      v-if="isShowImage"
      @closeModal="closeImageModal()"
      :isOpenModal="isShowImage"
      :showEditBtn="false"
      :imageSrc="selectedImageSrc"
      modal-title="Address Image"
    />

    <v-dialog
      v-model="showVerificationModal"
      persistent
      content-class="P-verification-modal"
    >
      <div class="position-relative">
        <span
          class="mdi mdi-close P-verification-close P-icon white--text cursor-pointer"
          @click="closeVerificationModal"
        ></span>
        
        <template>
          <address-verification
            v-if="!toVerifyAddress?.isValid && toVerifyAddress?.validationObject && 'newaddress' in toVerifyAddress?.validationObject"
            @verfiedButtonClick="updateByValidation(toVerifyAddress)"
            :address="toVerifyAddress" 
            :bad-address-text="getAddress(toVerifyAddress?.validationObject?.oldAddress)"
            :verified-address-text="getAddress(toVerifyAddress?.validationObject?.newaddress)"
          />

          <div v-else class="pa-5 bg-white" style="x-index: 99;">
            <v-alert
              dense
              outlined
              type="error"
              class="mb-0"
            >
            {{ validationMessage.charAt(0).toUpperCase() + validationMessage.slice(1) }}
            </v-alert>
          </div>
        </template>
      </div>
    </v-dialog>
  </div> 
</template>
<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import _ from "lodash";
  import { eventBus } from "@/main";

  // component
  import OttDialog from "@/components/vuetifyComponents/OttDialog";
  import TitleComponent from "@/components/TitleComponent";
  import WarningModal from "@/components/WarningModal";
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
  import ImageModal from "@/components/ImageModal";
  import AddressVerification from "@/components/addressVerification/AddressVerification"

  export default {
    components: { OttDialog, TitleComponent, WarningModal, ErrorMessageContainer, ImageModal, AddressVerification },
    name: "AddressList",
    data() {
      return {
        header: [
          "Address",
          "Phone number",
          "Contact Invoice",
          "Shipping",
          "Billing",
          "Resident"
        ],
        showWarningModal: false,
        deletedAddressIndex: null,
        warningModalBtnText: "",
        warningModalIsDangerButton: true,
        warningModalIsLoading: false,
        deletedAddress: "",
        errorMessage: '',
        selectedImageSrc: null,
        isShowImage: false,
        showVerificationModal: false,
        toVerifyAddress: null,
        validationMessage: ''
      };
    },
    async created() {
      if (this.$route.name !== 'Search') {
        await this.fetchAddress()
      }
    },

    mounted() {
      // for address image showing
      // if (this.tabsWidth === 100) {
      //   if (!this.header.includes('image')) {
      //     this.header.splice(1, 0, 'image');
      //   }
      // } else {
      //   if (this.header.includes('image')) {
      //     this.header.splice(1, 1);
      //   }
      // }

      this.$nextTick(() => {
        // After adding address checking if addres is invalid opening verification modal
        eventBus.$on('addressAdded', () => {
          if (this.allAddresses.length) {
            this.openVerificationModal(this.allAddresses[this.allAddresses.length-1])
          }
        }) 

        // After edit(updating) address checking if addres is invalid opening verification modal
        eventBus.$on('addressUpdated', (addressIndex) => {
          if (this.allAddresses.length) {
            this.allAddresses.forEach((address, index) => {
              if (index === addressIndex) {
                this.openVerificationModal(address)
              }
            })  
          }
        });
      });
    },

    computed: {
      ...mapState({
        addressClient: state => state.addressClient,
        formData: state => state.addressClient.formData,
        allAddresses: state => state.addressClient.allAddresses,
        allPhones: state => state.contactClient.allPhones,
        tabsWidth: state => state.generalSearch.tabsWidth,
      }),
      
      warningModalHeading() {
        return `Delete client's address?`
      },

      warningModalText() {
        return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> client's address <br> <ins>${this.deletedAddress}</ins>. If you're not sure, you can cancel this operation.`
      },

      showErrorMessage() {
        let showError = false;

        if (this.allAddresses.length) {
          this.allAddresses.map(item => {
            if (!item?.isValid) {
              showError = true
            }
          })
        }

        return showError
      },
    },

    watch: {
      // for address image
      // tabsWidth(newVal) {
      //   if (newVal === 100) {
      //     if (!this.header.includes('image')) {
      //       this.header.splice(1, 0, 'image');
      //     }
      //   } else {
      //     if (this.header.includes('image')) {
      //       this.header.splice(1, 1);
      //     }
      //   }
      // }
    },
    methods: {
      ...mapActions({
        updateAddress: 'addressClient/addContactAddress',
        fetchAddress: 'addressClient/fetchAddress',
        resetAddress: 'addressClient/resetAddress',
      }),
      ...mapMutations({
        setData: 'addressClient/setData',
        setFormData: 'addressClient/setFormData',
        isAddMode: 'addressClient/setIsAddMode',
        isEditMode: 'addressClient/setIsEditMode',
        resetAddress: 'addressClient/resetAddress',
        setShowSaveBtn: 'personalInfoClient/setShowSaveBtn'
      }),

      closeVerificationModal() {
        this.toVerifyAddress = null
        this.validationMessage = ''
        this.showVerificationModal = false
      },

      openVerificationModal(address) {
        if (!address?.isValid && address?.validationObject && 'newaddress' in address?.validationObject) {
          this.toVerifyAddress = address
          this.showVerificationModal = true
        } 
        
        if (!address?.isValid && 
          address?.validationObject && 
          !('newaddress' in address?.validationObject)){
          this.validationMessage = address?.validationMessage
          this.showVerificationModal = true
        }
      },

      addressImageClick(image) {
        this.selectedImageSrc = 'data:image/jpeg;base64,'+image
        this.isShowImage = true
      },

      closeImageModal() {
        this.selectedImageSrc = null
        this.isShowImage = false
      },
      addNewAddress() {
        this.resetAddress()
        this.isAddMode(true)
        this.setShowSaveBtn(false)
      },
      editAddress(item, index) {
        this.isAddMode(false)
        this.isEditMode(true)
        this.setData({ selectedAddressIndex: index })
        this.setFormData({
          firstname: item.firstname,
          lastname: item.lastname,
          phone: item.phone,
          address: item.address,
          country: item.country,
          city: item.city,
          province: item.province,
          suite: item.suite,
          zip: item.zip,
          isBilling: item.isBilling,
          isShipping: item.isShipping,
          isResident: item?.isResident,
          forContactInvoice: item.forContactInvoice,
          image: item?.image
        })
       
        this.setShowSaveBtn(false)
      },

      async updateByValidation(address) {
        let addresses = _.clone(this.allAddresses)
        addresses.forEach((item, index) => {
          if (item.id === address.id) {
            item.address = address.validationObject.newaddress.address
            item.country = address.validationObject.newaddress.country
            item.city = address.validationObject.newaddress.city
            item.province = address.validationObject.newaddress.province
            item.suite = address.validationObject.newaddress.suite
            item.zip = address.validationObject.newaddress.zip
            item.lat = address.validationObject.newaddress.lat
            item.long = address.validationObject.newaddress.long
          }
        })

        await this.updateAddress({ addresses })
        await this.fetchAddress()

        this.toVerifyAddress = null;
        this.validationMessage = ''
        this.showVerificationModal = false;
      },

      openDeleteModal(e) {
        this.deletedAddressIndex = e.index
        this.deletedAddress = e.address
        this.warningModalIsLoading = false;
        this.warningModalBtnText = "Delete";
        this.showWarningModal = true;
      },
      async warningModalBtnFunc() {
        await this.deleteAddress().finally(() => {
          this.warningModalIsLoading = false;
        });

        if (!this.allAddresses.length) {
          this.setData({ showComponents: false }); 
          this.resetAddress();
        }
      },
      async deleteAddress() {
        this.warningModalIsLoading = true;

        let addresses = []
        addresses = this.allAddresses.forEach((item, i) => {
          if (i === this.deletedAddressIndex) {
            this.allAddresses.splice(i, 1)
          }
        })
        addresses = this.allAddresses
        await this.updateAddress({addresses})
        await this.fetchAddress()
        
        this.showWarningModal = false; 
      },
      getAddress(address) {
        return this.FormatClientAddress(address);
      }
    },
  };
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables";
  @import "../../simpleTable";

  .P-addresses-list {
    .P-table-block-small  {
      li {
        &:nth-child(2) {
          max-width : 150px;
          min-width : 150px;
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5), 
        &:nth-child(6) {
          max-width : 85px;
          min-width : 85px;
        }
      }
    }

    .P-table-block-big {
      li {
        &:nth-child(1) {
          max-width : 240px;
          min-width : 240px;
        }
      }
    }

    // .P-table-block-small {
    //   ul {
    //     li {
    //       &:nth-child(2) {
    //         max-width : 180px;
    //         min-width : 180px;
    //       }
    //       &:nth-child(3),
    //       &:nth-child(4),
    //       &:nth-child(5),
    //       &:nth-child(6), 
    //       &:nth-child(7) {
    //         max-width : 85px;
    //         min-width : 85px;
    //       }
    //     }
    //   }
    // }
  }
  .theme--dark {
    .P-table {
      background-color: unset !important;
    }
  }

  .P-address-image {
    object-fit: contain;
    width: 75%;
  }

  .P-verification-close {
    position: absolute;
    right: 1px;
    top: -1px;
    font-size: 20px;
    z-index: 1;
    text-shadow: 0 0 2px grey;
  }

  .P-address-verify-icon {
    top: 2px; 
    font-size: 19px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .invalid-address {
    color: $red;
  }

  .valid-address {
    color: $primary-color;
  }

  .bg-white {
    background-color: white;
  }
</style>

<style lang="scss">
  .P-verification-modal {
    width: max-content !important;
  }
</style>
